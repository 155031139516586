const fetchAtmosphericWeather = () => {
  const weathers = [
    'Soleado',
    'Escasa nubosidad',
    'Nubosidad parcial',
    'Mayormente nublado',
    'Nublado',
    'Llovizna',
    'Chubasco',
    'Lluvioso',
    'Precipitaciones',
    'Tormentas',
    'Nevadas',
    'Aguanieve',
    'Ventoso',
    'Bruma',
    'Calina o calima',
    'Nieve',
    'Neblina',
  ];

  return weathers;
};

export { fetchAtmosphericWeather };
