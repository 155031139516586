const fetchRoles = () => {
  const roles = [
    { name: 'admin', esName: 'Administrador' },
    { name: 'workers', esName: 'Empleado' },
    { name: 'collaborator', esName: 'Colaborador' },
    { name: 'complainants', esName: 'Denunciante' },
  ];

  return roles;
};

export { fetchRoles };
