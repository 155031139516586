/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';

import InterestGroupFormFirstStepComponent from '../interest-group-form-first-step-component/interest-group-form-first-step-component.vue';
import InterestGroupFormSecondStepComponent from '../interest-group-form-second-step-component/interest-group-form-second-step-component.vue';
import InterestGroupFormThirdStepComponent from '../interest-group-form-third-step-component/interest-group-form-third-step-component.vue';
import InterestGroupFormFourthStepComponent from '../interest-group-form-fourth-step-component/interest-group-form-fourth-step-component.vue';
import InterestGroupFormFifthStepComponent from '../interest-group-form-fifth-step-component/interest-group-form-fifth-step-component.vue';
import { vxm } from '@/app/app-state';
import { restoreBackendData } from '../../utils';
import { editInterestGroup, newInterestGroup } from '../../services';

@Component({
  components: {
    InterestGroupFormFirstStep: InterestGroupFormFirstStepComponent,
    InterestGroupFormSecondStep: InterestGroupFormSecondStepComponent,
    InterestGroupFormThirdStep: InterestGroupFormThirdStepComponent,
    InterestGroupFormFourthStep: InterestGroupFormFourthStepComponent,
    InterestGroupFormFifthStep: InterestGroupFormFifthStepComponent,
  },
})
export default class InterestGroupFormComponent extends Vue {
  private formStep: number;
  private saveButtonText: string;
  $vs!: {
    loading: any;
  };
  constructor() {
    super();
    this.formStep = 1;
    this.saveButtonText =
      vxm.interestGroup.interestDialogMode == 'Nuevo' ? 'Crear' : 'Guardar';
  }

  nextStep() {
    switch (this.formStep) {
      case 1:
        this.formStep = 2;
        break;
      case 2:
        this.formStep = 3;
        break;
      case 3:
        this.formStep = 4;
        break;
      case 4:
        this.formStep = 5;
        break;
      case 5:
        break;
    }
  }

  prevStep() {
    switch (this.formStep) {
      case 1:
        this.formStep = 1;
        break;
      case 2:
        this.formStep = 1;
        break;
      case 3:
        this.formStep = 2;
        break;
      case 4:
        this.formStep = 3;
        break;
      case 5:
        this.formStep = 4;
        break;
    }
  }

  async saveInterestGroup() {
    const backendData = restoreBackendData(
      vxm.interestGroup.interestGroupSelected
    );
    const loading = this.$vs.loading();

    if (vxm.interestGroup.interestDialogMode == 'Editar') {
      await editInterestGroup(backendData);
      await vxm.interestGroup.fetchAll();
    } else {
      await newInterestGroup(backendData);
      await vxm.interestGroup.fetchAll();
    }

    loading.close();
    const parent = this.$parent as any;
    parent.$parent.closeDialog();
  }
}
