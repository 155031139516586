import draggable from 'vuedraggable';

import { vxm } from '@/app/app-state';
import { OperationalTaskData } from '@/app/operational-tasks/shared/interfaces';
import { Component, Vue } from 'vue-property-decorator';
import {
  Activity,
  answerStatus,
  CausesDetermination,
  Measures,
} from '../shared/interfaces';
import { Answer, Claim } from '../shared/models';
import {
  editAnswer,
  editClaim,
  fetchAnimalSpecies,
  fetchAnswer,
  fetchAtmosphericWeather,
  fetchBreedingTypes,
  fetchClaim,
  fetchCriticalStages,
  fetchExternalityDistances,
  fetchExternalityTypes,
  fetchOperationalInfras,
  fetchSectors,
  fetchTerritorialUnits,
  fetchZones,
  newAnswer,
} from '../shared/services';

import OperationalTaskItem from '@/app/operational-tasks/shared/components/operational-task-item.component.vue';
import { fetchOperationalTasksSelected } from '@/app/operational-tasks/shared/services';
import TextEditable from '@/app/shared/components/text-editable/text-editable.vue';
import { format } from 'date-fns';
import { UserAuthenticatedData } from '@/app/users/shared/interfaces';

@Component({
  components: {
    OperationalTaskItem,
    draggable,
    TextEditable,
  },
})
export default class AnswerItemComponent extends Vue {
  private claimData: Claim;
  private answerData: Answer;
  private operationalTasks: OperationalTaskData[] = [];
  private selectedTasks: OperationalTaskData[] = [];
  private atmosphericWeathers: string[];
  private causesDeterminationForm: CausesDetermination;
  private currentStep = 0;
  private answerSteps = [
    {
      title: 'Determinación de causas',
      icon: 'container',
    },
    {
      title: 'Tareas Operacionales',
      icon: 'carry-out',
    },
  ];
  private operationalTaskColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Subgerencia',
      dataIndex: 'taskInformation.submanagement',
    },
    {
      title: 'Area',
      dataIndex: 'taskInformation.area',
    },
    {
      title: 'Tipo Tarea',
      dataIndex: 'taskInformation.taskType',
    },
    {
      title: 'Categoría Tarea',
      dataIndex: 'taskInformation.taskCategory',
    },
    {
      title: 'Tarea',
      dataIndex: 'taskInformation.task',
    },
    {
      title: 'Actividad',
      dataIndex: 'taskInformation.activity',
    },
  ];
  private answerStatus = false;
  private isLoadingClaimItem = false;
  private isLoadingAnswerItem = false;
  private isEditing = true;
  private isAnswerRoute = false;

  private statusForm = {
    statusComment: '',
  };
  private statusPlaceholder = 'Motivo de rechazo';
  private statusRules = {
    statusComment: [
      {
        required: true,
        message: 'Escribir motivo de rechazo',
        trigger: 'change',
      },
    ],
  };
  $refs!: {
    statusForm: any;
  };

  constructor() {
    super();
    this.claimData = new Claim();
    this.answerData = new Answer();
    this.atmosphericWeathers = fetchAtmosphericWeather();
    this.causesDeterminationForm = this.answerData.causesDetermination;
  }

  get externalityDistances() {
    return fetchExternalityDistances();
  }

  get externalityTypes() {
    return fetchExternalityTypes();
  }

  get territorialUnits() {
    return fetchTerritorialUnits();
  }

  get operationalInfras() {
    const operationalInfras = fetchOperationalInfras();
    return operationalInfras.map(infra => infra.name);
  }

  get operationalUnits() {
    const operationalInfras = fetchOperationalInfras();
    const units = operationalInfras.find(
      infra =>
        infra.name ==
        this.causesDeterminationForm.productionData.infraOperational
    )?.operationalUnits;

    return units;
  }

  get sectors() {
    const sectors = fetchSectors();
    return sectors.map(sector => sector.name);
  }

  get sectorTypes() {
    const sectors = fetchSectors();
    const types = sectors.find(
      sector =>
        sector.name == this.causesDeterminationForm.productionData.sector
    )?.types;

    return types;
  }

  get animalSpecies() {
    const animalSpecies = fetchAnimalSpecies();
    return animalSpecies.map(specie => specie.name);
  }

  get animalAges() {
    const animalSpecies = fetchAnimalSpecies();
    const ages = animalSpecies.find(
      specie =>
        specie.name == this.causesDeterminationForm.productionData.animalSpecie
    )?.ages;

    return ages;
  }

  get zones() {
    return fetchZones();
  }

  get breedingTypes() {
    return fetchBreedingTypes();
  }

  get criticalStages() {
    return fetchCriticalStages();
  }

  get taskTypeList() {
    let taskTypeList = this.selectedTasks.map(task => {
      return task.taskInformation.taskType;
    });

    taskTypeList = taskTypeList.filter((task, index) => {
      return taskTypeList.indexOf(task) === index;
    });

    return taskTypeList;
  }

  get areaList() {
    let areaList = this.selectedTasks.map(task => {
      return task.taskInformation.area;
    });

    areaList = areaList.filter((task, index) => {
      return areaList.indexOf(task) === index;
    });

    return areaList;
  }

  get pageModeTitle() {
    if (this.$route.name == 'reclamo-respuesta') {
      return `Editar respuesta - `;
    }
    return 'Crear respuesta';
  }

  get pageIdTitle() {
    const title = this.claimData.code
      ? this.claimData.code
      : 'REC-AACC-2020-XXXXXX';

    return this.$route.name == 'reclamo-respuesta' ? title : '';
  }

  async mounted() {
    this.isLoadingAnswerItem = true;
    const claimId = this.$route.params.id;
    const claimRes = await fetchClaim(claimId);
    this.claimData = new Claim(claimRes.res);
    await this.getOperationalTaskFiltered();
    if (this.$route.name == 'reclamo-respuesta') {
      this.isAnswerRoute = true;
      this.isEditing = false;
      const answerId = this.$route.params.answerId;
      const answerRes = await fetchAnswer(answerId);
      this.answerData = new Answer(answerRes.res);
      this.causesDeterminationForm = this.answerData.causesDetermination;
      this.answerStatus = this.answerData.status == 'Aprobada';
      this.statusForm.statusComment = this.answerData.statusComment;
    }
    this.isLoadingAnswerItem = false;
  }

  async getOperationalTaskFiltered() {
    const today = this.claimData.registrationData.date;
    const interestGroupId = (vxm.authentication
      .authenticatedUser as UserAuthenticatedData).interestGroupId;
    await vxm.operationalTasks.getOperationalTaskList();
    this.operationalTasks = JSON.parse(
      JSON.stringify(vxm.operationalTasks.operationalTaskList)
    );
    this.operationalTasks = this.operationalTasks.filter(task => {
      return task.interestGroupId == interestGroupId;
    });
    this.operationalTasks = this.operationalTasks.filter(task => {
      return task.taskStatus.estimatedData.startDate == today;
    });
    if (this.$route.name == 'reclamo-respuesta') {
      const answerId = this.$route.params.answerId;
      const operationalTasks = await fetchOperationalTasksSelected(
        today,
        interestGroupId,
        answerId
      );
      this.operationalTasks = operationalTasks.res.available;
      this.selectedTasks = operationalTasks.res.selected;
    }
  }

  addCorrectiveMeasure() {
    const measure: Measures = {
      title: 'Medida Correctiva',
      operationType: this.taskTypeList[0],
      area: this.areaList[0],
      activities: [],
    };

    this.answerData.correctiveMeasures.measures.push(measure);
  }

  removeCorrectiveMeasure(index: number) {
    this.answerData.correctiveMeasures.measures.splice(index, 1);
  }

  addActivityToMeasure(index: number) {
    const activity: Activity = {
      title: 'Actividad',
      observation: '',
      proposedSolution: '',
      implementationStartDate: format(new Date(), 'yyyy-MM-dd'),
      implementationDuration: '',
      implementationEndDate: format(new Date(), 'yyyy-MM-dd'),
      comments: '',
      progress: 0,
      state: 'Sin avance',
    };

    this.answerData.correctiveMeasures.measures[index].activities.push(
      activity
    );
  }

  removeActivityInMeasure(idx: number, index: number) {
    this.answerData.correctiveMeasures.measures[idx].activities.splice(
      index,
      1
    );
  }

  onStatusChange(value: boolean) {
    this.statusRules.statusComment[0].required = !value;
    this.statusPlaceholder = value
      ? 'Motivo de aprobación (opcional)'
      : 'Motivo de rechazo';
    this.$refs.statusForm.resetFields();
  }

  onStatusCommentChange() {
    this.$refs.statusForm.resetFields();
  }

  onStepChange(current: any) {
    this.currentStep = current;
  }

  updateActivityProgress(activity: Activity, type: string) {
    let percent =
      type == 'up' ? activity.progress + 10 : activity.progress - 10;

    if (percent > 100 || percent < 0) {
      percent = activity.progress;
    }

    activity.progress = percent;
    this.updateActivityState(activity);
  }

  updateActivityState(activity: Activity) {
    if (activity.progress == 0) return (activity.state = 'Sin avance');
    if (activity.progress == 100) return (activity.state = 'Completado');
    return (activity.state = 'En Ejecución');
  }

  goToAnswers() {
    this.$router.push({
      name: 'reclamo-respuestas',
      params: { id: this.claimData.getId() },
    });
  }

  async submitAnswerItem() {
    this.isLoadingAnswerItem = true;
    this.answerData.setClaimId(this.$route.params.id);
    this.answerData.setSelectedTaskIds(this.selectedTasks);
    const response = await newAnswer(this.answerData);
    await vxm.claims.getAnswerList();
    const responseAnswers = vxm.claims.answerList.filter(
      answer => answer.claimId == this.$route.params.id
    );
    this.claimData.updateAssociatedAnswers(responseAnswers);
    this.claimData.updateClaimStage();
    await editClaim(this.claimData.getId(), this.claimData.getWithOutId());
    this.isLoadingAnswerItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'reclamo-respuestas',
      });
    }
  }

  async updateAnswer() {
    this.$refs.statusForm.validate(async (valid: boolean) => {
      if (valid) {
        this.isLoadingAnswerItem = true;
        const status: answerStatus = this.answerStatus
          ? 'Aprobada'
          : 'Rechazada';
        this.answerData.updateAnswer(status);
        this.answerData.setSelectedTaskIds(this.selectedTasks);
        this.answerData.statusComment = this.statusForm.statusComment;
        const response = await editAnswer(
          this.answerData.getId(),
          this.answerData.getWithOutId()
        );
        await vxm.claims.getAnswerList();
        const responseAnswers = vxm.claims.answerList.filter(
          answer => answer.claimId == this.$route.params.id
        );
        this.claimData.updateAssociatedAnswers(responseAnswers);
        this.claimData.updateClaimStage();
        await editClaim(this.claimData.getId(), this.claimData.getWithOutId());
        this.isLoadingAnswerItem = false;
        if (response.status == 'Failed') {
          this.$message.error(response.msg);
        } else {
          this.$message.success(response.msg);
          this.$router.push({
            name: 'reclamo-respuestas',
          });
        }
      }
    });
  }
}
