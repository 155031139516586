import { InterestGroupActivity } from '../interfaces';

const fetchPrimaryActivities = () => {
  const activities: InterestGroupActivity[] = [
    {
      code: '11101',
      activity: 'Cultivo de trigo',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11102',
      activity: 'Cultivo de maíz',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11103',
      activity: 'Cultivo de avena',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11104',
      activity: 'Cultivo de cebada',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11105',
      activity:
        'Cultivo de otros cereales (excepto trigo, maíz, avena y cebada)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11106',
      activity: 'Cultivo de porotos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11107',
      activity: 'Cultivo de lupino',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11108',
      activity: 'Cultivo de otras legumbres (excepto porotos y lupino)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11109',
      activity: 'Cultivo de semillas de raps',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11110',
      activity: 'Cultivo de semillas de maravilla (girasol)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11111',
      activity:
        'Cultivo de semillas de cereales, legumbres y oleaginosas (excepto semillas de raps y maravilla)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11200',
      activity: 'Cultivo de arroz',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11301',
      activity: 'Cultivo de papas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11302',
      activity: 'Cultivo de camotes',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11303',
      activity: 'Cultivo de otros tubérculos (excepto papas y camotes)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11304',
      activity: 'Cultivo de remolacha azucarera',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11305',
      activity: 'Cultivo de semillas de hortalizas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11306',
      activity: 'Cultivo de hortalizas y melones',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11400',
      activity: 'Cultivo de caña de azúcar',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11500',
      activity: 'Cultivo de tabaco',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11600',
      activity: 'Cultivo de plantas de fibra',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11901',
      activity: 'Cultivo de flores',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11902',
      activity:
        'Cultivos forrajeros en praderas mejoradas o sembradas; cultivos suplementarios forrajeros',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '11903',
      activity:
        'Cultivos de semillas de flores; cultivo de semillas de plantas forrajeras',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12111',
      activity:
        'Cultivo de uva destinada a la producción de pisco y aguardiente',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12112',
      activity: 'Cultivo de uva destinada a la producción de vino',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12120',
      activity: 'Cultivo de uva para mesa',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12200',
      activity:
        'Cultivo de frutas tropicales y subtropicales (incluye el cultivo de paltas)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12300',
      activity: 'Cultivo de cítricos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12400',
      activity: 'Cultivo de frutas de pepita y de hueso',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12501',
      activity: 'Cultivo de semillas de frutas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12502',
      activity: 'Cultivo de otros frutos y nueces de árboles y arbustos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12600',
      activity:
        'Cultivo de frutos oleaginosos (incluye el cultivo de aceitunas)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12700',
      activity:
        'Cultivo de plantas con las que se preparan bebidas (incluye el cultivo de café, té y mate)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12801',
      activity: 'Cultivo de especias',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12802',
      activity: 'Cultivo de plantas aromáticas, medicinales y farmacéuticas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '12900',
      activity: 'Cultivo de otras plantas perennes',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '13000',
      activity:
        'Cultivo de plantas vivas incluida la producción en viveros (excepto viveros forestales)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14101',
      activity: 'Cría de ganado bovino para la producción lechera',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14102',
      activity:
        'Cría de ganado bovino para la producción de carne o como ganado reproductor',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14200',
      activity: 'Cría de caballos y otros equinos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14300',
      activity: 'Cría de llamas, alpacas, vicuñas, guanacos y otros camélidos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14410',
      activity: 'Cría de ovejas (ovinos)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14420',
      activity: 'Cría de cabras (caprinos)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14500',
      activity: 'Cría de cerdos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14601',
      activity: 'Cría de aves de corral para la producción de carne',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14602',
      activity: 'Cría de aves de corral para la producción de huevos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14901',
      activity: 'Apicultura',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '14909',
      activity: 'Cría de otros animales n.c.p.',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '15000',
      activity:
        'Cultivo de productos agrícolas en combinación con la cría de animales (explotación mixta)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '16100',
      activity: 'activityes de apoyo a la agricultura',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '16200',
      activity: 'activityes de apoyo a la ganadería',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '16300',
      activity: 'activityes poscosecha',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '16400',
      activity: 'Tratamiento de semillas para propagación',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '17000',
      activity:
        'Caza ordinaria y mediante trampas y activityes de servicios conexas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '21001',
      activity: 'Explotación de viveros forestales',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '21002',
      activity:
        'Silvicultura y otras activityes forestales (excepto explotación de viveros forestales)',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '22000',
      activity: 'Extracción de madera',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '23000',
      activity: 'Recolección de productos forestales distintos de la madera',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '24001',
      activity:
        'Servicios de forestación a cambio de una retribución o por contrata',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '24002',
      activity:
        'Servicios de corta de madera a cambio de una retribución o por contrata',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '24003',
      activity: 'Servicios de extinción y prevención de incendios forestales',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '24009',
      activity: 'Otros servicios de apoyo a la silvicultura n.c.p.',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '31110',
      activity: 'Pesca marítima industrial, excepto de barcos factoría',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '31120',
      activity: 'Pesca marítima artesanal',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '31130',
      activity: 'Recolección y extracción de productos marinos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '31140',
      activity: 'Servicios relacionados con la pesca marítima',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '31200',
      activity: 'Pesca de agua dulce',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '32110',
      activity: 'Cultivo y crianza de peces marinos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '32120',
      activity: 'Cultivo, reproducción y manejo de algas marinas',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '32130',
      activity: 'Reproducción y cría de moluscos, crustáceos y gusanos marinos',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '32140',
      activity: 'Servicios relacionados con la acuicultura marina',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '32200',
      activity: 'Acuicultura de agua dulce',
      businessLine: 'Agricultura, ganadería, silvicultura y pesca',
    },
    {
      code: '40000',
      activity: 'Extracción y procesamiento de cobre',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '51000',
      activity: 'Extracción de carbón de piedra',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '52000',
      activity: 'Extracción de lignito',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '61000',
      activity: 'Extracción de petróleo crudo',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '62000',
      activity: 'Extracción de gas natural',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '71000',
      activity: 'Extracción de minerales de hierro',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '72100',
      activity: 'Extracción de minerales de uranio y torio',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '72910',
      activity: 'Extracción de oro y plata',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '72991',
      activity: 'Extracción de zinc y plomo',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '72992',
      activity: 'Extracción de manganeso',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '72999',
      activity:
        'Extracción de otros minerales metalíferos no ferrosos n.c.p. (excepto zinc, plomo y manganeso)',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '81000',
      activity: 'Extracción de piedra, arena y arcilla',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '89110',
      activity: 'Extracción y procesamiento de litio',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '89190',
      activity:
        'Extracción de minerales para la fabricación de abonos y productos químicos n.c.p.',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '89200',
      activity: 'Extracción de turba',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '89300',
      activity: 'Extracción de sal',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '89900',
      activity: 'Explotación de otras minas y canteras n.c.p.',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '91001',
      activity:
        'activityes de apoyo para la extracción de petróleo y gas natural prestados por empresas',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '91002',
      activity:
        'activityes de apoyo para la extracción de petróleo y gas natural prestados por profesionales',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '99001',
      activity:
        'activityes de apoyo para la explotación de otras minas y canteras prestados por empresas',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '99002',
      activity:
        'activityes de apoyo para la explotación de otras minas y canteras prestados por profesionales',
      businessLine: 'Explotación de minas y canteras',
    },
    {
      code: '101011',
      activity:
        'Explotación de mataderos de bovinos, ovinos, equinos, caprinos, porcinos y camélidos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '101019',
      activity:
        'Explotación de mataderos de aves y de otros tipos de animales n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '101020',
      activity: 'Elaboración y conservación de carne y productos cárnicos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102010',
      activity: 'Producción de harina de pescado',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102020',
      activity: 'Elaboración y conservación de salmónidos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102030',
      activity:
        'Elaboración y conservación de otros pescados, en plantas en tierra (excepto barcos factoría)',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102040',
      activity:
        'Elaboración y conservación de crustáceos, moluscos y otros productos acuáticos, en plantas en tierra',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102050',
      activity:
        'activityes de elaboración y conservación de pescado, realizadas en barcos factoría',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '102060',
      activity: 'Elaboración y procesamiento de algas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '103000',
      activity: 'Elaboración y conservación de frutas, legumbres y hortalizas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '104000',
      activity:
        'Elaboración de aceites y grasas de origen vegetal y animal (excepto elaboración de mantequilla)',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '105000',
      activity: 'Elaboración de productos lácteos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '106101',
      activity: 'Molienda de trigo: producción de harina, sémola y gránulos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '106102',
      activity: 'Molienda de arroz; producción de harina de arroz',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '106109',
      activity: 'Elaboración de otros productos de molinería n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '106200',
      activity: 'Elaboración de almidones y productos derivados del almidón',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107100',
      activity: 'Elaboración de productos de panadería y pastelería',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107200',
      activity: 'Elaboración de azúcar',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107300',
      activity: 'Elaboración de cacao, chocolate y de productos de confitería',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107400',
      activity:
        'Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107500',
      activity:
        'Elaboración de comidas y platos preparados envasados, rotulados y con información nutricional',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107901',
      activity: 'Elaboración de té, café, mate e infusiones de hierbas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107902',
      activity: 'Elaboración de levaduras naturales o artificiales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107903',
      activity:
        'Elaboración de vinagres, mostazas, mayonesas y condimentos en general',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '107909',
      activity: 'Elaboración de otros productos alimenticios n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '108000',
      activity: 'Elaboración de piensos preparados para animales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110110',
      activity: 'Elaboración de pisco (industrias pisqueras)',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110120',
      activity:
        'Destilación, rectificación y mezclas de bebidas alcohólicas; excepto pisco',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110200',
      activity: 'Elaboración de vinos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110300',
      activity: 'Elaboración de bebidas malteadas y de malta',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110401',
      activity: 'Elaboración de bebidas no alcohólicas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '110402',
      activity: 'Producción de aguas minerales y otras aguas embotelladas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '120001',
      activity: 'Elaboración de cigarros y cigarrillos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '120009',
      activity: 'Elaboración de otros productos de tabaco n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '131100',
      activity: 'Preparación e hilatura de fibras textiles',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '131200',
      activity: 'Tejedura de productos textiles',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '131300',
      activity: 'Acabado de productos textiles',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '139100',
      activity: 'Fabricación de tejidos de punto y ganchillo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '139200',
      activity:
        'Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '139300',
      activity: 'Fabricación de tapices y alfombras',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '139400',
      activity: 'Fabricación de cuerdas, cordeles, bramantes y redes',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '139900',
      activity: 'Fabricación de otros productos textiles n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '141001',
      activity:
        'Fabricación de prendas de vestir de materiales textiles y similares',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '141002',
      activity:
        'Fabricación de prendas de vestir de cuero natural o artificial',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '141003',
      activity: 'Fabricación de accesorios de vestir',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '141004',
      activity: 'Fabricación de ropa de trabajo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '142000',
      activity: 'Fabricación de artículos de piel',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '143000',
      activity: 'Fabricación de artículos de punto y ganchillo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '151100',
      activity: 'Curtido y adobo de cueros; adobo y teñido de pieles',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '151200',
      activity:
        'Fabricación de maletas, bolsos y artículos similares, artículos de talabartería y guarnicionería',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '152000',
      activity: 'Fabricación de calzado',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '161000',
      activity: 'Aserrado y acepilladura de madera',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '162100',
      activity:
        'Fabricación de hojas de madera para enchapado y tableros a base de madera',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '162200',
      activity:
        'Fabricación de partes y piezas de carpintería para edificios y construcciones',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '162300',
      activity: 'Fabricación de recipientes de madera',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '162900',
      activity:
        'Fabricación de otros productos de madera, de artículos de corcho, paja y materiales trenzables',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '170110',
      activity: 'Fabricación de celulosa y otras pastas de madera',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '170190',
      activity:
        'Fabricación de papel y cartón para su posterior uso industrial n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '170200',
      activity:
        'Fabricación de papel y cartón ondulado y de envases de papel y cartón',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '170900',
      activity: 'Fabricación de otros artículos de papel y cartón',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '181101',
      activity: 'Impresión de libros',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '181109',
      activity: 'Otras activityes de impresión n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '181200',
      activity: 'activityes de servicios relacionadas con la impresión',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '182000',
      activity: 'Reproducción de grabaciones',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '191000',
      activity: 'Fabricación de productos de hornos de coque',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '192000',
      activity: 'Fabricación de productos de la refinación del petróleo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '201101',
      activity:
        'Fabricación de carbón vegetal (excepto activado); fabricación de briquetas de carbón vegetal',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '201109',
      activity: 'Fabricación de otras sustancias químicas básicas n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '201200',
      activity: 'Fabricación de abonos y compuestos de nitrógeno',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '201300',
      activity:
        'Fabricación de plásticos y caucho sintético en formas primarias',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '202100',
      activity:
        'Fabricación de plaguicidas y otros productos químicos de uso agropecuario',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '202200',
      activity:
        'Fabricación de pinturas, barnices y productos de revestimiento, tintas de imprenta y masillas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '202300',
      activity:
        'Fabricación de jabones y detergentes, preparados para limpiar, perfumes y preparados de tocador',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '202901',
      activity: 'Fabricación de explosivos y productos pirotécnicos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '202909',
      activity: 'Fabricación de otros productos químicos n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '203000',
      activity: 'Fabricación de fibras artificiales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '210000',
      activity:
        'Fabricación de productos farmacéuticos, sustancias químicas medicinales y productos botánicos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '221100',
      activity:
        'Fabricación de cubiertas y cámaras de caucho; recauchutado y renovación de cubiertas de caucho',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '221900',
      activity: 'Fabricación de otros productos de caucho',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '222000',
      activity: 'Fabricación de productos de plástico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '231001',
      activity: 'Fabricación de vidrio plano',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '231002',
      activity: 'Fabricación de vidrio hueco',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '231003',
      activity: 'Fabricación de fibras de vidrio',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '231009',
      activity: 'Fabricación de productos de vidrio n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239100',
      activity: 'Fabricación de productos refractarios',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239200',
      activity: 'Fabricación de materiales de construcción de arcilla',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239300',
      activity: 'Fabricación de otros productos de porcelana y de cerámica',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239400',
      activity: 'Fabricación de cemento, cal y yeso',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239500',
      activity: 'Fabricación de artículos de hormigón, cemento y yeso',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239600',
      activity: 'Corte, talla y acabado de la piedra',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '239900',
      activity: 'Fabricación de otros productos minerales no metálicos n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '241000',
      activity: 'Industrias básicas de hierro y acero',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '242001',
      activity: 'Fabricación de productos primarios de cobre',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '242002',
      activity: 'Fabricación de productos primarios de aluminio',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '242009',
      activity:
        'Fabricación de productos primarios de metales preciosos y de otros metales no ferrosos n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '243100',
      activity: 'Fundición de hierro y acero',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '243200',
      activity: 'Fundición de metales no ferrosos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '251100',
      activity: 'Fabricación de productos metálicos para uso estructural',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '251201',
      activity:
        'Fabricación de recipientes de metal para gases comprimidos o licuados',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '251209',
      activity:
        'Fabricación de tanques, depósitos y recipientes de metal n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '251300',
      activity:
        'Fabricación de generadores de vapor, excepto calderas de agua caliente para calefacción central',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '252000',
      activity: 'Fabricación de armas y municiones',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '259100',
      activity:
        'Forja, prensado, estampado y laminado de metales; pulvimetalurgia',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '259200',
      activity: 'Tratamiento y revestimiento de metales; maquinado',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '259300',
      activity:
        'Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '259900',
      activity: 'Fabricación de otros productos elaborados de metal n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '261000',
      activity: 'Fabricación de componentes y tableros electrónicos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '262000',
      activity: 'Fabricación de computadores y equipo periférico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '263000',
      activity: 'Fabricación de equipo de comunicaciones',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '264000',
      activity: 'Fabricación de aparatos electrónicos de consumo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '265100',
      activity:
        'Fabricación de equipo de medición, prueba, navegación y control',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '265200',
      activity: 'Fabricación de relojes',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '266000',
      activity:
        'Fabricación de equipo de irradiación y equipo electrónico de uso médico y terapéutico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '267000',
      activity: 'Fabricación de instrumentos ópticos y equipo fotográfico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '268000',
      activity: 'Fabricación de soportes magnéticos y ópticos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '271000',
      activity:
        'Fabricación de motores, generadores y transformadores eléctricos, aparatos de distribución y control',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '272000',
      activity: 'Fabricación de pilas, baterías y acumuladores',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '273100',
      activity: 'Fabricación de cables de fibra óptica',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '273200',
      activity: 'Fabricación de otros hilos y cables eléctricos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '273300',
      activity: 'Fabricación de dispositivos de cableado',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '274000',
      activity: 'Fabricación de equipo eléctrico de iluminación',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '275000',
      activity: 'Fabricación de aparatos de uso doméstico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '279000',
      activity: 'Fabricación de otros tipos de equipo eléctrico',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281100',
      activity:
        'Fabricación de motores y turbinas, excepto para aeronaves, vehículos automotores y motocicletas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281200',
      activity: 'Fabricación de equipo de propulsión de fluidos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281300',
      activity: 'Fabricación de otras bombas, compresores, grifos y válvulas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281400',
      activity:
        'Fabricación de cojinetes, engranajes, trenes de engranajes y piezas de transmisión',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281500',
      activity: 'Fabricación de hornos, calderas y quemadores',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281600',
      activity: 'Fabricación de equipo de elevación y manipulación',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281700',
      activity:
        'Fabricación de maquinaria y equipo de oficina (excepto computadores y equipo periférico)',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281800',
      activity: 'Fabricación de herramientas de mano motorizadas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '281900',
      activity: 'Fabricación de otros tipos de maquinaria de uso general',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282100',
      activity: 'Fabricación de maquinaria agropecuaria y forestal',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282200',
      activity:
        'Fabricación de maquinaria para la conformación de metales y de máquinas herramienta',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282300',
      activity: 'Fabricación de maquinaria metalúrgica',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282400',
      activity:
        'Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282500',
      activity:
        'Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282600',
      activity:
        'Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '282900',
      activity: 'Fabricación de otros tipos de maquinaria de uso especial',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '291000',
      activity: 'Fabricación de vehículos automotores',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '292000',
      activity:
        'Fabricación de carrocerías para vehículos automotores; fabricación de remolques y semirremolques',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '293000',
      activity:
        'Fabricación de partes, piezas y accesorios para vehículos automotores',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '301100',
      activity:
        'Construcción de buques, embarcaciones menores y estructuras flotantes',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '301200',
      activity: 'Construcción de embarcaciones de recreo y de deporte',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '302000',
      activity: 'Fabricación de locomotoras y material rodante',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '303000',
      activity:
        'Fabricación de aeronaves, naves espaciales y maquinaria conexa',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '304000',
      activity: 'Fabricación de vehículos militares de combate',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '309100',
      activity: 'Fabricación de motocicletas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '309200',
      activity: 'Fabricación de bicicletas y de sillas de ruedas',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '309900',
      activity: 'Fabricación de otros tipos de equipo de transporte n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '310001',
      activity: 'Fabricación de muebles principalmente de madera',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '310009',
      activity: 'Fabricación de colchones; fabricación de otros muebles n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '321100',
      activity: 'Fabricación de joyas y artículos conexos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '321200',
      activity: 'Fabricación de bisutería y artículos conexos',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '322000',
      activity: 'Fabricación de instrumentos musicales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '323000',
      activity: 'Fabricación de artículos de deporte',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '324000',
      activity: 'Fabricación de juegos y juguetes',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '325001',
      activity: 'activityes de laboratorios dentales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '325009',
      activity:
        'Fabricación de instrumentos y materiales médicos, oftalmológicos y odontológicos n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '329000',
      activity: 'Otras industrias manufactureras n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331100',
      activity: 'Reparación de productos elaborados de metal',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331201',
      activity: 'Reparación de maquinaria agropecuaria y forestal',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331202',
      activity:
        'Reparación de maquinaria metalúrgica, para la minería, extracción de petróleo y para la construcción',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331203',
      activity:
        'Reparación de maquinaria para la elaboración de alimentos, bebidas y tabaco',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331204',
      activity:
        'Reparación de maquinaria para producir textiles, prendas de vestir, artículos de cuero y calzado',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331209',
      activity:
        'Reparación de otro tipo de maquinaria y equipos industriales n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331301',
      activity:
        'Reparación de equipo de medición, prueba, navegación y control',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331309',
      activity: 'Reparación de otros equipos electrónicos y ópticos n.c.p.',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331400',
      activity:
        'Reparación de equipo eléctrico (excepto reparación de equipo y enseres domésticos)',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331501',
      activity:
        'Reparación de buques, embarcaciones menores y estructuras flotantes',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331502',
      activity: 'Reparación de aeronaves y naves espaciales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331509',
      activity:
        'Reparación de otros equipos de transporte n.c.p., excepto vehículos automotores',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '331900',
      activity: 'Reparación de otros tipos de equipo',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '332000',
      activity: 'Instalación de maquinaria y equipos industriales',
      businessLine: 'Industrias manufactureras',
    },
    {
      code: '351011',
      activity: 'Generación de energía eléctrica en centrales hidroeléctricas',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '351012',
      activity: 'Generación de energía eléctrica en centrales termoeléctricas',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '351019',
      activity: 'Generación de energía eléctrica en otras centrales n.c.p.',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '351020',
      activity: 'Transmisión de energía eléctrica',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '351030',
      activity: 'Distribución de energía eléctrica',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '352010',
      activity: 'Regasificación de Gas Natural Licuado (GNL)',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '352020',
      activity:
        'Fabricación de gas; distribución de combustibles gaseosos por tubería, excepto regasificación de GNL',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '353001',
      activity: 'Suministro de vapor y de aire acondicionado',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '353002',
      activity: 'Elaboración de hielo (excepto fabricación de hielo seco)',
      businessLine:
        'Suministro de electricidad, gas, vapor y aire acondicionado',
    },
    {
      code: '360000',
      activity: 'Captación, tratamiento y distribución de agua',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '370000',
      activity: 'Evacuación y tratamiento de aguas servidas',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '381100',
      activity: 'Recogida de desechos no peligrosos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '381200',
      activity: 'Recogida de desechos peligrosos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '382100',
      activity: 'Tratamiento y eliminación de desechos no peligrosos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '382200',
      activity: 'Tratamiento y eliminación de desechos peligrosos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '383001',
      activity:
        'Recuperación y reciclamiento de desperdicios y desechos metálicos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '383002',
      activity: 'Recuperación y reciclamiento de papel',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '383003',
      activity: 'Recuperación y reciclamiento de vidrio',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '383009',
      activity:
        'Recuperación y reciclamiento de otros desperdicios y desechos n.c.p.',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '390000',
      activity:
        'activityes de descontaminación y otros servicios de gestión de desechos',
      businessLine:
        'Suministro de agua; evacuación de aguas residuales, gestión de desechos y descontaminación',
    },
    {
      code: '410010',
      activity: 'Construcción de edificios para uso residencial',
      businessLine: 'Construcción',
    },
    {
      code: '410020',
      activity: 'Construcción de edificios para uso no residencial',
      businessLine: 'Construcción',
    },
    {
      code: '421000',
      activity: 'Construcción de carreteras y líneas de ferrocarril',
      businessLine: 'Construcción',
    },
    {
      code: '422000',
      activity: 'Construcción de proyectos de servicio público',
      businessLine: 'Construcción',
    },
    {
      code: '429000',
      activity: 'Construcción de otras obras de ingeniería civil',
      businessLine: 'Construcción',
    },
    { code: '431100', activity: 'Demolición', businessLine: 'Construcción' },
    {
      code: '431200',
      activity: 'Preparación del terreno',
      businessLine: 'Construcción',
    },
    {
      code: '432100',
      activity: 'Instalaciones eléctricas',
      businessLine: 'Construcción',
    },
    {
      code: '432200',
      activity: 'Instalaciones de gasfitería, calefacción y aire acondicionado',
      businessLine: 'Construcción',
    },
    {
      code: '432900',
      activity: 'Otras instalaciones para obras de construcción',
      businessLine: 'Construcción',
    },
    {
      code: '433000',
      activity: 'Terminación y acabado de edificios',
      businessLine: 'Construcción',
    },
    {
      code: '439000',
      activity: 'Otras activityes especializadas de construcción',
      businessLine: 'Construcción',
    },
    {
      code: '451001',
      activity: 'Venta al por mayor de vehículos automotores',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '451002',
      activity:
        'Venta al por menor de vehículos automotores nuevos o usados (incluye compraventa)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '452001',
      activity: 'Servicio de lavado de vehículos automotores',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '452002',
      activity: 'Mantenimiento y reparación de vehículos automotores',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '453000',
      activity:
        'Venta de partes, piezas y accesorios para vehículos automotores',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '454001',
      activity: 'Venta de motocicletas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '454002',
      activity: 'Venta de partes, piezas y accesorios de motocicletas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '454003',
      activity: 'Mantenimiento y reparación de motocicletas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '461001',
      activity: 'Corretaje al por mayor de productos agrícolas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '461002',
      activity: 'Corretaje al por mayor de ganado',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '461009',
      activity: 'Otros tipos de corretajes o remates al por mayor n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '462010',
      activity: 'Venta al por mayor de materias primas agrícolas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '462020',
      activity: 'Venta al por mayor de animales vivos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '462090',
      activity:
        'Venta al por mayor de otras materias primas agropecuarias n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463011',
      activity: 'Venta al por mayor de frutas y verduras',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463012',
      activity: 'Venta al por mayor de carne y productos cárnicos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463013',
      activity:
        'Venta al por mayor de productos del mar (pescados, mariscos y algas)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463014',
      activity: 'Venta al por mayor de productos de confitería',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463019',
      activity:
        'Venta al por mayor de huevos, lácteos, abarrotes y de otros alimentos n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463020',
      activity: 'Venta al por mayor de bebidas alcohólicas y no alcohólicas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '463030',
      activity: 'Venta al por mayor de tabaco',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464100',
      activity:
        'Venta al por mayor de productos textiles, prendas de vestir y calzado',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464901',
      activity: 'Venta al por mayor de muebles, excepto muebles de oficina',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464902',
      activity:
        'Venta al por mayor de artículos eléctricos y electrónicos para el hogar',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464903',
      activity:
        'Venta al por mayor de artículos de perfumería, de tocador y cosméticos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464904',
      activity: 'Venta al por mayor de artículos de papelería y escritorio',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464905',
      activity: 'Venta al por mayor de libros',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464906',
      activity: 'Venta al por mayor de diarios y revistas',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464907',
      activity: 'Venta al por mayor de productos farmacéuticos y medicinales',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464908',
      activity: 'Venta al por mayor de instrumentos científicos y quirúrgicos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '464909',
      activity: 'Venta al por mayor de otros enseres domésticos n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465100',
      activity:
        'Venta al por mayor de computadores, equipo periférico y programas informáticos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465200',
      activity:
        'Venta al por mayor de equipo, partes y piezas electrónicos y de telecomunicaciones',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465300',
      activity:
        'Venta al por mayor de maquinaria, equipo y materiales agropecuarios',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465901',
      activity:
        'Venta al por mayor de maquinaria metalúrgica, para la minería, extracción de petróleo y construcción',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465902',
      activity:
        'Venta al por mayor de maquinaria para la elaboración de alimentos, bebidas y tabaco',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465903',
      activity:
        'Venta al por mayor de maquinaria para la industria textil, del cuero y del calzado',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465904',
      activity:
        'Venta al por mayor de maquinaria y equipo de oficina; venta al por mayor de muebles de oficina',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465905',
      activity:
        'Venta al por mayor de equipo de transporte(excepto vehículos automotores, motocicletas y bicicletas)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '465909',
      activity:
        'Venta al por mayor de otros tipos de maquinaria y equipo n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466100',
      activity:
        'Venta al por mayor de combustibles sólidos, líquidos y gaseosos y productos conexos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466200',
      activity: 'Venta al por mayor de metales y minerales metalíferos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466301',
      activity:
        'Venta al por mayor de madera en bruto y productos primarios de la elaboración de madera',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466302',
      activity:
        'Venta al por mayor de materiales de construcción, artículos de ferretería, gasfitería y calefacción',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466901',
      activity: 'Venta al por mayor de productos químicos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466902',
      activity: 'Venta al por mayor de desechos metálicos (chatarra)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '466909',
      activity:
        'Venta al por mayor de desperdicios, desechos y otros productos n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '469000',
      activity: 'Venta al por mayor no especializada',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '471100',
      activity:
        'Venta al por menor en comercios de alimentos, bebidas o tabaco (supermercados e hipermercados)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '471910',
      activity:
        'Venta al por menor en comercios de vestuario y productos para el hogar (grandes tiendas)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '471990',
      activity:
        'Otras activityes de venta al por menor en comercios no especializados n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472101',
      activity:
        'Venta al por menor de alimentos en comercios especializados (almacenes pequeños y minimarket)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472102',
      activity:
        'Venta al por menor en comercios especializados de carne y productos cárnicos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472103',
      activity:
        'Venta al por menor en comercios especializados de frutas y verduras (verdulerías)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472104',
      activity:
        'Venta al por menor en comercios especializados de pescado, mariscos y productos conexos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472105',
      activity:
        'Venta al por menor en comercios especializados de productos de panadería y pastelería',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472109',
      activity:
        'Venta al por menor en comercios especializados de huevos, confites y productos alimenticios n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472200',
      activity:
        'Venta al por menor de bebidas alcohólicas y no alcohólicas en comercios especializados (botillerías)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '472300',
      activity:
        'Venta al por menor de tabaco y productos de tabaco en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '473000',
      activity:
        'Venta al por menor de combustibles para vehículos automotores en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '474100',
      activity:
        'Venta al por menor de computadores, equipo periférico, programas informáticos y equipo de telecom.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '474200',
      activity:
        'Venta al por menor de equipo de sonido y de video en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475100',
      activity:
        'Venta al por menor de telas, lanas, hilos y similares en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475201',
      activity:
        'Venta al por menor de artículos de ferretería y materiales de construcción',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475202',
      activity:
        'Venta al por menor de pinturas, barnices y lacas en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475203',
      activity:
        'Venta al por menor de productos de vidrio en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475300',
      activity:
        'Venta al por menor de tapices, alfombras y cubrimientos para paredes y pisos',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475901',
      activity:
        'Venta al por menor de muebles y colchones en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475902',
      activity:
        'Venta al por menor de instrumentos musicales en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '475909',
      activity:
        'Venta al por menor de aparatos eléctricos, textiles para el hogar y otros enseres domésticos n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476101',
      activity: 'Venta al por menor de libros en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476102',
      activity:
        'Venta al por menor de diarios y revistas en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476103',
      activity:
        'Venta al por menor de artículos de papelería y escritorio en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476200',
      activity:
        'Venta al por menor de grabaciones de música y de video en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476301',
      activity:
        'Venta al por menor de artículos de caza y pesca en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476302',
      activity:
        'Venta al por menor de bicicletas y sus repuestos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476309',
      activity:
        'Venta al por menor de otros artículos y equipos de deporte n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '476400',
      activity:
        'Venta al por menor de juegos y juguetes en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477101',
      activity: 'Venta al por menor de calzado en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477102',
      activity:
        'Venta al por menor de prendas y accesorios de vestir en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477103',
      activity:
        'Venta al por menor de carteras, maletas y otros accesorios de viaje en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477201',
      activity:
        'Venta al por menor de productos farmacéuticos y medicinales en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477202',
      activity:
        'Venta al por menor de artículos ortopédicos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477203',
      activity:
        'Venta al por menor de artículos de perfumería, de tocador y cosméticos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477310',
      activity:
        'Venta al por menor de gas licuado en bombonas (cilindros) en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477391',
      activity:
        'Venta al por menor de alimento y accesorios para mascotas en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477392',
      activity:
        'Venta al por menor de armas y municiones en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477393',
      activity:
        'Venta al por menor de artículos ópticos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477394',
      activity:
        'Venta al por menor de artículos de joyería, bisutería y relojería en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477395',
      activity:
        'Venta al por menor de carbón, leña y otros combustibles de uso doméstico en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477396',
      activity:
        'Venta al por menor de recuerdos, artesanías y artículos religiosos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477397',
      activity:
        'Venta al por menor de flores, plantas, arboles, semillas y abonos en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477398',
      activity: 'Venta al por menor de mascotas en comercios especializados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477399',
      activity:
        'Venta al por menor de otros productos en comercios especializados n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477401',
      activity: 'Venta al por menor de antigüedades en comercios',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477402',
      activity: 'Venta al por menor de ropa usada en comercios',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '477409',
      activity:
        'Venta al por menor de otros artículos de segunda mano en comercios n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '478100',
      activity:
        'Venta al por menor de alimentos, bebidas y tabaco en puestos de venta y mercados (incluye ferias)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '478200',
      activity:
        'Venta al por menor de productos textiles, prendas de vestir y calzado en puestos de venta y mercados',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '478900',
      activity:
        'Venta al por menor de otros productos en puestos de venta y mercados (incluye ferias)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '479100',
      activity: 'Venta al por menor por correo, por Internet y vía telefónica',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '479901',
      activity:
        'Venta al por menor realizada por independientes en la locomoción colectiva (Ley 20.388)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '479902',
      activity: 'Venta al por menor mediante máquinas expendedoras',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '479903',
      activity:
        'Venta al por menor por comisionistas (no dependientes de comercios)',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '479909',
      activity:
        'Otras activityes de venta por menor no realizadas en comercios, puestos de venta o mercados n.c.p.',
      businessLine:
        'Comercio al por mayor y al por menor; reparación de vehículos automotores y motocicletas',
    },
    {
      code: '491100',
      activity: 'Transporte interurbano de pasajeros por ferrocarril',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '491200',
      activity: 'Transporte de carga por ferrocarril',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492110',
      activity:
        'Transporte urbano y suburbano de pasajeros vía metro y metrotren',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492120',
      activity:
        'Transporte urbano y suburbano de pasajeros vía locomoción colectiva',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492130',
      activity: 'Transporte de pasajeros vía taxi colectivo',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492190',
      activity:
        'Otras activityes de transporte urbano y suburbano de pasajeros por vía terrestre n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492210',
      activity: 'Servicios de transporte de escolares',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492220',
      activity: 'Servicios de transporte de trabajadores',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492230',
      activity:
        'Servicios de transporte de pasajeros en taxis libres y radiotaxis',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492240',
      activity: 'Servicios de transporte a turistas',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492250',
      activity: 'Transporte de pasajeros en buses interurbanos',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492290',
      activity:
        'Otras activityes de transporte de pasajeros por vía terrestre n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '492300',
      activity: 'Transporte de carga por carretera',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '493010',
      activity: 'Transporte por oleoductos',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '493020',
      activity: 'Transporte por gasoductos',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '493090',
      activity: 'Otras activityes de transporte por tuberías n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '501100',
      activity: 'Transporte de pasajeros marítimo y de cabotaje',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '501200',
      activity: 'Transporte de carga marítimo y de cabotaje',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '502100',
      activity: 'Transporte de pasajeros por vías de navegación interiores',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '502200',
      activity: 'Transporte de carga por vías de navegación interiores',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '511000',
      activity: 'Transporte de pasajeros por vía aérea',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '512000',
      activity: 'Transporte de carga por vía aérea',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '521001',
      activity: 'Explotación de frigoríficos para almacenamiento y depósito',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '521009',
      activity: 'Otros servicios de almacenamiento y depósito n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522110',
      activity: 'Explotación de terminales terrestres de pasajeros',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522120',
      activity:
        'Explotación de estacionamientos de vehículos automotores y parquímetros',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522130',
      activity: 'Servicios prestados por concesionarios de carreteras',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522190',
      activity:
        'activityes de servicios vinculadas al transporte terrestre n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522200',
      activity: 'activityes de servicios vinculadas al transporte acuático',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522300',
      activity: 'activityes de servicios vinculadas al transporte aéreo',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522400',
      activity: 'Manipulación de la carga',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522910',
      activity: 'Agencias de aduanas',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522920',
      activity: 'Agencias de naves',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '522990',
      activity: 'Otras activityes de apoyo al transporte n.c.p.',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '531000',
      activity: 'activityes postales',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '532000',
      activity: 'activityes de mensajería',
      businessLine: 'Transporte y almacenamiento',
    },
    {
      code: '551001',
      activity: 'activityes de hoteles',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '551002',
      activity: 'activityes de moteles',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '551003',
      activity: 'activityes de residenciales para turistas',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '551009',
      activity: 'Otras activityes de alojamiento para turistas n.c.p.',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '552000',
      activity: 'activityes de camping y de parques para casas rodantes',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '559001',
      activity: 'activityes de residenciales para estudiantes y trabajadores',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '559009',
      activity: 'Otras activityes de alojamiento n.c.p.',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '561000',
      activity: 'activityes de restaurantes y de servicio móvil de comidas',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '562100',
      activity: 'Suministro de comidas por encargo (Servicios de banquetería)',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '562900',
      activity:
        'Suministro industrial de comidas por encargo; concesión de servicios de alimentación',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '563001',
      activity:
        'activityes de discotecas y cabaret (night club), con predominio del servicio de bebidas',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '563009',
      activity: 'Otras activityes de servicio de bebidas n.c.p.',
      businessLine: 'activityes de alojamiento y de servicio de comidas',
    },
    {
      code: '581100',
      activity: 'Edición de libros',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '581200',
      activity: 'Edición de directorios y listas de correo',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '581300',
      activity: 'Edición de diarios, revistas y otras publicaciones periódicas',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '581900',
      activity: 'Otras activityes de edición',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '582000',
      activity: 'Edición de programas informáticos',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '591100',
      activity:
        'activityes de producción de películas cinematográficas, videos y programas de televisión',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '591200',
      activity:
        'activityes de postproducción de películas cinematográficas, videos y programas de televisión',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '591300',
      activity:
        'activityes de distribución de películas cinematográficas, videos y programas de televisión',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '591400',
      activity:
        'activityes de exhibición de películas cinematográficas y cintas de video',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '592000',
      activity: 'activityes de grabación de sonido y edición de música',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '601000',
      activity: 'Transmisiones de radio',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '602000',
      activity: 'Programación y transmisiones de televisión',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '611010',
      activity: 'Telefonía fija',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '611020',
      activity: 'Telefonía larga distancia',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '611030',
      activity: 'Televisión de pago por cable',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '611090',
      activity: 'Otros servicios de telecomunicaciones alámbricas n.c.p.',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '612010',
      activity: 'Telefonía móvil celular',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '612020',
      activity: 'Radiocomunicaciones móviles',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '612030',
      activity: 'Televisión de pago inalámbrica',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '612090',
      activity: 'Otros servicios de telecomunicaciones inalámbricas n.c.p.',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '613010',
      activity: 'Telefonía móvil satelital',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '613020',
      activity: 'Televisión de pago satelital',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '613090',
      activity: 'Otros servicios de telecomunicaciones por satélite n.c.p.',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '619010',
      activity: 'Centros de llamados y centros de acceso a Internet',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '619090',
      activity: 'Otras activityes de telecomunicaciones n.c.p.',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '620100',
      activity: 'activityes de programación informática',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '620200',
      activity:
        'activityes de consultoría de informática y de gestión de instalaciones informáticas',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '620900',
      activity:
        'Otras activityes de tecnología de la información y de servicios informáticos',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '631100',
      activity: 'Procesamiento de datos, hospedaje y activityes conexas',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '631200',
      activity: 'Portales web',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '639100',
      activity: 'activityes de agencias de noticias',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '639900',
      activity: 'Otras activityes de servicios de información n.c.p.',
      businessLine: 'Información y comunicaciones',
    },
    {
      code: '641100',
      activity: 'Banca central',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '641910',
      activity: 'activityes bancarias',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '641990',
      activity: 'Otros tipos de intermediación monetaria n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '642000',
      activity: 'activityes de sociedades de cartera',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '643000',
      activity:
        'Fondos y sociedades de inversión y entidades financieras similares',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649100',
      activity: 'Leasing financiero',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649201',
      activity: 'Financieras',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649202',
      activity: 'activityes de crédito prendario',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649203',
      activity: 'Cajas de compensación',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649209',
      activity: 'Otras activityes de concesión de crédito n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '649900',
      activity:
        'Otras activityes de servicios financieros, excepto las de seguros y fondos de pensiones n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '651100',
      activity: 'Seguros de vida',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '651210',
      activity: 'Seguros generales, excepto activityes de Isapres',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '651220',
      activity: 'activityes de Isapres',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '652000',
      activity: 'Reaseguros',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '653000',
      activity: 'Fondos de pensiones',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661100',
      activity: 'Administración de mercados financieros',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661201',
      activity: 'activityes de securitizadoras',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661202',
      activity: 'Corredores de bolsa',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661203',
      activity: 'Agentes de valores',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661204',
      activity: 'activityes de casas de cambio y operadores de divisa',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661209',
      activity: 'Otros servicios de corretaje de valores y commodities n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661901',
      activity: 'activityes de cámaras de compensación',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661902',
      activity: 'Administración de tarjetas de crédito',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661903',
      activity:
        'Empresas de asesoría y consultoría en inversión financiera; sociedades de apoyo al giro',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661904',
      activity: 'activityes de clasificadoras de riesgo',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '661909',
      activity:
        'Otras activityes auxiliares de las activityes de servicios financieros n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '662100',
      activity:
        'Evaluación de riesgos y daños (incluye activityes de liquidadores de seguros)',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '662200',
      activity: 'activityes de agentes y corredores de seguros',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '662900',
      activity:
        'Otras activityes auxiliares de las activityes de seguros y fondos de pensiones',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663010',
      activity: 'Administradoras de Fondos de Pensiones (AFP)',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663091',
      activity: 'Administradoras de fondos de inversión',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663092',
      activity: 'Administradoras de fondos mutuos',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663093',
      activity:
        'Administradoras de fices (fondos de inversión de capital extranjero)',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663094',
      activity: 'Administradoras de fondos para la vivienda',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '663099',
      activity: 'Administradoras de fondos para otros fines n.c.p.',
      businessLine: 'activityes financieras y de seguros',
    },
    {
      code: '681011',
      activity:
        'Alquiler de bienes inmuebles amoblados o con equipos y maquinarias',
      businessLine: 'activityes inmobiliarias',
    },
    {
      code: '681012',
      activity: 'Compra, venta y alquiler (excepto amoblados) de inmuebles',
      businessLine: 'activityes inmobiliarias',
    },
    {
      code: '681020',
      activity: 'Servicios imputados de alquiler de viviendas',
      businessLine: 'activityes inmobiliarias',
    },
    {
      code: '682000',
      activity:
        'activityes inmobiliarias realizadas a cambio de una retribución o por contrata',
      businessLine: 'activityes inmobiliarias',
    },
    {
      code: '691001',
      activity: 'Servicios de asesoramiento y representación jurídica',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '691002',
      activity: 'Servicio notarial',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '691003',
      activity: 'Conservador de bienes raíces',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '691004',
      activity: 'Receptores judiciales',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '691009',
      activity:
        'Servicios de arbitraje; síndicos de quiebra y peritos judiciales; otras activityes jurídicas n.c.p.',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '692000',
      activity:
        'activityes de contabilidad, teneduría de libros y auditoría; consultoría fiscal',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '701000',
      activity: 'activityes de oficinas principales',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '702000',
      activity: 'activityes de consultoría de gestión',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '711001',
      activity:
        'Servicios de arquitectura (diseño de edificios, dibujo de planos de construcción, entre otros)',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '711002',
      activity:
        'Empresas de servicios de ingeniería y activityes conexas de consultoría técnica',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '711003',
      activity:
        'Servicios profesionales de ingeniería y activityes conexas de consultoría técnica',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '712001',
      activity:
        'activityes de plantas de revisión técnica para vehículos automotores',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '712009',
      activity:
        'Otros servicios de ensayos y análisis técnicos (excepto activityes de plantas de revisión técnica)',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '721000',
      activity:
        'Investigaciones y desarrollo experimental en el campo de las ciencias naturales y la ingeniería',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '722000',
      activity:
        'Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '731001',
      activity: 'Servicios de publicidad prestados por empresas',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '731002',
      activity: 'Servicios de publicidad prestados por profesionales',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '732000',
      activity: 'Estudios de mercado y encuestas de opinión pública',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '741001',
      activity: 'activityes de diseño de vestuario',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '741002',
      activity: 'activityes de diseño y decoración de interiores',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '741009',
      activity: 'Otras activityes especializadas de diseño n.c.p.',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '742001',
      activity: 'Servicios de revelado, impresión y ampliación de fotografías',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '742002',
      activity: 'Servicios y activityes de fotografía',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '742003',
      activity: 'Servicios personales de fotografía',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '749001',
      activity:
        'Asesoría y gestión en la compra o venta de pequeñas y medianas empresas',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '749002',
      activity:
        'Servicios de traducción e interpretación prestados por empresas',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '749003',
      activity: 'Servicios personales de traducción e interpretación',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '749004',
      activity:
        'activityes de agencias y agentes de representación de actores, deportistas y otras figuras públicas',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '749009',
      activity: 'Otras activityes profesionales, científicas y técnicas n.c.p.',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '750001',
      activity: 'activityes de clínicas veterinarias',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '750002',
      activity:
        'activityes de veterinarios, técnicos y otro personal auxiliar, prestados de forma independiente',
      businessLine: 'activityes profesionales, científicas y técnicas',
    },
    {
      code: '771000',
      activity: 'Alquiler de vehículos automotores sin chofer',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '772100',
      activity: 'Alquiler y arrendamiento de equipo recreativo y deportivo',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '772200',
      activity: 'Alquiler de cintas de video y discos',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '772900',
      activity:
        'Alquiler de otros efectos personales y enseres domésticos (incluye mobiliario para eventos)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '773001',
      activity:
        'Alquiler de equipos de transporte sin operario, excepto vehículos automotores',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '773002',
      activity:
        'Alquiler de maquinaria y equipo agropecuario, forestal, de construcción e ing. civil, sin operarios',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '773003',
      activity:
        'Alquiler de maquinaria y equipo de oficina, sin operarios (sin servicio administrativo)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '773009',
      activity:
        'Alquiler de otros tipos de maquinarias y equipos sin operario n.c.p.',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '774000',
      activity:
        'Arrendamiento de propiedad intelectual y similares, excepto obras protegidas por derechos de autor',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '781000',
      activity: 'activityes de agencias de empleo',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '782000',
      activity:
        'activityes de agencias de empleo temporal (incluye empresas de servicios transitorios)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '783000',
      activity: 'Otras activityes de dotación de recursos humanos',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '791100',
      activity: 'activityes de agencias de viajes',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '791200',
      activity: 'activityes de operadores turísticos',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '799000',
      activity:
        'Otros servicios de reservas y activityes conexas (incluye venta de entradas para teatro, y otros)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '801001',
      activity: 'Servicios de seguridad privada prestados por empresas',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '801002',
      activity: 'Servicio de transporte de valores en vehículos blindados',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '801003',
      activity: 'Servicios de seguridad privada prestados por independientes',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '802000',
      activity:
        'activityes de servicios de sistemas de seguridad (incluye servicios de cerrajería)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '803000',
      activity:
        'activityes de investigación (incluye activityes de investigadores y detectives privados)',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '811000',
      activity: 'activityes combinadas de apoyo a instalaciones',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '812100',
      activity: 'Limpieza general de edificios',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '812901',
      activity:
        'Desratización, desinfección y exterminio de plagas no agrícolas',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '812909',
      activity:
        'Otras activityes de limpieza de edificios e instalaciones industriales n.c.p.',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '813000',
      activity:
        'activityes de paisajismo, servicios de jardinería y servicios conexos',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '821100',
      activity: 'activityes combinadas de servicios administrativos de oficina',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '821900',
      activity:
        'Fotocopiado, preparación de documentos y otras activityes especializadas de apoyo de oficina',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '822000',
      activity: 'activityes de call-center',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '823000',
      activity: 'Organización de convenciones y exposiciones comerciales',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '829110',
      activity: 'activityes de agencias de cobro',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '829120',
      activity: 'activityes de agencias de calificación crediticia',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '829200',
      activity: 'activityes de envasado y empaquetado',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '829900',
      activity: 'Otras activityes de servicios de apoyo a las empresas n.c.p.',
      businessLine: 'activityes de servicios administrativos y de apoyo',
    },
    {
      code: '841100',
      activity: 'activityes de la administración pública en general',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '841200',
      activity:
        'Regulación de las activityes de organismos que prestan servicios sanitarios, educativos, culturales',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '841300',
      activity: 'Regulación y facilitación de la activity económica',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '842100',
      activity: 'Relaciones exteriores',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '842200',
      activity: 'activityes de defensa',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '842300',
      activity: 'activityes de mantenimiento del orden público y de seguridad',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '843010',
      activity: 'Fondo Nacional de Salud (FONASA)',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '843020',
      activity: 'Instituto de Previsión Social (IPS)',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '843090',
      activity:
        'Otros planes de seguridad social de afiliación obligatoria n.c.p.',
      businessLine:
        'Administración pública y defensa; planes de seguridad social de afiliación obligatoria',
    },
    {
      code: '850011',
      activity: 'Enseñanza preescolar pública',
      businessLine: 'Enseñanza',
    },
    {
      code: '850012',
      activity:
        'Enseñanza primaria, secundaria científico humanista y técnico profesional pública',
      businessLine: 'Enseñanza',
    },
    {
      code: '850021',
      activity: 'Enseñanza preescolar privada',
      businessLine: 'Enseñanza',
    },
    {
      code: '850022',
      activity:
        'Enseñanza primaria, secundaria científico humanista y técnico profesional privada',
      businessLine: 'Enseñanza',
    },
    {
      code: '853110',
      activity: 'Enseñanza superior en universidades públicas',
      businessLine: 'Enseñanza',
    },
    {
      code: '853120',
      activity: 'Enseñanza superior en universidades privadas',
      businessLine: 'Enseñanza',
    },
    {
      code: '853201',
      activity: 'Enseñanza superior en institutos profesionales',
      businessLine: 'Enseñanza',
    },
    {
      code: '853202',
      activity: 'Enseñanza superior en centros de formación técnica',
      businessLine: 'Enseñanza',
    },
    {
      code: '854100',
      activity: 'Enseñanza deportiva y recreativa',
      businessLine: 'Enseñanza',
    },
    {
      code: '854200',
      activity: 'Enseñanza cultural',
      businessLine: 'Enseñanza',
    },
    {
      code: '854901',
      activity: 'Enseñanza preuniversitaria',
      businessLine: 'Enseñanza',
    },
    {
      code: '854902',
      activity: 'Servicios personales de educación',
      businessLine: 'Enseñanza',
    },
    {
      code: '854909',
      activity: 'Otros tipos de enseñanza n.c.p.',
      businessLine: 'Enseñanza',
    },
    {
      code: '855000',
      activity: 'activityes de apoyo a la enseñanza',
      businessLine: 'Enseñanza',
    },
    {
      code: '861010',
      activity: 'activityes de hospitales y clínicas públicas',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '861020',
      activity: 'activityes de hospitales y clínicas privadas',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '862010',
      activity:
        'activityes de centros de salud municipalizados (servicios de salud pública)',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '862021',
      activity:
        'Centros médicos privados (establecimientos de atención ambulatoria)',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '862022',
      activity:
        'Centros de atención odontológica privados (establecimientos de atención ambulatoria)',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '862031',
      activity: 'Servicios de médicos prestados de forma independiente',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '862032',
      activity: 'Servicios de odontólogos prestados de forma independiente',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '869010',
      activity: 'activityes de laboratorios clínicos y bancos de sangre',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '869091',
      activity:
        'Otros servicios de atención de la salud humana prestados por empresas',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '869092',
      activity:
        'Servicios prestados de forma independiente por otros profesionales de la salud',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '871000',
      activity: 'activityes de atención de enfermería en instituciones',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '872000',
      activity:
        'activityes de atención en instituciones para personas con discapacidad mental y toxicómanos',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '873000',
      activity:
        'activityes de atención en instituciones para personas de edad y personas con discapacidad física',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '879000',
      activity: 'Otras activityes de atención en instituciones',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '881000',
      activity:
        'activityes de asistencia social sin alojamiento para personas de edad y personas con discapacidad',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '889000',
      activity: 'Otras activityes de asistencia social sin alojamiento',
      businessLine:
        'activityes de atención de la salud humana y de asistencia social',
    },
    {
      code: '900001',
      activity:
        'Servicios de producción de obras de teatro, conciertos, espectáculos de danza, otras prod. escénicas',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '900002',
      activity:
        'activityes artísticas realizadas por bandas de música, compañías de teatro, circenses y similares',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '900003',
      activity:
        'activityes de artistas realizadas de forma independiente: actores, músicos, escritores, entre otros',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '900004',
      activity: 'Servicios prestados por periodistas independientes',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '900009',
      activity:
        'Otras activityes creativas, artísticas y de entretenimiento n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '910100',
      activity: 'activityes de bibliotecas y archivos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '910200',
      activity:
        'activityes de museos, gestión de lugares y edificios históricos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '910300',
      activity:
        'activityes de jardines botánicos, zoológicos y reservas naturales',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '920010',
      activity: 'activityes de casinos de juegos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '920090',
      activity: 'Otras activityes de juegos de azar y apuestas n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931101',
      activity: 'Hipódromos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931102',
      activity:
        'Gestión de salas de billar; gestión de salas de bolos (bowling)',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931109',
      activity: 'Gestión de otras instalaciones deportivas n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931201',
      activity: 'activityes de clubes de fútbol amateur y profesional',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931209',
      activity: 'activityes de otros clubes deportivos n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931901',
      activity: 'Promoción y organización de competencias deportivas',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '931909',
      activity: 'Otras activityes deportivas n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '932100',
      activity: 'activityes de parques de atracciones y parques temáticos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '932901',
      activity:
        'Gestión de salas de pool; gestión (explotación) de juegos electrónicos',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '932909',
      activity: 'Otras activityes de esparcimiento y recreativas n.c.p.',
      businessLine: 'activityes artísticas, de entretenimiento y recreativas',
    },
    {
      code: '941100',
      activity: 'activityes de asociaciones empresariales y de empleadores',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '941200',
      activity: 'activityes de asociaciones profesionales',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '942000',
      activity: 'activityes de sindicatos',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949100',
      activity: 'activityes de organizaciones religiosas',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949200',
      activity: 'activityes de organizaciones políticas',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949901',
      activity: 'activityes de centros de madres',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949902',
      activity: 'activityes de clubes sociales',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949903',
      activity:
        'Fundaciones y corporaciones; asociaciones que promueven activityes culturales o recreativas',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949904',
      activity: 'Consejo de administración de edificios y condominios',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '949909',
      activity: 'activityes de otras asociaciones n.c.p.',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '951100',
      activity: 'Reparación de computadores y equipo periférico',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '951200',
      activity:
        'Reparación de equipo de comunicaciones (incluye la reparación teléfonos celulares)',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '952100',
      activity:
        'Reparación de aparatos electrónicos de consumo (incluye aparatos de televisión y radio)',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '952200',
      activity:
        'Reparación de aparatos de uso doméstico, equipo doméstico y de jardinería',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '952300',
      activity: 'Reparación de calzado y de artículos de cuero',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '952400',
      activity: 'Reparación de muebles y accesorios domésticos',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '952900',
      activity: 'Reparación de otros efectos personales y enseres domésticos',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960100',
      activity:
        'Lavado y limpieza, incluida la limpieza en seco, de productos textiles y de piel',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960200',
      activity: 'Peluquería y otros tratamientos de belleza',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960310',
      activity: 'Servicios funerarios',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960320',
      activity: 'Servicios de cementerios',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960901',
      activity:
        'Servicios de adiestramiento, guardería, peluquería, paseo de mascotas (excepto act. veterinarias)',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960902',
      activity:
        'activityes de salones de masajes, baños turcos, saunas, servicio de baños públicos',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '960909',
      activity: 'Otras activityes de servicios personales n.c.p.',
      businessLine: 'Otras activityes de servicios',
    },
    {
      code: '970000',
      activity:
        'activityes de los hogares como empleadores de personal doméstico',
      businessLine:
        'activityes de los hogares como empleadores; activityes no diferenciadas de los hogares ',
    },
    {
      code: '990000',
      activity: 'activityes de organizaciones y órganos extraterritoriales',
      businessLine: 'activityes de organizaciones y órganos extraterritoriales',
    },
    {
      code: '999999',
      activity: 'Otros.',
      businessLine: 'Otros.',
    },
  ];

  return activities;
};

const fetchPrimaryActivitiesWithNames = () => {
  const activitiesWithNames: InterestGroupActivity[] = fetchPrimaryActivities();
  activitiesWithNames.map((activity: InterestGroupActivity) => {
    activity.completeName = `${activity.code} - ${activity.activity}`;
  });

  return activitiesWithNames;
};

export { fetchPrimaryActivities, fetchPrimaryActivitiesWithNames };
