const fetchLocalities = () => {
  const localities = [
    {
      region: 'Tarapacá',
      provincia: 'Iquique',
      comuna: 'Iquique',
      localidad: 'Iquique',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Iquique',
      comuna: 'Alto Hospicio',
      localidad: 'Alto Hospicio',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Tamarugal',
      comuna: 'Pozo Almonte',
      localidad: 'Pozo Almonte',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Tamarugal',
      comuna: 'Camiña',
      localidad: 'Camiña',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Tamarugal',
      comuna: 'Colchane',
      localidad: 'Colchane',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Tamarugal',
      comuna: 'Huara',
      localidad: 'Huara',
      zona: 'Sin Zona',
    },
    {
      region: 'Tarapacá',
      provincia: 'Tamarugal',
      comuna: 'Pica',
      localidad: 'Pica',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Antofagasta',
      comuna: 'Antofagasta',
      localidad: 'Antofagasta',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Antofagasta',
      comuna: 'Mejillones',
      localidad: 'Mejillones',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Antofagasta',
      comuna: 'Sierra Gorda',
      localidad: 'Sierra Gorda',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Antofagasta',
      comuna: 'Taltal',
      localidad: 'Taltal',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'El Loa',
      comuna: 'Calama',
      localidad: 'Calama',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'El Loa',
      comuna: 'Ollagüe',
      localidad: 'Ollagüe',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'El Loa',
      comuna: 'San Pedro de Atacama',
      localidad: 'San Pedro de Atacama',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Tocopilla',
      comuna: 'Tocopilla',
      localidad: 'Tocopilla',
      zona: 'Sin Zona',
    },
    {
      region: 'Antofagasta',
      provincia: 'Tocopilla',
      comuna: 'María Elena',
      localidad: 'María Elena',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Copiapó',
      comuna: 'Copiapó',
      localidad: 'Copiapó',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Copiapó',
      comuna: 'Caldera',
      localidad: 'Caldera',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Copiapó',
      comuna: 'Tierra Amarilla',
      localidad: 'Tierra Amarilla',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Chañaral',
      comuna: 'Chañaral',
      localidad: 'Chañaral',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Chañaral',
      comuna: 'Diego de Almagro',
      localidad: 'Diego de Almagro',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Huasco',
      comuna: 'Vallenar',
      localidad: 'Vallenar',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Huasco',
      comuna: 'Alto del Carmen',
      localidad: 'Alto del Carmen',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Huasco',
      comuna: 'Freirina',
      localidad: 'Freirina',
      zona: 'Sin Zona',
    },
    {
      region: 'Atacama',
      provincia: 'Huasco',
      comuna: 'Huasco',
      localidad: 'Huasco',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Elqui',
      comuna: 'La Serena',
      localidad: 'La Serena',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Elqui',
      comuna: 'Coquimbo',
      localidad: 'Coquimbo',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Elqui',
      comuna: 'Andacollo',
      localidad: 'Andacollo',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Elqui',
      comuna: 'Paiguano',
      localidad: 'Paiguano',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Elqui',
      comuna: 'Vicuña',
      localidad: 'Vicuña',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Choapa',
      comuna: 'Illapel',
      localidad: 'Illapel',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Choapa',
      comuna: 'Canela',
      localidad: 'Canela',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Choapa',
      comuna: 'Los Vilos',
      localidad: 'Los Vilos',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Choapa',
      comuna: 'Salamanca',
      localidad: 'Salamanca',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Limarí',
      comuna: 'Ovalle',
      localidad: 'Ovalle',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Limarí',
      comuna: 'Combarbalá',
      localidad: 'Combarbalá',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Limarí',
      comuna: 'Monte Patria',
      localidad: 'Monte Patria',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Limarí',
      comuna: 'Punitaqui',
      localidad: 'Punitaqui',
      zona: 'Sin Zona',
    },
    {
      region: 'Coquimbo',
      provincia: 'Limarí',
      comuna: 'Río Hurtado',
      localidad: 'Río Hurtado',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Valparaíso',
      localidad: 'Valparaíso',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Concón',
      localidad: 'Concón',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Juan Fernández',
      localidad: 'Juan Fernández',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Puchuncaví',
      localidad: 'Puchuncaví',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Quintero',
      localidad: 'Quintero',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Viña del Mar',
      localidad: 'Viña del Mar',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Isla de Pascua',
      comuna: 'Isla de Pascua',
      localidad: 'Isla de Pascua',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Los Andes',
      comuna: 'Los Andes',
      localidad: 'Los Andes',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Los Andes',
      comuna: 'Calle Larga',
      localidad: 'Calle Larga',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Los Andes',
      comuna: 'Rinconada',
      localidad: 'Rinconada',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Los Andes',
      comuna: 'San Esteban',
      localidad: 'San Esteban',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Casablanca',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'La Viñilla',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Lagunillas',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Las Dichas',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Lo Orozco',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Lo Orrego',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Lo Ovalle',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Pitama',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Quintay',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'San Jerónimo',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Valparaíso',
      comuna: 'Casablanca',
      localidad: 'Tapihue',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Valle Hermoso',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'Cabildo',
      localidad: 'Cabildo',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Longotoma',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Placilla',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'La Chimba',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Quinquimo',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Jaururo',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Quebradilla',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Casas Viejas Huaquén',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Los Hornos',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Trapiche',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Pichicuy',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Los Quinquelles',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Los Molles',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'La Higuera',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'Talanquén',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'La Ligua',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'La Ligua',
      localidad: 'El Rayado',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'Papudo',
      localidad: 'Papudo',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'Petorca',
      localidad: 'Petorca',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'Zapallar',
      localidad: 'Zapallar',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Quillota',
      localidad: 'Quillota',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Petorca',
      comuna: 'Papudo',
      localidad: 'Pullalli',
      zona: 'Norte',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Calera',
      localidad: 'Calera',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Calera',
      localidad: 'Artificio',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Calera',
      localidad: 'Las Cabritas',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Calera',
      localidad: 'Pachacamita',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Calera',
      localidad: 'Pachacama',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'El Olivo',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'La Cruz',
      localidad: 'La Cruz',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Caqui',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Hijuelas',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Cuatro Esquinas',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Barracita',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'La Punta de Torrejon',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Purehue',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Ocoa',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Vista Hermosa',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'La Champa de Ocoa',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Maitenes de Ocoa',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Hualcapo',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Oasis de la Campana',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Las Palmas de Ocoa',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Villa Prat',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Rabuco',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Romeral',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'La Sombra',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Los Pinos',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'El Retiro',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'La Hidráulica',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'La Febre',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'El Toco',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Petorquita',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Conchalí',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Tres Esquinas',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'San Antonio',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Hijuelas',
      localidad: 'Los Tilos',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'Nogales',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Antonio',
      comuna: 'Algarrobo',
      localidad: 'Algarrobo',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Antonio',
      comuna: 'Cartagena',
      localidad: 'Cartagena',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Antonio',
      comuna: 'El Quisco',
      localidad: 'El Quisco',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Antonio',
      comuna: 'El Tabo',
      localidad: 'El Tabo',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Antonio',
      comuna: 'Santo Domingo',
      localidad: 'Santo Domingo',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'San Felipe',
      localidad: 'San Felipe',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'Catemu',
      localidad: 'Catemu',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'Llaillay',
      localidad: 'Llaillay',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'Panquehue',
      localidad: 'Panquehue',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'Putaendo',
      localidad: 'Putaendo',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'San Felipe de Aconcagua',
      comuna: 'Santa María',
      localidad: 'Santa María',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'El Cobre',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'El Melón',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'La Peña',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'Pucalán',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Quillota',
      comuna: 'Nogales',
      localidad: 'Rosario',
      zona: 'Centro',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Avenida Valparaíso',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Boco',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Cancha Santa Ana',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'El Teatro',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'La Capilla',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'La Palma',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'La Tetera',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Lo Varela',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Mauco',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Mayaca',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'OHiggins',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Rautén',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'San Pedro',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Quilpué',
      localidad: 'Quilpué',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Ferrocarril',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Olmué',
      localidad: 'Olmué',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Hipódromo',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'La Trinidad',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Limachito',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Lo Chaparro',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Los Maitenes',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Tabolango',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Urmeneta',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Limache',
      localidad: 'Limache',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Villa Alemana',
      zona: 'Sur',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Rancagua',
      localidad: 'Rancagua',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Codegua',
      localidad: 'Codegua',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Coinco',
      localidad: 'Coinco',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Coltauco',
      localidad: 'Coltauco',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Doñihue',
      localidad: 'Doñihue',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Graneros',
      localidad: 'Graneros',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Las Cabras',
      localidad: 'Las Cabras',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Machalí',
      localidad: 'Machalí',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Malloa',
      localidad: 'Malloa',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Mostazal',
      localidad: 'Mostazal',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Olivar',
      localidad: 'Olivar',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Peumo',
      localidad: 'Peumo',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Pichidegua',
      localidad: 'Pichidegua',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Quinta de Tilcoco',
      localidad: 'Quinta de Tilcoco',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Rengo',
      localidad: 'Rengo',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'Requínoa',
      localidad: 'Requínoa',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cachapoal',
      comuna: 'San Vicente',
      localidad: 'San Vicente',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'Pichilemu',
      localidad: 'Pichilemu',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'La Estrella',
      localidad: 'La Estrella',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'Litueche',
      localidad: 'Litueche',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'Marchihue',
      localidad: 'Marchihue',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'Navidad',
      localidad: 'Navidad',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Cardenal Caro',
      comuna: 'Paredones',
      localidad: 'Paredones',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'San Fernando',
      localidad: 'San Fernando',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Chépica',
      localidad: 'Chépica',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Chimbarongo',
      localidad: 'Chimbarongo',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Lolol',
      localidad: 'Lolol',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Nancagua',
      localidad: 'Nancagua',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Palmilla',
      localidad: 'Palmilla',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Peralillo',
      localidad: 'Peralillo',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Pumanque',
      localidad: 'Pumanque',
      zona: 'Sin Zona',
    },
    {
      region: "Libertador General Bernardo O'Higgins",
      provincia: 'Colchagua',
      comuna: 'Santa Cruz',
      localidad: 'Santa Cruz',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Talca',
      localidad: 'Talca',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Constitución',
      localidad: 'Constitución',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Curepto',
      localidad: 'Curepto',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Empedrado',
      localidad: 'Empedrado',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Maule',
      localidad: 'Maule',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Pelarco',
      localidad: 'Pelarco',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Pencahue',
      localidad: 'Pencahue',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'Río Claro',
      localidad: 'Río Claro',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'San Clemente',
      localidad: 'San Clemente',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Talca',
      comuna: 'San Rafael',
      localidad: 'San Rafael',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Cauquenes',
      comuna: 'Cauquenes',
      localidad: 'Cauquenes',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Cauquenes',
      comuna: 'Chanco',
      localidad: 'Chanco',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Cauquenes',
      comuna: 'Pelluhue',
      localidad: 'Pelluhue',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Curicó',
      localidad: 'Curicó',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Hualañé',
      localidad: 'Hualañé',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Licantén',
      localidad: 'Licantén',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Molina',
      localidad: 'Molina',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Rauco',
      localidad: 'Rauco',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Sagrada Familia',
      localidad: 'Sagrada Familia',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Teno',
      localidad: 'Teno',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Curicó',
      comuna: 'Vichuquén',
      localidad: 'Vichuquén',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Linares',
      localidad: 'Linares',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Colbún',
      localidad: 'Colbún',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Longaví',
      localidad: 'Longaví',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Parral',
      localidad: 'Parral',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Retiro',
      localidad: 'Retiro',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'San Javier',
      localidad: 'San Javier',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Villa Alegre',
      localidad: 'Villa Alegre',
      zona: 'Sin Zona',
    },
    {
      region: 'Maule',
      provincia: 'Linares',
      comuna: 'Yerbas Buenas',
      localidad: 'Yerbas Buenas',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Concepción',
      localidad: 'Concepción',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Coronel',
      localidad: 'Coronel',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Chiguayante',
      localidad: 'Chiguayante',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Florida',
      localidad: 'Florida',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Hualqui',
      localidad: 'Hualqui',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Lota',
      localidad: 'Lota',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Penco',
      localidad: 'Penco',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'San Pedro de la Paz',
      localidad: 'San Pedro de la Paz',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Santa Juana',
      localidad: 'Santa Juana',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Talcahuano',
      localidad: 'Talcahuano',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Tomé',
      localidad: 'Tomé',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Concepción',
      comuna: 'Hualpén',
      localidad: 'Hualpén',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Lebu',
      localidad: 'Lebu',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Arauco',
      localidad: 'Arauco',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Cañete',
      localidad: 'Cañete',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Contulmo',
      localidad: 'Contulmo',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Curanilahue',
      localidad: 'Curanilahue',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Los Alamos',
      localidad: 'Los Alamos',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Arauco',
      comuna: 'Tirúa',
      localidad: 'Tirúa',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Los Angeles',
      localidad: 'Los Angeles',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Antuco',
      localidad: 'Antuco',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Cabrero',
      localidad: 'Cabrero',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Laja',
      localidad: 'Laja',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Mulchén',
      localidad: 'Mulchén',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Nacimiento',
      localidad: 'Nacimiento',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Negrete',
      localidad: 'Negrete',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Quilaco',
      localidad: 'Quilaco',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Quilleco',
      localidad: 'Quilleco',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'San Rosendo',
      localidad: 'San Rosendo',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Santa Bárbara',
      localidad: 'Santa Bárbara',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Tucapel',
      localidad: 'Tucapel',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Yumbel',
      localidad: 'Yumbel',
      zona: 'Sin Zona',
    },
    {
      region: 'Biobío',
      provincia: 'Biobío',
      comuna: 'Alto Biobío',
      localidad: 'Alto Biobío',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Temuco',
      localidad: 'Temuco',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Carahue',
      localidad: 'Carahue',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Cunco',
      localidad: 'Cunco',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Curarrehue',
      localidad: 'Curarrehue',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Freire',
      localidad: 'Freire',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Galvarino',
      localidad: 'Galvarino',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Gorbea',
      localidad: 'Gorbea',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Lautaro',
      localidad: 'Lautaro',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Loncoche',
      localidad: 'Loncoche',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Melipeuco',
      localidad: 'Melipeuco',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Nueva Imperial',
      localidad: 'Nueva Imperial',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Padre Las Casas',
      localidad: 'Padre Las Casas',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Perquenco',
      localidad: 'Perquenco',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Pitrufquén',
      localidad: 'Pitrufquén',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Pucón',
      localidad: 'Pucón',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Saavedra',
      localidad: 'Saavedra',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Teodoro Schmidt',
      localidad: 'Teodoro Schmidt',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Toltén',
      localidad: 'Toltén',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Vilcún',
      localidad: 'Vilcún',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Villarrica',
      localidad: 'Villarrica',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Cautín',
      comuna: 'Cholchol',
      localidad: 'Cholchol',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Angol',
      localidad: 'Angol',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Collipulli',
      localidad: 'Collipulli',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Curacautín',
      localidad: 'Curacautín',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Ercilla',
      localidad: 'Ercilla',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Lonquimay',
      localidad: 'Lonquimay',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Los Sauces',
      localidad: 'Los Sauces',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Lumaco',
      localidad: 'Lumaco',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Purén',
      localidad: 'Purén',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Renaico',
      localidad: 'Renaico',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Traiguén',
      localidad: 'Traiguén',
      zona: 'Sin Zona',
    },
    {
      region: 'La Araucanía',
      provincia: 'Malleco',
      comuna: 'Victoria',
      localidad: 'Victoria',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Puerto Montt',
      localidad: 'Puerto Montt',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Calbuco',
      localidad: 'Calbuco',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Cochamó',
      localidad: 'Cochamó',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Fresia',
      localidad: 'Fresia',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Frutillar',
      localidad: 'Frutillar',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Los Muermos',
      localidad: 'Los Muermos',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Llanquihue',
      localidad: 'Llanquihue',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Maullín',
      localidad: 'Maullín',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Llanquihue',
      comuna: 'Puerto Varas',
      localidad: 'Puerto Varas',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Castro',
      localidad: 'Castro',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Ancud',
      localidad: 'Ancud',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Chonchi',
      localidad: 'Chonchi',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Curaco de Vélez',
      localidad: 'Curaco de Vélez',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Dalcahue',
      localidad: 'Dalcahue',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Puqueldón',
      localidad: 'Puqueldón',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Queilén',
      localidad: 'Queilén',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Quellón',
      localidad: 'Quellón',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Quemchi',
      localidad: 'Quemchi',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Chiloé',
      comuna: 'Quinchao',
      localidad: 'Quinchao',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'Osorno',
      localidad: 'Osorno',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'Puerto Octay',
      localidad: 'Puerto Octay',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'Purranque',
      localidad: 'Purranque',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'Puyehue',
      localidad: 'Puyehue',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'Río Negro',
      localidad: 'Río Negro',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'San Juan de la Costa',
      localidad: 'San Juan de la Costa',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Osorno',
      comuna: 'San Pablo',
      localidad: 'San Pablo',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Palena',
      comuna: 'Chaitén',
      localidad: 'Chaitén',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Palena',
      comuna: 'Futaleufú',
      localidad: 'Futaleufú',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Palena',
      comuna: 'Hualaihué',
      localidad: 'Hualaihué',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Lagos',
      provincia: 'Palena',
      comuna: 'Palena',
      localidad: 'Palena',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Coihaique',
      comuna: 'Coihaique',
      localidad: 'Coihaique',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Coihaique',
      comuna: 'Lago Verde',
      localidad: 'Lago Verde',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Aisén',
      comuna: 'Aisén',
      localidad: 'Aisén',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Aisén',
      comuna: 'Cisnes',
      localidad: 'Cisnes',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Aisén',
      comuna: 'Guaitecas',
      localidad: 'Guaitecas',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Capitán Prat',
      comuna: 'Cochrane',
      localidad: 'Cochrane',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Capitán Prat',
      comuna: "O'Higgins",
      localidad: "O'Higgins",
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'Capitán Prat',
      comuna: 'Tortel',
      localidad: 'Tortel',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'General Carrera',
      comuna: 'Chile Chico',
      localidad: 'Chile Chico',
      zona: 'Sin Zona',
    },
    {
      region: 'Aysén del General Carlos Ibáñez del Campo',
      provincia: 'General Carrera',
      comuna: 'Río Ibáñez',
      localidad: 'Río Ibáñez',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Magallanes',
      comuna: 'Punta Arenas',
      localidad: 'Punta Arenas',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Magallanes',
      comuna: 'Laguna Blanca',
      localidad: 'Laguna Blanca',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Magallanes',
      comuna: 'Río Verde',
      localidad: 'Río Verde',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Magallanes',
      comuna: 'San Gregorio',
      localidad: 'San Gregorio',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Antártica Chilena',
      comuna: 'Cabo de Hornos',
      localidad: 'Cabo de Hornos',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Tierra del Fuego',
      comuna: 'Porvenir',
      localidad: 'Porvenir',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Tierra del Fuego',
      comuna: 'Primavera',
      localidad: 'Primavera',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Tierra del Fuego',
      comuna: 'Timaukel',
      localidad: 'Timaukel',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Última Esperanza',
      comuna: 'Natales',
      localidad: 'Natales',
      zona: 'Sin Zona',
    },
    {
      region: 'Magallanes y de la Antártica Chilena',
      provincia: 'Última Esperanza',
      comuna: 'Torres del Paine',
      localidad: 'Torres del Paine',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Santiago',
      localidad: 'Santiago',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Cerrillos',
      localidad: 'Cerrillos',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Cerro Navia',
      localidad: 'Cerro Navia',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'El Bosque',
      localidad: 'El Bosque',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Estación Central',
      localidad: 'Estación Central',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Huechuraba',
      localidad: 'Huechuraba',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Independencia',
      localidad: 'Independencia',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'La Cisterna',
      localidad: 'La Cisterna',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'La Florida',
      localidad: 'La Florida',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'La Granja',
      localidad: 'La Granja',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'La Pintana',
      localidad: 'La Pintana',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'La Reina',
      localidad: 'La Reina',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Las Condes',
      localidad: 'Las Condes',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Lo Barnechea',
      localidad: 'Lo Barnechea',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Lo Espejo',
      localidad: 'Lo Espejo',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Lo Prado',
      localidad: 'Lo Prado',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Macul',
      localidad: 'Macul',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Maipú',
      localidad: 'Maipú',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Ñuñoa',
      localidad: 'Ñuñoa',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Pedro Aguirre Cerda',
      localidad: 'Pedro Aguirre Cerda',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Peñalolén',
      localidad: 'Peñalolén',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Providencia',
      localidad: 'Providencia',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Pudahuel',
      localidad: 'Pudahuel',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Quilicura',
      localidad: 'Quilicura',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Quinta Normal',
      localidad: 'Quinta Normal',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Recoleta',
      localidad: 'Recoleta',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Renca',
      localidad: 'Renca',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'San Joaquín',
      localidad: 'San Joaquín',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'San Miguel',
      localidad: 'San Miguel',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'San Ramón',
      localidad: 'San Ramón',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Santiago',
      comuna: 'Vitacura',
      localidad: 'Vitacura',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Cordillera',
      comuna: 'Puente Alto',
      localidad: 'Puente Alto',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Cordillera',
      comuna: 'Pirque',
      localidad: 'Pirque',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Cordillera',
      comuna: 'San José de Maipo',
      localidad: 'San José de Maipo',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Chacabuco',
      comuna: 'Colina',
      localidad: 'Colina',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Chacabuco',
      comuna: 'Lampa',
      localidad: 'Lampa',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Chacabuco',
      comuna: 'Tiltil',
      localidad: 'Tiltil',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Maipo',
      comuna: 'San Bernardo',
      localidad: 'San Bernardo',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Maipo',
      comuna: 'Buin',
      localidad: 'Buin',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Maipo',
      comuna: 'Calera de Tango',
      localidad: 'Calera de Tango',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Maipo',
      comuna: 'Paine',
      localidad: 'Paine',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Melipilla',
      comuna: 'Melipilla',
      localidad: 'Melipilla',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Melipilla',
      comuna: 'Alhué',
      localidad: 'Alhué',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Melipilla',
      comuna: 'Curacaví',
      localidad: 'Curacaví',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Melipilla',
      comuna: 'María Pinto',
      localidad: 'María Pinto',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Talagante',
      comuna: 'Talagante',
      localidad: 'Talagante',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Talagante',
      comuna: 'El Monte',
      localidad: 'El Monte',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Talagante',
      comuna: 'Isla de Maipo',
      localidad: 'Isla de Maipo',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Talagante',
      comuna: 'Padre Hurtado',
      localidad: 'Padre Hurtado',
      zona: 'Sin Zona',
    },
    {
      region: 'Metropolitana de Santiago',
      provincia: 'Talagante',
      comuna: 'Peñaflor',
      localidad: 'Peñaflor',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Valdivia',
      localidad: 'Valdivia',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Corral',
      localidad: 'Corral',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Lanco',
      localidad: 'Lanco',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Los Lagos',
      localidad: 'Los Lagos',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Máfil',
      localidad: 'Máfil',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Mariquina',
      localidad: 'Mariquina',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Paillaco',
      localidad: 'Paillaco',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Valdivia',
      comuna: 'Panguipulli',
      localidad: 'Panguipulli',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Ranco',
      comuna: 'La Unión',
      localidad: 'La Unión',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Ranco',
      comuna: 'Futrono',
      localidad: 'Futrono',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Ranco',
      comuna: 'Lago Ranco',
      localidad: 'Lago Ranco',
      zona: 'Sin Zona',
    },
    {
      region: 'Los Ríos',
      provincia: 'Ranco',
      comuna: 'Río Bueno',
      localidad: 'Río Bueno',
      zona: 'Sin Zona',
    },
    {
      region: 'Arica y Parinacota',
      provincia: 'Arica',
      comuna: 'Arica',
      localidad: 'Arica',
      zona: 'Sin Zona',
    },
    {
      region: 'Arica y Parinacota',
      provincia: 'Arica',
      comuna: 'Camarones',
      localidad: 'Camarones',
      zona: 'Sin Zona',
    },
    {
      region: 'Arica y Parinacota',
      provincia: 'Parinacota',
      comuna: 'Putre',
      localidad: 'Putre',
      zona: 'Sin Zona',
    },
    {
      region: 'Arica y Parinacota',
      provincia: 'Parinacota',
      comuna: 'General Lagos',
      localidad: 'General Lagos',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Chillán',
      localidad: 'Chillán',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Bulnes',
      localidad: 'Bulnes',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Chillán Viejo',
      localidad: 'Chillán Viejo',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'El Carmen',
      localidad: 'El Carmen',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Pemuco',
      localidad: 'Pemuco',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Pinto',
      localidad: 'Pinto',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Quillón',
      localidad: 'Quillón',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'San Ignacio',
      localidad: 'San Ignacio',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Diguillín',
      comuna: 'Yungay',
      localidad: 'Yungay',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Quirihue',
      localidad: 'Quirihue',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Cobquecura',
      localidad: 'Cobquecura',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Coelemu',
      localidad: 'Coelemu',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Ninhue',
      localidad: 'Ninhue',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Portezuelo',
      localidad: 'Portezuelo',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Ranquil',
      localidad: 'Ranquil',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Itata',
      comuna: 'Treguaco',
      localidad: 'Treguaco',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Punilla',
      comuna: 'San Carlos',
      localidad: 'San Carlos',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Punilla',
      comuna: 'Coihueco',
      localidad: 'Coihueco',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Punilla',
      comuna: 'Ñiquén',
      localidad: 'Ñiquén',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Punilla',
      comuna: 'San Fabián',
      localidad: 'San Fabián',
      zona: 'Sin Zona',
    },
    {
      region: 'Ñuble',
      provincia: 'Punilla',
      comuna: 'San Nicolás',
      localidad: 'San Nicolás',
      zona: 'Sin Zona',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'José Miguel Carrera',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Peñablanca',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Manuel Montt',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Moscoso',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Municipalidad',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Quebrada Escobares',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'San Enrique',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Sanatorio',
      zona: 'Sur',
    },
    {
      region: 'Valparaíso',
      provincia: 'Marga Marga',
      comuna: 'Villa Alemana',
      localidad: 'Villa Alemana Norte',
      zona: 'Sur',
    },
  ];

  return localities;
};

export { fetchLocalities };
