const fetchBreedingTypes = () => {
  const breedingTypes = [
    'Reproductora',
    'Pre-Engorda',
    'Engorda',
    'Destete Venta',
    'Sitio',
    'Incubadora',
    'Crianza Broiler',
    'Sistema Tratamiento',
    'No Aplica',
  ];

  return breedingTypes;
};

export { fetchBreedingTypes };
