
import Vue from 'vue';
export default Vue.extend({
  name: 'DateRange',
  props: {
    task: {
      type: Object,
      default: () => ({
        code: '0',
        interestGroupId: '',
        taskInformation: {
          area: '',
          submanagement: '',
          management: '',
          taskType: '',
          taskCategory: '',
          task: '',
          activity: '',
        },
        taskStatus: { progress: 0, state: '', observations: '' },
      }),
    },
  },
});
