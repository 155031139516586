import {
  Historial,
  HistoricalEvent,
  InterestGroupBackendData,
  InterestGroupData,
  Organization,
  Orgpert,
} from '../interfaces';

export const transformHistoricalEvents = (historial: Historial[]) => {
  const historicalEvents: HistoricalEvent[] = [];

  if (typeof historial === 'undefined') {
    return [];
  } else {
    historial.forEach(item => {
      const historicalEvent: HistoricalEvent = {
        name: item.hecho,
        year: item.anio,
        description: item.descripcion,
      };

      historicalEvents.push(historicalEvent);
    });

    return historicalEvents;
  }
};

export const transformOrganizations = (orgpert: Orgpert[]) => {
  const organizations: Organization[] = [];

  if (typeof orgpert === 'undefined') {
    return [];
  } else {
    orgpert.forEach(item => {
      const organization: Organization = {
        id: item.id,
        chore: item.faenas,
        businessName: item.razon_social,
        charge: item.cargo,
        year: item.anio,
      };

      organizations.push(organization);
    });

    return organizations;
  }
};

export const formatZone = (zone: string | string[]) => {
  if (Array.isArray(zone)) return zone;

  return zone ? [zone] : [''];
};

export const transformBackendData = (backendData: InterestGroupBackendData) => {
  const data: InterestGroupData = {
    id: backendData._id || '',
    code: backendData.codigo || '',
    generalInformation: {
      rut: backendData.rut || '',
      businessName: backendData.razon_social || '',
      pseudonym: backendData.nombre_fantasia || '',
      birthdate: backendData.fecha_nacimiento || '',
      state: backendData.activeInactive || 'Activo',
      primaryActivity: {
        code: backendData.actividad_principal.codigo || '999999',
        businessLine: backendData.actividad_principal.rubro || '',
        completeName: backendData.actividad_principal.nombreCompleto || '',
        activity: backendData.actividad_principal.actividad || '',
      },
      secondaryActivity: {
        code: backendData.actividad_secundario.codigo || '999999',
        businessLine: backendData.actividad_secundario.rubro || '',
        completeName: backendData.actividad_secundario.nombreCompleto || '',
        activity: backendData.actividad_secundario.actividad || '',
      },
      category: {
        name: backendData.grupointeres || 'Comunidades',
        businessType: backendData.tipo_denunciante || 'Persona Natural',
        companyCategory: backendData.categoria_empresa || 'No Aplica',
        organizationCategory: backendData.categoria_organizacion || 'No Aplica',
        complainantCategory: backendData.categoria_denunciante || 'Ocasional',
      },
    },
    personalInformation: {
      photo: backendData.archivo_adjunto || '',
      primaryContact: backendData.contacto_primario || '',
      secondaryContact: backendData.contacto_secundario || '',
      primaryEmail: backendData.email_primario || '',
      secondaryEmail: backendData.email_secundario || '',
      address: backendData.direccion || '',
      sector: backendData.urbano_rural || 'Urbano',
      nacionality: backendData.nacionalidad || 'Chileno(a)',
      country: backendData.pais_origen || '152',
      locality: backendData.localidad_personal || '',
      commune: backendData.comuna_personal || '',
      province: backendData.provincia_personal || '',
      region: backendData.region_personal || '',
    },
    laborInformation: {
      registeredWorker: {
        registrationNumber: backendData.nro_inscripcion || '',
        lastBoardElectionDate:
          backendData.fecha_ultima_eleccion_directiva || '',
        directiveDuration: backendData.duracion_directiva || '',
        directiveExpirationDate: backendData.fecha_venc_directiva || '',
        directiveValidity: backendData.vigencia_directiva || '',
        pjValidity: backendData.vigencia_pj || '',
        pjType: backendData.tipo_pj || '',
        pjClassification: backendData.clasificacion_pj || '',
        occupation: backendData.profesion_oficio || '',
        charge: backendData.cargo || '',
        gender: backendData.genero || '',
        educationalLevel:
          backendData.nivel_educacional || 'Sin educación formal',
        maritalStatus: backendData.estado_civil || 'Soltero/a',
        area: backendData.area || '',
        submanagement: backendData.subgerencia || '',
        management: backendData.gerencia || '',
        firstImmediateBoss: backendData.superior_1 || '',
        secondImmediateBoss: backendData.superior_2 || '',
        thirdImmediateBoss: backendData.superior_3 || '',
        locality: backendData.localidad || '',
        commune: backendData.comuna || '',
        province: backendData.provincia || '',
        region: backendData.region || '',
        sector: backendData.sector || [],
        zone: formatZone(backendData.zona),
        operationalInfrastructure: backendData.infra_operacional || [],
        operationalUnit: backendData.unidad_operacional || [],
        organizations: transformOrganizations(backendData.orgpert) || [],
        position: {
          ...{
            lat: 0.0,
            lng: 0.0,
            zoom: 17,
          },
          ...backendData.positionGI,
        },
        chore: backendData.faena || [],
      },
    },
    historicalInformation: {
      events: transformHistoricalEvents(backendData.historial),
    },
    influenceMatrix: {
      communityRelations: {
        communication:
          backendData.relaciones_comunitarias.nivel_comunicacion || 'Baja',
        confidence:
          backendData.relaciones_comunitarias.nivel_confianza || 'Baja',
        organizationKnowledge:
          backendData.relaciones_comunitarias.nivel_conocimiento_org || 'Baja',
        complianceWithAgreements:
          backendData.relaciones_comunitarias.nivel_cumplimiento_acuerdos ||
          'Baja',
        organizationSatisfaction:
          backendData.relaciones_comunitarias.nivel_satisfaccion_org || 'Baja',
        average: backendData.relaciones_comunitarias.promedio || 0,
      },
      actorInfluence: {
        territoryOrganizationRelationship:
          backendData.influencia_actor.grado_relacion_org_territorio || 'Baja',
        relationshipWithOtherLocalActors:
          backendData.influencia_actor.grado_relacion_otros_actores_locales ||
          'Baja',
        organizationLeadership:
          backendData.influencia_actor.grado_liderazgo_existente_org || 'Baja',
        mobilization: backendData.influencia_actor.grado_movilizacion || 'Baja',
        average: backendData.influencia_actor.promedio || 0,
      },
      allianceWithActor: {
        strategicCooperationRelationship:
          backendData.alianza_actor.relacion_cooperacion_estrategica || 'Baja',
        adversariesList:
          backendData.alianza_actor.relacion_adversarios || 'Baja',
        temporaryCooperativeRelationship:
          backendData.alianza_actor.relacion_cooperacion_temporal || 'Baja',
        average: backendData.alianza_actor.promedio || 0,
      },
      finalAverage: backendData.promedio_final || 0,
    },
  };

  return data;
};
