const fetchClaimTypes = () => {
  const types = [
    {
      tiporeclamo: 'Olor',
      tipocausante: ['Guano', 'Pavo', 'Humo', 'Planta Subproducto', 'Otro'],
    },
    {
      tiporeclamo: 'Mosca',
      tipocausante: ['Mosca', 'Otro'],
    },
    {
      tiporeclamo: 'Pluma',
      tipocausante: ['Pluma', 'Otro'],
    },
    {
      tiporeclamo: 'Polvo',
      tipocausante: ['Planta Alimentos', 'Otro'],
    },
    {
      tiporeclamo: 'Ruido',
      tipocausante: ['Ruido', 'Otro'],
    },
    {
      tiporeclamo: 'Transporte',
      tipocausante: [
        'Colisión',
        'Exceso de Velocidad',
        'Atropello',
        'Volcamiento',
        'Camión No Cubierto',
        'Daño Caminos',
        'Paso Ruta Prohibida',
        'Corte de Cables de Luz',
        'Daño Matriz Agua Limpia',
        'Daño Matriz Agua Tratada',
        'Ruido',
        'Polvo',
        'Contenido',
        'Otro',
      ],
    },
    {
      tiporeclamo: 'Mantención',
      tipocausante: [
        'Mal estado del camino',
        'Ruptura matriz agua tratada',
        'Ruptura matriz agua limpia',
        'Ruptura matriz purines',
        'Otro',
      ],
    },
    {
      tiporeclamo: 'Administrativo',
      tipocausante: [
        'Trato',
        'Competencia Técnica',
        'Infraestructura',
        'Tiempo de Espera',
        'Información',
        'Procedimientos Administrativos',
        'Probidad Administrativa',
        'Otro',
      ],
    },
    {
      tiporeclamo: 'Otro',
      tipocausante: ['Otro'],
    },
  ];

  return types;
};

export { fetchClaimTypes };
