import { vxm } from '@/app/app-state';
import { fetchLocalities } from '@/app/shared/services';
import { UserAuthenticated } from '@/app/users/shared/models';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { ClaimData } from '../shared/interfaces';
import { Claim } from '../shared/models/claim.model';
import {
  deleteClaim,
  fetchClaimTypes,
  fetchPDFClaimsByFilter,
  fetchSheetClaimsByFilter,
  sendSheetToEmail,
} from '../shared/services';

@Component
export default class ClaimListComponent extends Vue {
  private isClaimsTableLoading = false;
  private isEmailSending = false;
  private claimColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Fecha de reclamo',
      dataIndex: 'registrationData.date',
      scopedSlots: { customRender: 'date' },
      className: 'centered',
    },
    {
      title: 'Hora de reclamo',
      dataIndex: 'registrationData.time',
      className: 'centered',
    },
    {
      title: 'Título',
      dataIndex: 'registrationData.title',
    },
    {
      title: 'Denunciante',
      dataIndex: 'complainantData.complainantPerson.name',
    },
    {
      title: 'Tipo Reclamo',
      dataIndex: 'assignmentData.responsiblePerson.claimType',
      className: 'centered',
    },
    {
      title: 'Tipo Causante',
      dataIndex: 'assignmentData.responsiblePerson.causerType',
      className: 'centered',
    },
    {
      title: 'Clasificación',
      dataIndex: 'registrationData.estimatedResponse.classification',
      className: 'centered',
    },
    {
      title: 'Localidad',
      dataIndex: 'registrationData.locality',
      className: 'centered',
    },
    {
      title: 'Comuna',
      dataIndex: 'registrationData.commune',
      className: 'centered',
    },
    {
      title: 'Etapa',
      dataIndex: 'claimStage',
      className: 'centered',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];
  private showDeleteClaimModal = false;
  private isExportModalOpen = false;
  private emailToSend = '';
  private claimSelected: Claim = new Claim();
  private isDeleteClaimLoading = false;
  private searchOption = '';
  private searchText = '';
  private isFiltersModalOpen = false;
  private searchFilters: Record<any, string | any[]> = {
    date: [] as any[],
    claimType: '',
    causerType: '',
    classification: '',
    locality: '',
    claimStage: '',
  };

  private filterFields: Record<any, string | any[]> = {
    date: [] as any[],
    claimType: '',
    causerType: '',
    classification: '',
    locality: '',
    claimStage: '',
  };

  private actionsMenuItems = [
    ...(this.user.can('edit', 'claims')
      ? [
          {
            action: this.editClaim,
            text: 'Editar',
            icon: 'edit',
          },
        ]
      : []),
    ...(this.user.can('delete', 'claims')
      ? [
          {
            action: this.openDeleteClaimModal,
            text: 'Eliminar',
            icon: 'delete',
          },
        ]
      : []),
    {
      divider: true,
    },
    ...(this.user.can('edit', 'claims')
      ? [
          {
            action: this.goToClaimAnswers,
            text: 'Respuestas',
            icon: 'unordered-list',
          },
        ]
      : []),
    ...(this.user.can('edit', 'claims')
      ? [
          {
            action: this.goToClaimClosure,
            text: 'Comunicación y Cierre',
            icon: 'solution',
          },
        ]
      : []),
    ...(this.user.can('edit', 'claims')
      ? [
          {
            action: this.goToClaimLessons,
            text: 'Lecciones Aprendidas',
            icon: 'profile',
          },
        ]
      : []),
  ];

  get user() {
    const authenticatedUser = vxm.authentication
      .authenticatedUser as UserAuthenticated;
    return authenticatedUser;
  }

  get userCanCreate() {
    return this.user.can('create', 'claims');
  }

  get claims() {
    return vxm.claims.flattenClaimList;
  }

  get claimsTypes() {
    return fetchClaimTypes().map(type => type.tiporeclamo);
  }

  get causerTypes() {
    const claimTypeSelected = this.filterFields.claimType;
    if (claimTypeSelected == '') return [];
    const claimTypeFound = fetchClaimTypes().find(
      type => type.tiporeclamo == claimTypeSelected
    );
    return claimTypeFound?.tipocausante;
  }

  get classifications() {
    return ['Alta', 'Media', 'Baja'];
  }

  get localities() {
    return fetchLocalities();
  }

  get stages() {
    return [
      'Registro',
      'Respuesta',
      'Comunicación y cierre',
      'Lecciones Aprendidas',
      'Finalizado',
    ];
  }

  get activeFilters() {
    const keys = Object.keys(this.searchFilters);

    const arrayFilters = keys.map((key, idx) => {
      return {
        [key]: Object.values(this.searchFilters)[idx],
        key: key,
        value:
          key == 'date'
            ? this.formatDate(
                Object.values(this.searchFilters)[idx][0] as string
              ) +
              ' - ' +
              this.formatDate(
                Object.values(this.searchFilters)[idx][1] as string
              )
            : Object.values(this.searchFilters)[idx],
        name: this.filterOptions.find(
          opt => opt.code.toLowerCase() == key.toLowerCase()
        )?.name,
        hasValue: () => {
          if (Array.isArray(Object.values(this.searchFilters)[idx])) {
            return Object.values(this.searchFilters)[idx].length != 0;
          } else {
            return Object.values(this.searchFilters)[idx] != '';
          }
        },
      };
    });

    return arrayFilters.filter(filter => filter.hasValue());
  }

  get filterOptions() {
    return [
      {
        name: 'Fecha',
        code: 'date',
      },
      {
        name: 'Tipo reclamo',
        code: 'claimType',
      },
      {
        name: 'Tipo causante',
        code: 'causerType',
      },
      {
        name: 'Clasificación',
        code: 'classification',
      },
      {
        name: 'Localidad',
        code: 'locality',
      },
      {
        name: 'Etapa',
        code: 'claimStage',
      },
    ];
  }

  formatDate(date: string) {
    if (!date) return '';
    if (typeof date == 'string')
      return moment(new Date(date.replace(/-/g, '/'))).format('DD/MM/yyyy');
    return moment(date).format('DD/MM/yyyy');
  }

  async setSearchFilterValues(query: Record<string, string>) {
    this.isClaimsTableLoading = true;
    await vxm.claims.getClaimListByFilter(query);
    this.isClaimsTableLoading = false;
  }

  async downloadPDFByFilters() {
    this.searchFilters = this.filterFields;

    const { date, ...filters } = this.searchFilters;

    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != '')
    );

    const dateFilter = {
      startDate: moment(date[0]).format('yyyy-MM-DD'),
      endDate: moment(date[1]).format('yyyy-MM-DD'),
    };

    this.isClaimsTableLoading = true;
    const res = await fetchPDFClaimsByFilter({
      ...(validFilters as Record<string, string>),
      ...(date.length > 0 && dateFilter),
    });
    window.open(res.uri, '_blank');
    this.isClaimsTableLoading = false;
  }

  async downloadSheetByFilters() {
    this.searchFilters = this.filterFields;

    const { date, ...filters } = this.searchFilters;

    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != '')
    );

    const dateFilter = {
      startDate: moment(date[0]).format('yyyy-MM-DD'),
      endDate: moment(date[1]).format('yyyy-MM-DD'),
    };

    this.isClaimsTableLoading = true;
    const res = await fetchSheetClaimsByFilter({
      ...(validFilters as Record<string, string>),
      ...(date.length > 0 && dateFilter),
    });
    window.open(res.uri, '_blank');
    this.isClaimsTableLoading = false;
  }

  async sendSheetToEmail(email: string) {
    this.searchFilters = this.filterFields;

    const { date, ...filters } = this.searchFilters;

    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != '')
    );

    const dateFilter = {
      startDate: moment(date[0]).format('yyyy-MM-DD'),
      endDate: moment(date[1]).format('yyyy-MM-DD'),
    };

    this.isEmailSending = true;
    await sendSheetToEmail(
      {
        ...(validFilters as Record<string, string>),
        ...(date.length > 0 && dateFilter),
      },
      email
    );
    this.isEmailSending = false;
  }

  filterList(list: object[], option: string, value: string) {
    let filteredList = list;

    if (option == '') {
      return filteredList;
    } else {
      filteredList = list.filter((item: any) => {
        if (item[option] || item[option] == 0) {
          return String(item[option])
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });

      return filteredList;
    }
  }

  async handleFilterTagClose(filter: any) {
    this.searchFilters[filter.key] = filter.key == 'date' ? [] : '';

    this.searchFilters = this.filterFields;

    const { date, ...filters } = this.searchFilters;

    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != '')
    );

    const dateFilter = {
      startDate: moment(date[0]).format('yyyy-MM-DD'),
      endDate: moment(date[1]).format('yyyy-MM-DD'),
    };

    this.setSearchFilterValues({
      ...(validFilters as Record<string, string>),
      ...(date.length > 0 && dateFilter),
    });
  }

  handleOk() {
    this.searchFilters = this.filterFields;

    const { date, ...filters } = this.searchFilters;

    const validFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v != '')
    );

    const dateFilter = {
      startDate: moment(date[0]).format('yyyy-MM-DD'),
      endDate: moment(date[1]).format('yyyy-MM-DD'),
    };

    this.isFiltersModalOpen = false;

    this.setSearchFilterValues({
      ...(validFilters as Record<string, string>),
      ...(date.length > 0 && dateFilter),
    });
  }

  openFilterModal() {
    this.isFiltersModalOpen = true;
    this.filterFields = this.searchFilters;
  }

  async mounted() {
    await this.getClaimList();
  }

  editClaim(claim: ClaimData) {
    const claimSelected = new Claim(claim);

    this.$router.push({
      name: 'editar-reclamo',
      params: { id: claimSelected.getId() },
    });
  }

  async getClaimList() {
    this.isClaimsTableLoading = true;
    await vxm.claims.getClaimList();
    this.isClaimsTableLoading = false;
  }

  async deleteClaim() {
    this.isDeleteClaimLoading = true;
    const response = await deleteClaim(this.claimSelected.getId());
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
    }
    this.isDeleteClaimLoading = false;
    this.closeDeleteClaimModal();
    await this.getClaimList();
  }

  openDeleteClaimModal(claim: ClaimData) {
    this.claimSelected = new Claim(claim);
    this.showDeleteClaimModal = true;
  }

  closeDeleteClaimModal() {
    this.claimSelected = new Claim();
    this.showDeleteClaimModal = false;
  }

  goToClaimAnswers(claim: ClaimData) {
    const claimSelected = new Claim(claim);

    this.$router.push({
      name: 'reclamo-respuestas',
      params: { id: claimSelected.getId() },
    });
  }

  goToClaimClosure(claim: ClaimData) {
    const claimSelected = new Claim(claim);

    this.$router.push({
      name: 'reclamo-comunicacion-cierre',
      params: { id: claimSelected.getId() },
    });
  }

  goToClaimLessons(claim: ClaimData) {
    const claimSelected = new Claim(claim);

    this.$router.push({
      name: 'reclamo-lecciones-aprendidas',
      params: { id: claimSelected.getId() },
    });
  }
}
