const fetchSmellLevels = () => {
  const levels = [
    {
      level: 0,
      text: 'Sin Olor',
    },
    {
      level: 1,
      text: 'Muy Suave',
    },
    {
      level: 2,
      text: 'Suave',
    },
    {
      level: 3,
      text: 'Media',
    },
    {
      level: 4,
      text: 'Fuerte',
    },
    {
      level: 5,
      text: 'Muy Fuerte',
    },
  ];

  return levels;
};

export { fetchSmellLevels };
