import { fetchPrimaryActivities } from '../services';

const activityByCode = (code: string | number) => {
  const activities = fetchPrimaryActivities();
  const activityFound = activities.find(activity => activity.code == code);

  return activityFound;
};

export { activityByCode };
