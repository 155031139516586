import { vxm } from '@/app/app-state';
import { Component, Vue } from 'vue-property-decorator';
import { fetchRoles, newUser } from '../shared/services';

@Component
export default class UserItemComponent extends Vue {
  private userForm: {
    interestGroupId: string;
    role: string;
    password: string;
    checkPassword?: string;
    interestGroupInfo: {
      businessName: string;
      charge: string;
      primaryContact: string;
      primaryEmail: string;
    };
  };
  private passwordConfirmation = '';
  private roles: object[];
  private userFormRules: object;
  $refs!: {
    userForm: any;
  };
  private isLoadingUserItem = false;

  get interestGroupList() {
    return vxm.interestGroup.interestGroupList;
  }

  constructor() {
    super();
    this.userForm = {
      interestGroupId: '',
      role: '',
      password: '',
      checkPassword: '',
      interestGroupInfo: {
        businessName: '',
        charge: '',
        primaryContact: '',
        primaryEmail: '',
      },
    };
    this.userFormRules = {
      password: [{ validator: this.validatePass, trigger: 'change' }],
      checkPassword: [{ validator: this.validatePass2, trigger: 'change' }],
    };
    this.roles = fetchRoles();
  }

  async mounted() {
    vxm.interestGroup.fetchAll();
  }

  filterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  validatePass = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('Por favor ingrese la contraseña'));
    } else {
      if (this.userForm.checkPassword !== '') {
        this.$refs.userForm.validateField('checkPassword');
      }
      callback();
    }
  };

  validatePass2 = (rule: any, value: any, callback: any) => {
    if (value === '') {
      callback(new Error('Ingrese la contraseña otra vez'));
    } else if (value !== this.userForm.password) {
      callback(new Error('Las contraseñas ingresadas no coinciden'));
    } else {
      callback();
    }
  };

  onInterestGroupChange(value: string) {
    const interestGroupSelected = this.interestGroupById(value);
    if (interestGroupSelected) {
      this.userForm.interestGroupInfo = {
        businessName: interestGroupSelected.razon_social || '',
        charge: interestGroupSelected.cargo || '',
        primaryContact: interestGroupSelected.contacto_primario || '',
        primaryEmail: interestGroupSelected.email_primario || '',
      };
    }
  }

  interestGroupById = (id: string) => {
    const interestGroupFound = vxm.interestGroup.interestGroupList.find(
      gi => gi._id == id
    );

    return interestGroupFound;
  };

  async submitUser() {
    this.isLoadingUserItem = true;
    const userData = { ...this.userForm };
    delete userData.checkPassword;

    const response = await newUser(userData);
    this.isLoadingUserItem = false;

    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'usuarios',
      });
    }
  }
}
