import { render, staticRenderFns } from "./interest-group-form-fourth-step-component.html?vue&type=template&id=4ff2706f&"
import script from "./interest-group-form-fourth-step-component.ts?vue&type=script&lang=ts&"
export * from "./interest-group-form-fourth-step-component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports