import ClaimsComponent from './claims.component.vue';
import ClaimItemComponent from './claim-item/claim-item.component.vue';
import ClaimListComponent from './claim-list/claim-list.component.vue';
import AnswersComponent from './answers/answers.component.vue';
import AnswersItemComponent from './answer-item/answer-item.component.vue';
import ClaimClosureComponent from './claim-closure/claim-closure.component.vue';
import ClaimLessonsComponent from './claim-lessons/claim-lessons.component.vue';

export const claimRoutes = [
  {
    path: '/reclamos',
    component: ClaimsComponent,
    children: [
      {
        path: 'crear',
        name: 'crear-reclamo',
        component: ClaimItemComponent,
      },
      {
        path: 'editar/:id',
        name: 'editar-reclamo',
        component: ClaimItemComponent,
      },
      {
        path: '',
        name: 'reclamos',
        component: ClaimListComponent,
      },
      {
        path: ':id/respuestas',
        name: 'reclamo-respuestas',
        component: AnswersComponent,
      },
      {
        path: ':id/respuestas/crear',
        name: 'reclamo-crear-respuesta',
        component: AnswersItemComponent,
      },
      {
        path: ':id/respuestas/:answerId',
        name: 'reclamo-respuesta',
        component: AnswersItemComponent,
      },
      {
        path: ':id/comunicacion-cierre',
        name: 'reclamo-comunicacion-cierre',
        component: ClaimClosureComponent,
      },
      {
        path: ':id/lecciones-aprendidas',
        name: 'reclamo-lecciones-aprendidas',
        component: ClaimLessonsComponent,
      },
    ],
  },
];
