const fetchOffenseLevel = () => {
  const levels = [
    {
      level: 1,
      text: 'No Ofensivo',
    },
    {
      level: 2,
      text: 'Levemente Ofensivo',
    },
    {
      level: 3,
      text: 'Ofensivo',
    },
    {
      level: 4,
      text: 'Muy Ofensivo',
    },
    {
      level: 5,
      text: 'Extremadamente Ofensivo',
    },
  ];

  return levels;
};

export { fetchOffenseLevel };
