const fetchStrategicAspectCategories = () => {
  const categories = [
    'Seguridad y Salud Ocupacional',
    'Medio Ambiente',
    'Comunidades',
    'Legal',
    'Reputación',
  ];

  return categories;
};

export { fetchStrategicAspectCategories };
