import { vxm } from '@/app/app-state';
import { InterestGroupBackendData } from '@/app/interest-groups/shared/interfaces';
import { getInterestGroup } from '@/app/interest-groups/shared/services';
import { Component, Vue } from 'vue-property-decorator';
import {
  DateData,
  DescriptiveInformation,
  OperationalTaskActivity,
  TaskInformation,
  TaskStatusInformation,
} from '../shared/interfaces';
import { OperationalTask } from '../shared/models';
import {
  editOperationalTask,
  fetchOperationalTask,
  fetchOperationalTaskActivities,
  fetchUnits,
  newOperationalTask,
} from '../shared/services';

import DateRange from '@/app/shared/components/date-range/date-range.component.vue';
import MeasurementUnits from '@/app/shared/components/measurement-units/measurement-units.component.vue';
import { UserAuthenticated } from '@/app/users/shared/models';

@Component({
  components: {
    DateRange,
    MeasurementUnits,
  },
})
export default class OperationalTaskItemComponent extends Vue {
  private operationalTaskData: OperationalTask;
  private taskInformationForm: TaskInformation;
  private descriptiveInformationForm: DescriptiveInformation;
  private taskStatusForm: TaskStatusInformation;
  private taskActivities: OperationalTaskActivity[];
  private units: string[];
  private percentageMarks = {
    0: '0%',
    10: '10%',
    20: '20%',
    30: '30%',
    40: '40%',
    50: '50%',
    60: '60%',
    70: '70%',
    80: '80%',
    90: '90%',
    100: '100%',
  };
  private currentStep = 0;
  private operationalTaskSteps = [
    {
      title: 'Tareas',
      content: 'First-content',
    },
    {
      title: 'Descripciones',
      content: 'Second-content',
    },
    {
      title: 'Planificación',
      content: 'Last-content',
    },
  ];
  private isLoadingOperationalTaslItem = false;
  private taskInformationRules = {
    taskType: [
      {
        required: true,
        message: 'Seleccione un tipo de tarea',
        trigger: 'change',
      },
    ],
    taskCategory: [
      {
        required: true,
        message: 'Seleccione una categoria de tarea',
        trigger: 'change',
      },
    ],
    task: [
      {
        required: true,
        message: 'Seleccione una tarea',
        trigger: 'change',
      },
    ],
    activity: [
      {
        required: true,
        message: 'Seleccione una actividad',
        trigger: 'change',
      },
    ],
  };
  private headerTitleText = 'Nueva Tarea Operacional';
  private submitButtonText = 'Crear Tarea Operacional';
  $refs!: {
    taskInformationForm: any;
    taskStatusForm: any;
  };

  constructor() {
    super();
    this.operationalTaskData = new OperationalTask();
    this.taskInformationForm = this.operationalTaskData.taskInformation;
    this.descriptiveInformationForm = this.operationalTaskData.descriptiveInformation;
    this.taskStatusForm = this.operationalTaskData.taskStatus;
    this.taskActivities = fetchOperationalTaskActivities();
    this.units = fetchUnits();
    if (this.$route.name == 'editar-tarea-operacional') {
      this.headerTitleText = 'Editar Tarea Operacional';
      this.submitButtonText = 'Guardar Tarea Operacional';
    }
  }

  get taskTypes() {
    const activities = this.taskActivities.filter(
      activity =>
        activity.area.toLowerCase() ==
        this.taskInformationForm.area.toLowerCase()
    );

    return this.getStringListByProperty(activities, 'tipoTarea');
  }

  get taskCategories() {
    const activities = this.taskActivities.filter(
      activity =>
        activity.area.toLowerCase() ==
        this.taskInformationForm.area.toLowerCase()
    );

    const types = activities.filter(
      activity =>
        activity.tipoTarea.toLowerCase() ==
        this.taskInformationForm.taskType.toLowerCase()
    );

    return this.getStringListByProperty(types, 'categoriaTarea');
  }

  get tasks() {
    const activities = this.taskActivities.filter(
      activity =>
        activity.area.toLowerCase() ==
        this.taskInformationForm.area.toLowerCase()
    );

    const types = activities.filter(
      activity =>
        activity.tipoTarea.toLowerCase() ==
        this.taskInformationForm.taskType.toLowerCase()
    );

    const categories = types.filter(
      activity =>
        activity.categoriaTarea.toLowerCase() ==
        this.taskInformationForm.taskCategory.toLowerCase()
    );

    return this.getStringListByProperty(categories, 'tarea');
  }

  get list() {
    const activities = this.taskActivities.filter(
      activity =>
        activity.area.toLowerCase() ==
        this.taskInformationForm.area.toLowerCase()
    );

    const types = activities.filter(
      activity =>
        activity.tipoTarea.toLowerCase() ==
        this.taskInformationForm.taskType.toLowerCase()
    );

    const categories = types.filter(
      activity =>
        activity.categoriaTarea.toLowerCase() ==
        this.taskInformationForm.taskCategory.toLowerCase()
    );

    const list = categories.filter(
      activity =>
        activity.tarea.toLowerCase() ==
        this.taskInformationForm.task.toLowerCase()
    );

    return this.getStringListByProperty(list, 'actividad');
  }

  get taskStatus() {
    if (this.taskStatusForm.progress == 0)
      return (this.taskStatusForm.state = 'No iniciada');
    if (this.taskStatusForm.progress == 100)
      return (this.taskStatusForm.state = 'Completada');
    return (this.taskStatusForm.state = 'En Ejecución');
  }

  async mounted() {
    this.isLoadingOperationalTaslItem = true;
    const interestGroup = await getInterestGroup(
      (vxm.authentication.authenticatedUser as UserAuthenticated)
        .interestGroupId
    );
    const interestGroupData: InterestGroupBackendData = interestGroup.res;

    if (this.$route.name == 'editar-tarea-operacional') {
      const operationalTaskId = this.$route.params.id;
      const operationalTaskRes = await fetchOperationalTask(operationalTaskId);
      this.operationalTaskData = new OperationalTask(operationalTaskRes.res);
      this.taskInformationForm = this.operationalTaskData.taskInformation;
      this.descriptiveInformationForm = this.operationalTaskData.descriptiveInformation;
      this.taskStatusForm = this.operationalTaskData.taskStatus;
    }

    this.operationalTaskData.getManagementInformatiom(interestGroupData);

    this.isLoadingOperationalTaslItem = false;
  }

  getStringListByProperty(list: any[], property: string) {
    let temp = '';
    const stringList: string[] = [];
    list.map(item => {
      if (item[property] != temp) {
        stringList.push(item[property]);
      }
      temp = item[property];
    });

    return stringList;
  }

  increase() {
    let percent = this.taskStatusForm.progress + 10;
    if (percent > 100) {
      percent = 100;
    }
    this.taskStatusForm.progress = percent;
  }
  decline() {
    let percent = this.taskStatusForm.progress - 10;
    if (percent < 0) {
      percent = 0;
    }
    this.taskStatusForm.progress = percent;
  }

  nextStep() {
    if (this.currentStep == 0) {
      this.$refs.taskInformationForm.validate((valid: boolean) => {
        if (valid) {
          this.currentStep++;
        }
      });
    } else {
      this.currentStep++;
    }
  }

  prevStep() {
    this.currentStep--;
  }

  onEstimatedDateDataChange(dateDate: DateData) {
    this.taskStatusForm.estimatedData = dateDate;
  }

  onRealDateDataChange(dateDate: DateData) {
    this.taskStatusForm.realData = dateDate;
  }

  async submitOperationalTaskItem() {
    this.isLoadingOperationalTaslItem = true;
    let response;

    if (this.$route.name == 'editar-tarea-operacional') {
      response = await editOperationalTask(
        this.operationalTaskData.getId(),
        this.operationalTaskData.getWithOutId()
      );
    } else {
      response = await newOperationalTask(this.operationalTaskData);
    }

    this.isLoadingOperationalTaslItem = false;

    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'tareas-operacionales',
      });
    }
  }
}
