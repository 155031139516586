import { httpClient } from '@/app/shared/services';

const fetchUsers = () => {
  const claimsApi = '/users';

  return httpClient
    .get(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const fetchUser = (id: string) => {
  const usersApi = `/users/${id}`;

  return httpClient
    .get(usersApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const newUser = (user: any) => {
  const usersApi = '/users/insert';

  return httpClient
    .post(usersApi, user)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const deleteUser = (id: string) => {
  const usersApi = `/users/${id}`;

  return httpClient
    .delete(usersApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

export { fetchUsers, fetchUser, newUser, deleteUser };
