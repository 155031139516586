import Vue from 'vue';
import { Bubble, mixins as chartMixins } from 'vue-chartjs';
import Component, { mixins } from 'vue-class-component';
import { ChartData, ChartOptions } from 'chart.js';

const Props = Vue.extend({
  props: {
    chartData: {
      type: Object as () => ChartData,
      default: undefined,
    },
    options: {
      type: Object as () => ChartOptions,
      default: undefined,
    },
  },
});

@Component({
  extends: Bubble,
  mixins: [chartMixins.reactiveProp],
})
export class BubbleChart extends mixins(Props, Bubble) {
  mounted(): void {
    this.renderChart(
      {
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [
              {
                x: 20,
                y: 25,
                r: 5,
              },
              {
                x: 40,
                y: 10,
                r: 10,
              },
              {
                x: 30,
                y: 22,
                r: 30,
              },
            ],
          },
          {
            label: 'Data Two',
            backgroundColor: '#7C8CF8',
            data: [
              {
                x: 10,
                y: 30,
                r: 15,
              },
              {
                x: 20,
                y: 20,
                r: 10,
              },
              {
                x: 15,
                y: 8,
                r: 30,
              },
            ],
          },
        ],
      },
      this.options
    );
  }
}
