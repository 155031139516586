import Vue from 'vue';
import Vuex from 'vuex';
import { extractVuexModule, createProxy } from 'vuex-class-component';

import { InterestGroupStore } from './interest-groups';
import { ClaimsStore } from './claims';
import { UsersStore } from './users';
import { AuthenticationStore } from './authentication';
import { OperationalTasksStore } from './operational-tasks';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    ...extractVuexModule(InterestGroupStore),
    ...extractVuexModule(ClaimsStore),
    ...extractVuexModule(UsersStore),
    ...extractVuexModule(AuthenticationStore),
    ...extractVuexModule(OperationalTasksStore),
  },
});

// Creating proxies.
export const vxm = {
  interestGroup: createProxy(store, InterestGroupStore),
  claims: createProxy(store, ClaimsStore),
  users: createProxy(store, UsersStore),
  authentication: createProxy(store, AuthenticationStore),
  operationalTasks: createProxy(store, OperationalTasksStore),
};
