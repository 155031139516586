/* eslint-disable @typescript-eslint/camelcase */
import {
  Faena,
  Historial,
  HistoricalEvent,
  InterestGroupBackendData,
  InterestGroupData,
  Organization,
  Orgpert,
  PositionGI,
  Sector,
} from '../interfaces';

export const restoreBackendHistorial = (events: HistoricalEvent[]) => {
  const historial: Historial[] = [];

  if (typeof events === 'undefined') {
    return [];
  } else {
    events.forEach(item => {
      const historicalEvent: Historial = {
        hecho: item.name || '',
        anio: item.year || '',
        descripcion: item.description || '',
      };

      historial.push(historicalEvent);
    });

    return historial;
  }
};

export const restoreBackendOrgpert = (organizations: Organization[]) => {
  const orgpert: Orgpert[] = [];

  if (typeof organizations === 'undefined') {
    return [];
  } else {
    organizations.forEach(item => {
      const organization: Orgpert = {
        id: item.id,
        faenas: item.chore,
        razon_social: item.businessName,
        cargo: item.charge,
        anio: item.year,
      };

      orgpert.push(organization);
    });

    return orgpert;
  }
};

export const restoreBackendData = (interestGroup: InterestGroupData) => {
  const backendData: InterestGroupBackendData = {
    subarea: '',
    codigo: interestGroup.code,
    parametro_muestra: '',
    rubro_principal:
      interestGroup.generalInformation.primaryActivity?.businessLine || '',
    rubro_secundario:
      interestGroup.generalInformation.secondaryActivity?.businessLine || '',
    activeInactive: interestGroup.generalInformation.state || '',
    razon_social: interestGroup.generalInformation.businessName || '',
    nombre_fantasia: interestGroup.generalInformation.pseudonym || '',
    fecha_nacimiento: interestGroup.generalInformation.birthdate || '',
    rut: interestGroup.generalInformation.rut || '',
    actividad_principal: {
      actividad:
        interestGroup.generalInformation.primaryActivity?.activity || '',
      codigo: interestGroup.generalInformation.primaryActivity?.code || '',
      nombreCompleto:
        interestGroup.generalInformation.primaryActivity?.completeName || '',
      rubro:
        interestGroup.generalInformation.primaryActivity?.businessLine || '',
    },
    actividad_secundario: {
      actividad:
        interestGroup.generalInformation.secondaryActivity?.activity || '',
      codigo: interestGroup.generalInformation.secondaryActivity?.code || '',
      nombreCompleto:
        interestGroup.generalInformation.secondaryActivity?.completeName || '',
      rubro:
        interestGroup.generalInformation.secondaryActivity?.businessLine || '',
    },
    grupointeres: interestGroup.generalInformation.category.name,
    tipo_denunciante: interestGroup.generalInformation.category.businessType,
    categoria_empresa:
      interestGroup.generalInformation.category.companyCategory,
    categoria_organizacion:
      interestGroup.generalInformation.category.organizationCategory,
    categoria_denunciante:
      interestGroup.generalInformation.category.complainantCategory,
    archivo_adjunto: interestGroup.personalInformation.photo,
    contacto_primario: interestGroup.personalInformation.primaryContact || '',
    contacto_secundario:
      interestGroup.personalInformation.secondaryContact || '',
    email_primario: interestGroup.personalInformation.primaryEmail || '',
    email_secundario: interestGroup.personalInformation.secondaryEmail || '',
    direccion: interestGroup.personalInformation.address || '',
    localidad_personal: interestGroup.personalInformation.locality || '',
    comuna_personal: interestGroup.personalInformation.commune || '',
    provincia_personal: interestGroup.personalInformation.province || '',
    region_personal: interestGroup.personalInformation.region || '',
    urbano_rural: interestGroup.personalInformation.sector || '',
    nacionalidad: interestGroup.personalInformation.nacionality || '',
    pais_origen: interestGroup.personalInformation.country || '',
    nro_inscripcion:
      interestGroup.laborInformation.registeredWorker.registrationNumber || '',
    fecha_ultima_eleccion_directiva:
      interestGroup.laborInformation.registeredWorker.lastBoardElectionDate ||
      '',
    duracion_directiva:
      interestGroup.laborInformation.registeredWorker.directiveDuration || '',
    fecha_venc_directiva:
      interestGroup.laborInformation.registeredWorker.directiveExpirationDate ||
      '',
    vigencia_directiva:
      interestGroup.laborInformation.registeredWorker.directiveValidity || '',
    vigencia_pj:
      interestGroup.laborInformation.registeredWorker.pjValidity || '',
    clasificacion_pj:
      interestGroup.laborInformation.registeredWorker.pjClassification || '',
    tipo_pj: interestGroup.laborInformation.registeredWorker.pjType || '',
    profesion_oficio:
      interestGroup.laborInformation.registeredWorker.occupation || '',
    cargo: interestGroup.laborInformation.registeredWorker.charge || '',
    genero: interestGroup.laborInformation.registeredWorker.gender || '',
    nivel_educacional:
      interestGroup.laborInformation.registeredWorker.educationalLevel || '',
    estado_civil:
      interestGroup.laborInformation.registeredWorker.maritalStatus || '',
    area: interestGroup.laborInformation.registeredWorker.area || '',
    subgerencia:
      interestGroup.laborInformation.registeredWorker.submanagement || '',
    superior_1:
      interestGroup.laborInformation.registeredWorker.firstImmediateBoss || '',
    superior_2:
      interestGroup.laborInformation.registeredWorker.secondImmediateBoss || '',
    superior_3:
      interestGroup.laborInformation.registeredWorker.thirdImmediateBoss || '',
    gerencia: interestGroup.laborInformation.registeredWorker.management || '',
    localidad: interestGroup.laborInformation.registeredWorker.locality || '',
    comuna: interestGroup.laborInformation.registeredWorker.commune || '',
    provincia: interestGroup.laborInformation.registeredWorker.province || '',
    region: interestGroup.laborInformation.registeredWorker.region || '',
    sector: interestGroup.laborInformation.registeredWorker.sector as Sector[],
    zona: interestGroup.laborInformation.registeredWorker.zone || [],
    infra_operacional:
      interestGroup.laborInformation.registeredWorker
        .operationalInfrastructure || [],
    unidad_operacional:
      interestGroup.laborInformation.registeredWorker.operationalUnit || [],
    orgpert: restoreBackendOrgpert(
      interestGroup.laborInformation.registeredWorker
        .organizations as Organization[]
    ),
    positionGI: interestGroup.laborInformation.registeredWorker
      .position as PositionGI,
    faena: interestGroup.laborInformation.registeredWorker.chore as Faena[],
    historial: restoreBackendHistorial(
      interestGroup.historicalInformation.events as HistoricalEvent[]
    ),
    relaciones_comunitarias: {
      nivel_comunicacion:
        interestGroup.influenceMatrix.communityRelations.communication,
      nivel_confianza:
        interestGroup.influenceMatrix.communityRelations.confidence,
      nivel_conocimiento_org:
        interestGroup.influenceMatrix.communityRelations.organizationKnowledge,
      nivel_cumplimiento_acuerdos:
        interestGroup.influenceMatrix.communityRelations
          .complianceWithAgreements,
      nivel_satisfaccion_org:
        interestGroup.influenceMatrix.communityRelations
          .organizationSatisfaction,
      promedio: interestGroup.influenceMatrix.communityRelations.average,
    },
    influencia_actor: {
      grado_liderazgo_existente_org:
        interestGroup.influenceMatrix.actorInfluence.organizationLeadership,
      grado_movilizacion:
        interestGroup.influenceMatrix.actorInfluence.mobilization,
      grado_relacion_org_territorio:
        interestGroup.influenceMatrix.actorInfluence
          .territoryOrganizationRelationship,
      grado_relacion_otros_actores_locales:
        interestGroup.influenceMatrix.actorInfluence
          .relationshipWithOtherLocalActors,
      promedio: interestGroup.influenceMatrix.actorInfluence.average,
    },
    alianza_actor: {
      relacion_cooperacion_temporal:
        interestGroup.influenceMatrix.allianceWithActor
          .temporaryCooperativeRelationship,
      relacion_cooperacion_estrategica:
        interestGroup.influenceMatrix.allianceWithActor
          .strategicCooperationRelationship,
      relacion_adversarios:
        interestGroup.influenceMatrix.allianceWithActor.adversariesList,
      promedio: interestGroup.influenceMatrix.allianceWithActor.average,
    },
    promedio_final: interestGroup.influenceMatrix.finalAverage || 0,
  };

  if (interestGroup.id?.length > 0) backendData._id = interestGroup.id;

  return backendData;
};
