import { Component, Vue } from 'vue-property-decorator';
import {
  HistoricalEvent,
  InterestGroupHistoricalInformation,
} from '../../interfaces';

import { vxm } from '@/app/app-state';
import { fetchYears } from '../../services';

@Component
export default class InterestGroupFormFourthStepComponent extends Vue {
  private historicalInformation: InterestGroupHistoricalInformation;
  private eventDialogState = false;
  private years: string[] = [];
  private event: HistoricalEvent;

  constructor() {
    super();
    this.historicalInformation =
      vxm.interestGroup.interestGroupSelected.historicalInformation;
    this.event = this.emptyEventFields();
    this.years = fetchYears();
  }

  onEventDialogCancel() {
    this.eventDialogState = false;
    this.event = this.emptyEventFields();
  }

  onEventDialogAccept() {
    if (this.historicalInformation.events) {
      this.historicalInformation.events.push(this.event);
    }
    this.eventDialogState = false;
    this.event = this.emptyEventFields();
  }

  openEventDialog() {
    this.eventDialogState = true;
  }

  removeEvent(index: number) {
    this.historicalInformation.events?.splice(index, 1);
  }

  emptyEventFields() {
    return {
      name: '',
      year: '',
      description: '',
    };
  }
}
