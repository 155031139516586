import { httpClient } from '@/app/shared/services';
import { OperationalTaskData } from '../interfaces';

const fetchOperationalTasks = () => {
  const operationalTasksApi = '/tareasoperacionales';

  return httpClient
    .get(operationalTasksApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const fetchOperationalTask = (id: string) => {
  const operationalTasksApi = `/tareasoperacionales/${id}`;

  return httpClient
    .get(operationalTasksApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const fetchOperationalTasksSelected = (
  date: string,
  interestGroupId: string,
  answerId: string
) => {
  const operationalTasksApi = `/tareasoperacionales?tareasoperacionales&date=${date}&responsible=${interestGroupId}&answer=${answerId}`;

  return httpClient
    .post(operationalTasksApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const newOperationalTask = (operationalTask: OperationalTaskData) => {
  const operationalTasksApi = '/tareasoperacionales/insert';

  return httpClient
    .post(operationalTasksApi, operationalTask)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const editOperationalTask = (
  id: string,
  operationalTask: OperationalTaskData
) => {
  const claimsApi = `/tareasoperacionales/${id}`;

  return httpClient
    .put(claimsApi, operationalTask)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const deleteOperationalTask = (id: string) => {
  const operationalTasksApi = `/tareasoperacionales/${id}`;

  return httpClient
    .delete(operationalTasksApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

export {
  fetchOperationalTasks,
  fetchOperationalTask,
  fetchOperationalTasksSelected,
  newOperationalTask,
  editOperationalTask,
  deleteOperationalTask,
};
