import { InterestGroupData, InterestGroupBackendData } from '../interfaces';
import { transformBackendData } from '../utils';

export class InterestGroup {
  constructor(private interestGroupData: InterestGroupData) {}

  static transformBackendData(backendData: InterestGroupBackendData) {
    return transformBackendData(backendData);
  }
}
