import { Component, Vue } from 'vue-property-decorator';

import {
  AssignmentData,
  ComplainantData,
  RegistrationData,
} from '../shared/interfaces';
import { Claim } from '../shared/models/claim.model';

import { vxm } from '@/app/app-state';
import { fetchZoneList } from '@/app/interest-groups/shared/services';
import {
  editClaim,
  fetchClaim,
  fetchClaimTypes,
  fetchOffenseLevel,
  fetchReceptionTypes,
  fetchSmellLevels,
  fetchStrategicAspectCategories,
  newClaim,
} from '@/app/claims/shared/services';
import { fetchLocalities } from '@/app/shared/services';

import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Sector } from '@/app/interest-groups/shared/interfaces';

@Component
export default class ClaimItemComponent extends Vue {
  private claimData: Claim;
  private complainantDataForm: ComplainantData;
  private complainantDataRules = {
    name: [
      {
        required: false,
        message: 'Por favor selecciona un Grupo de Interés',
        trigger: 'change',
      },
    ],
  };
  private registrationDataForm: RegistrationData;
  private assignmentDataForm: AssignmentData;
  private localities: object[];
  private receptionTypes: string[];
  private smellLevels: object[];
  private offenseLevels: object[];
  private strategicAspectCategories: string[];
  private zones: string[] = [];
  private sectors: Sector[] = [];
  private operationalInfraestructures: object[] = [];
  private operationalUnits: object[] = [];
  private currentStep = 0;
  private claimSteps = [
    {
      title: 'Datos Denunciante',
      content: 'First-content',
    },
    {
      title: 'Descripción del reclamox',
      content: 'Second-content',
    },
    {
      title: 'Asignación del reclamo',
      content: 'Last-content',
    },
  ];
  private isLoadingClaimItem = false;
  private mapData = {
    pos: {
      lat: -26.9520638,
      lng: -69.9790432,
    },
    zoom: 5,
  };
  headerTitleText = 'Nuevo reclamo';
  submitButtonText = 'Crear reclamo';
  imageModal = false;
  imageModalSource = require('@/assets/clasificacion.png');
  private userInfo: any;
  $refs!: {
    complainantDataForm: any;
  };

  constructor() {
    super();
    this.claimData = new Claim();
    this.complainantDataForm = this.claimData.complainantData;
    this.registrationDataForm = this.claimData.registrationData;
    this.assignmentDataForm = this.claimData.assignmentData;
    this.localities = fetchLocalities();
    this.receptionTypes = fetchReceptionTypes();
    this.smellLevels = fetchSmellLevels();
    this.offenseLevels = fetchOffenseLevel();
    this.strategicAspectCategories = fetchStrategicAspectCategories();

    if (this.$route.name == 'editar-reclamo') {
      this.headerTitleText = 'Editar reclamo';
      this.submitButtonText = 'Guardar reclamo';
    }
  }

  get interestGroupList() {
    return vxm.interestGroup.interestGroupList;
  }

  get reponsiblePersonList() {
    return vxm.interestGroup.responsiblePersonList;
  }

  get estimatedDate() {
    return format(
      addDays(new Date(this.registrationDataForm.estimatedResponse.date), 1),
      'PPPP',
      {
        locale: es,
      }
    );
  }

  get claimsTypes() {
    return fetchClaimTypes().map(type => type.tiporeclamo);
  }

  get causerTypes() {
    const claimTypeSelected = this.assignmentDataForm.responsiblePerson
      ?.claimType;
    if (claimTypeSelected == '') return [];
    const claimTypeFound = fetchClaimTypes().find(
      type => type.tiporeclamo == claimTypeSelected
    );
    return claimTypeFound?.tipocausante;
  }

  async mounted() {
    this.isLoadingClaimItem = true;
    if (this.$route.name == 'editar-reclamo') {
      const claimId = this.$route.params.id;
      const claimRes = await fetchClaim(claimId);
      this.claimData = new Claim(claimRes.res);
      this.complainantDataForm = this.claimData.complainantData;
      this.registrationDataForm = this.claimData.registrationData;
      this.assignmentDataForm = this.claimData.assignmentData;
    }
    await this.getUserInfo();
    await vxm.interestGroup.fetchAll();
    this.calculatedEstimatedDate();
    this.isLoadingClaimItem = false;
  }

  async getUserInfo() {
    if (this.claimData.registrationData.receiver != '') {
      await vxm.users.getUserList();
      const userInfo = vxm.users.userList.find(
        (user: any) => this.claimData.registrationData.receiver == user._id
      );
      this.userInfo = userInfo ? userInfo : {};
    } else {
      this.userInfo.gi['razon_social'] = '';
    }
  }

  onGroupInterestChange(value: string) {
    const interestGroupSelected = this.interestGroupById(value);
    if (interestGroupSelected) {
      this.complainantDataForm.complainantPerson = {
        interestGroupId: interestGroupSelected?._id,
        rut: interestGroupSelected?.rut,
        name: interestGroupSelected?.razon_social,
        primaryContact: interestGroupSelected?.contacto_primario,
        secondaryContact: interestGroupSelected?.contacto_secundario,
        address: interestGroupSelected?.direccion,
        email: interestGroupSelected?.email_primario,
        organization:
          interestGroupSelected?.orgpert.length > 0
            ? interestGroupSelected?.orgpert[
                interestGroupSelected?.orgpert.length - 1
              ].razon_social
            : '',
        occupation: interestGroupSelected?.profesion_oficio,
        charge:
          interestGroupSelected?.orgpert.length > 0
            ? interestGroupSelected?.orgpert[
                interestGroupSelected?.orgpert.length - 1
              ].cargo
            : '',
      };
    }
  }

  onLocalityChange(locality: string) {
    const localities = fetchLocalities();
    const localityFound = localities.find(local => local.localidad == locality);

    this.registrationDataForm.province = localityFound?.provincia || '';
    this.registrationDataForm.commune = localityFound?.comuna || '';
    this.registrationDataForm.region = localityFound?.region || '';
    this.registrationDataForm.zone = localityFound?.zona || '';
  }

  calculateClasificacionForEachAE(index: number) {
    switch (this.registrationDataForm.strategicAspects[index].impact) {
      case 1:
        if (
          this.registrationDataForm.strategicAspects[index].probability === 1 ||
          this.registrationDataForm.strategicAspects[index].probability === 2 ||
          this.registrationDataForm.strategicAspects[index].probability === 3
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Baja';
        } else {
          this.registrationDataForm.strategicAspects[index].classification =
            'Media';
        }
        break;
      case 2:
        if (
          this.registrationDataForm.strategicAspects[index].probability === 1 ||
          this.registrationDataForm.strategicAspects[index].probability === 2
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Baja';
        } else if (
          this.registrationDataForm.strategicAspects[index].probability === 3 ||
          this.registrationDataForm.strategicAspects[index].probability === 4
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Media';
        } else {
          this.registrationDataForm.strategicAspects[index].classification =
            'Alta';
        }
        break;
      case 3:
        if (
          this.registrationDataForm.strategicAspects[index].probability === 1 ||
          this.registrationDataForm.strategicAspects[index].probability === 2
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Media';
        } else if (
          this.registrationDataForm.strategicAspects[index].probability === 3 ||
          this.registrationDataForm.strategicAspects[index].probability === 4
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Alta';
        } else {
          this.registrationDataForm.strategicAspects[index].classification =
            'Extrema';
        }
        break;
      case 4:
        if (
          this.registrationDataForm.strategicAspects[index].probability === 1
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Media';
        } else if (
          this.registrationDataForm.strategicAspects[index].probability === 2 ||
          this.registrationDataForm.strategicAspects[index].probability === 3
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Alta';
        } else {
          this.registrationDataForm.strategicAspects[index].classification =
            'Extrema';
        }
        break;
      case 5:
        if (
          this.registrationDataForm.strategicAspects[index].probability === 1 ||
          this.registrationDataForm.strategicAspects[index].probability === 2
        ) {
          this.registrationDataForm.strategicAspects[index].classification =
            'Alta';
        } else {
          this.registrationDataForm.strategicAspects[index].classification =
            'Extrema';
        }
        break;
    }
    this.calculateRecommendedAction(index);
    this.calculateEstimatedResponse();
  }

  calculateRecommendedAction(index: number) {
    switch (this.registrationDataForm.strategicAspects[index].classification) {
      case 'Baja':
        this.registrationDataForm.strategicAspects[index].recommendedAction =
          'Reclamo debe ser monitoreado de forma sistemática. No requiere necesariamente un Plan de Acción adicional a lo ya establecido para prevenir / mitigar el riesgo.';
        break;
      case 'Media':
        this.registrationDataForm.strategicAspects[index].recommendedAction =
          'Reclamos debe tener controles orientados a reducir o transferir los riesgos. Puede que sea necesario implementar un Plan de Acción, pero sin que el costo asociado sea desproporcionalmente mayor a los beneficios esperados. Deben ser presentados al Gerente General al menos tres veces al año.';
        break;
      case 'Alta':
        this.registrationDataForm.strategicAspects[index].recommendedAction =
          'Reclamo requiere un Plan de Acción para reducir / evitar la exposición y hacerlo aceptable. Incluye incorporar controles que ayuden prevenir / mitigar el riesgo actual. Deben ser presentados al Directorio al menos una vez al año.';
        break;
      case 'Extrema':
        this.registrationDataForm.strategicAspects[index].recommendedAction =
          'Reclamo requiere un Plan de Acción de manera inmediata para reducir / evitar la exposición y hacerlo aceptable. Incluye incorporar controles que ayuden prevenir / mitigar el riesgo actual. Deben ser presentados al Directorio al menos una vez al año. Activar Comité de Crisis.';
        break;
      default:
        this.registrationDataForm.strategicAspects[index].recommendedAction =
          'Dar respuesta.';
    }
  }

  calculateEstimatedResponse() {
    const length = this.registrationDataForm.strategicAspects.length;
    let impact = 0;
    let probability = 0;
    let appetite = 0;

    this.registrationDataForm.strategicAspects.forEach(element => {
      impact += element.impact;
      probability += element.probability;
      appetite += element.appetite;
    });

    this.registrationDataForm.estimatedResponse.impact =
      impact / length ? Math.round(impact / length) : 0;
    this.registrationDataForm.estimatedResponse.probability =
      probability / length ? Math.round(probability / length) : 0;
    this.registrationDataForm.estimatedResponse.appetite =
      appetite / length ? Math.round(appetite / length) : 0;
    this.calculateEstimatedClassification();
  }

  calculateEstimatedClassification() {
    const impact = this.registrationDataForm.estimatedResponse.impact;
    const probability = this.registrationDataForm.estimatedResponse.probability;

    switch (impact) {
      case 1:
        if (probability === 1 || probability === 2 || probability === 3) {
          this.registrationDataForm.estimatedResponse.classification = 'Baja';
        } else {
          this.registrationDataForm.estimatedResponse.classification = 'Media';
        }
        break;
      case 2:
        if (probability === 1 || probability === 2) {
          this.registrationDataForm.estimatedResponse.classification = 'Baja';
        } else if (probability === 3 || probability === 4) {
          this.registrationDataForm.estimatedResponse.classification = 'Media';
        } else {
          this.registrationDataForm.estimatedResponse.classification = 'Alta';
        }
        break;
      case 3:
        if (probability === 1 || probability === 2) {
          this.registrationDataForm.estimatedResponse.classification = 'Media';
        } else if (probability === 3 || probability === 4) {
          this.registrationDataForm.estimatedResponse.classification = 'Alta';
        } else {
          this.registrationDataForm.estimatedResponse.classification =
            'Extrema';
        }
        break;
      case 4:
        if (probability === 1) {
          this.registrationDataForm.estimatedResponse.classification = 'Media';
        } else if (probability === 2 || probability === 3) {
          this.registrationDataForm.estimatedResponse.classification = 'Alta';
        } else {
          this.registrationDataForm.estimatedResponse.classification =
            'Extrema';
        }
        break;
      case 5:
        if (probability === 1 || probability === 2) {
          this.registrationDataForm.estimatedResponse.classification = 'Alta';
        } else {
          this.registrationDataForm.estimatedResponse.classification =
            'Extrema';
        }
        break;
    }
    this.calculateEstimatedPriority();
  }

  calculateEstimatedPriority() {
    const classification = this.registrationDataForm.estimatedResponse
      .classification;
    if (classification === 'Baja' || classification === 'Media') {
      this.registrationDataForm.estimatedResponse.priority = 'Regular';
    } else if (classification == 'Alta') {
      this.registrationDataForm.estimatedResponse.priority = 'Alta';
    } else {
      this.registrationDataForm.estimatedResponse.priority = 'Urgente';
    }
    this.calculatedEstimatedDays();
  }

  calculatedEstimatedDays() {
    const priority = this.registrationDataForm.estimatedResponse.priority;
    if (priority === 'Regular') {
      this.registrationDataForm.estimatedResponse.days = 3;
    } else if (priority === 'Alta') {
      this.registrationDataForm.estimatedResponse.days = 2;
    } else {
      this.registrationDataForm.estimatedResponse.days = 1;
    }
    this.calculatedEstimatedDate();
  }

  calculatedEstimatedDate() {
    const estimatedDate = addDays(
      new Date(this.registrationDataForm.date),
      this.registrationDataForm.estimatedResponse.days
    );
    this.registrationDataForm.estimatedResponse.date = format(
      addDays(estimatedDate, 1),
      'yyyy-MM-dd'
    );
  }

  addNewStrategicAspect() {
    this.registrationDataForm.strategicAspects.push({
      category: '',
      appetite: 1,
      impact: 1,
      probability: 1,
      classification: 'Baja',
      recommendedAction:
        'Reclamo debe ser monitoreado de forma sistemática. No requiere necesariamente un Plan de Acción adicional a lo ya establecido para prevenir / mitigar el riesgo.',
    });
    this.calculateEstimatedResponse();
  }

  removeStrategicAspect(index: number) {
    this.registrationDataForm.strategicAspects.splice(index, 1);
    this.calculateEstimatedResponse();
  }

  getColorByClassification(classification: string) {
    if (classification == 'Baja') return 'green';
    if (classification == 'Media') return 'yellow';
    if (classification == 'Alta') return 'orange';
    return 'red';
  }

  onResponsiblePersonChange(value: string) {
    const interestGroupSelected = this.interestGroupById(value);
    if (interestGroupSelected) {
      this.zones = interestGroupSelected.zona as string[];
      this.sectors = interestGroupSelected.sector;
      this.operationalInfraestructures =
        interestGroupSelected.infra_operacional;
      this.operationalUnits = interestGroupSelected.unidad_operacional;
      this.assignmentDataForm.responsiblePerson = {
        interestGroupId: interestGroupSelected?._id,
        name: interestGroupSelected?.razon_social,
        rut: interestGroupSelected.rut,
        charge:
          interestGroupSelected?.orgpert.length > 0
            ? interestGroupSelected?.orgpert[
                interestGroupSelected?.orgpert.length - 1
              ].cargo
            : '',
        interestGroupName: interestGroupSelected?.grupointeres,
        email: interestGroupSelected?.email_primario,
        zone: '',
        sector: '',
        operationalInfrastructure: '',
        operationalUnit: '',
        area: interestGroupSelected?.area,
      };
    }
  }

  openImage(image: string) {
    this.imageModal = true;
    switch (image) {
      case 'apetito':
        this.imageModalSource = require('@/assets/apetito.png');
        break;
      case 'probabilidad':
        this.imageModalSource = require('@/assets/probabilidad.png');
        break;
      case 'clasificacion':
        this.imageModalSource = require('@/assets/clasificacion.png');
        break;
      case 'impacto':
        this.imageModalSource = require('@/assets/impacto.png');
        break;
    }
  }

  interestGroupById = (id: string) => {
    const interestGroupFound = vxm.interestGroup.interestGroupList.find(
      gi => gi._id == id
    );

    return interestGroupFound;
  };

  get sectorsOnMap() {
    const sector = this.sectorByName(
      this.assignmentDataForm.responsiblePerson.sector
    );

    this.mapData.pos = sector
      ? sector.pos
      : {
          lat: -26.9520638,
          lng: -69.9790432,
        };
    this.mapData.zoom = sector ? 12 : 5;

    return sector ? [sector] : [];
  }

  sectorByName(name: string) {
    let sectors: any[] = [];
    const zones = fetchZoneList();
    zones.map(zone => {
      sectors = sectors.concat(zone.sector);
    });

    const sectorFound = sectors.find(sector => sector.nombre == name);

    return sectorFound;
  }

  filterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  nextStep() {
    if (this.currentStep == 0) {
      this.$refs.complainantDataForm.validate((valid: boolean) => {
        if (valid) {
          this.currentStep++;
        }
      });
    } else {
      this.currentStep++;
    }
  }

  prevStep() {
    this.currentStep--;
  }

  async submitClaimItem() {
    this.isLoadingClaimItem = true;
    this.claimData.updateClaimStage();
    let response;
    if (this.$route.name == 'editar-reclamo') {
      response = await editClaim(
        this.claimData.getId(),
        this.claimData.getWithOutId()
      );
    } else {
      response = await newClaim(this.claimData);
    }
    this.isLoadingClaimItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'reclamos',
      });
    }
  }
}
