const fetchSectors = () => {
  const sectors = [
    {
      name: 'Cerdo',
      types: [
        'AC Rincón de Abra',
        'Barrancas',
        'Castrol',
        'Chancón 1',
        'Chancón 2',
        'Corneche',
        'Coya 1',
        'Coya 2',
        'Cuarentena Rubiana',
        'Divisadero',
        'Don Charles (Cerdos)',
        'Don Sata',
        'El  Quisco (Cerdos)',
        'El Alcalde',
        'El Almendro (Cerdos)',
        'El Capricho',
        'El Carmen (Cerdos)',
        'El Espino (Cerdos)',
        'El Estero',
        'El Honorable',
        'El Litre',
        'El Milagro',
        'El Mirador',
        'El Monte',
        'El Peumo (Cerdos)',
        'El Tótem',
        'El Yali',
        'Elhue',
        'Grupo 1',
        'Grupo 10',
        'Grupo 11',
        'Grupo 12',
        'Grupo 13',
        'Grupo 14',
        'Grupo 15',
        'Grupo 16',
        'Grupo 17',
        'Grupo 18',
        'Grupo 19',
        'Grupo 2',
        'Grupo 20',
        'Grupo 21',
        'Grupo 22',
        'Grupo 23',
        'Grupo 24',
        'Grupo 25',
        'Grupo 26',
        'Grupo 28',
        'Grupo 3',
        'Grupo 4',
        'Grupo 5',
        'Grupo 6',
        'Grupo 7',
        'Grupo 8',
        'Grupo 9',
        'La Arboleda (Cerdos)',
        'La Caña',
        'La Copa',
        'La Esmeralda (Cerdos)',
        'La Lechería',
        'La Leonera (Cerdos)',
        'La Maravilla',
        'La Mina (Cerdos)',
        'La Noria',
        'La Tosca',
        'Las Brisas',
        'Las Murallas',
        'Las Varillas',
        'Los Alamos',
        'Los Clonquis',
        'Los Concejales',
        'Los Cururos',
        'Los Gomeros (Cerdos)',
        'Los Llanos',
        'Los Tatas',
        'Maitenlahue',
        'Manzo',
        'Mr. Dic',
        'Piedra del Jote',
        'Piedra del Traro',
        'Pocillas',
        'Rapel',
        'Retorno',
        'San Manuel',
        'Sitio 1',
        'Sitio 2',
        'Sitio 3',
        'Sitio 4',
        'Sitio 5',
        'Sitio 6',
        'Sitio 7',
        'Sitio 8',
        'Sitio 9',
        'Stud Ramirana',
        'Stud Santa Rosa',
        'Stud Tantehue',
        'Testaje',
        'Totihue (Cerdos)',
      ],
    },
    {
      name: 'Pavo',
      types: [
        'CAG  Navío',
        'CAG Las Palmas',
        'CAG Mariano Alfonso',
        'CAG Trapiche',
        'Canelo',
        'Cardonal 1-1',
        'Cardonal 1-2',
        'Cardonal 2',
        'Casablanca',
        'Chamizal',
        'El Acacio',
        'El Aromo',
        'El Belloto',
        'El Bosque',
        'El Coihue',
        'El Cururo',
        'El Espino (Pavos)',
        'El Mañío',
        'El Navío',
        'El Peumo (Pavos)',
        'El Pulmahue',
        'El Quebracho',
        'El Roble',
        'El Sauce',
        'El Tranque',
        'Huaquén 1-1',
        'Huaquén 1-2',
        'Huaquén 2-1',
        'Huaquén 2-2',
        'Huaquén 3',
        'Huaquén 4',
        'Incubadora Cardonal',
        'Incubadora Pucalán',
        'La Floresta',
        'La Fragua',
        'La Gloria',
        'La Horqueta',
        'La Mina (Pavos)',
        'La Nueva o La Peña',
        'Las Añañucas',
        'Las Burras',
        'Las Codornices',
        'Las Encinas',
        'Las Torres',
        'Las Varas',
        'Los Bellotos',
        'Los Boldos',
        'Los Chaguales',
        'Los Guanacos',
        'Los Hornos',
        'Los Maquis',
        'Los Molles',
        'Los Naranjos',
        'Los Perales',
        'Los Pinos (Pavos)',
        'Los Tilos',
        'Melón',
        'Pucalán 1',
        'Pucalán 2',
        'Pucalán 3',
        'Pucalán 4',
        'Pucalán 5',
        'Pucalán 6',
        'Romeral',
        'Rosario',
        'San Francisco',
        'Santa Ana',
        'Stud Huaquén',
        'Stud La Piedra',
        'Torombolo',
      ],
    },
    {
      name: 'Pollo',
      types: [
        'Alhue',
        'Bosque Nuevo',
        'Bosque Viejo',
        'Candelaria 1',
        'Candelaria 2',
        'Candelaria 3',
        'Candelaria 4',
        'Candelaria 5',
        'Candelaria 6',
        'Candelaria 7',
        'Castaños',
        'Castillo 1',
        'Castillo 10',
        'Castillo 2',
        'Castillo 3',
        'Castillo 4',
        'Castillo 5',
        'Castillo 6',
        'Castillo 7',
        'Castillo 8',
        'Castillo 9',
        'Chayaco 1',
        'Chayaco 2',
        'Chifri',
        'Coya 1',
        'Coya 2',
        'Coya 3',
        'Coya 4',
        'Coya 5',
        'Coya 6',
        'Coya 7',
        'Demetrio',
        'Don Ambrosio',
        'Don Charles (Pollos)',
        'Don Cornelio',
        'Don Feña',
        'Don Forster',
        'Don Gastón',
        'Don Jorge',
        'Don Lalo',
        'Don Rica',
        'Don Tito',
        'Don Wilson',
        'Doña Ema',
        'Dusett',
        'El  Quisco (Pollos)',
        'El Ajial 1',
        'El Ajial 2',
        'El Ajial 3',
        'El Almendro (Pollos)',
        'El Carmen (Pollos)',
        'El Diputado',
        'El Galeon',
        'El Trigo',
        'El Tucán',
        'El Valle',
        'Ensayo Bosque Viejo',
        'Huilmay 1',
        'Huilmay 2',
        'Incubadora Curacaví',
        'Incubadora Las Arañas',
        'Incubadora Lo Miranda',
        'La  Compañía',
        'La Arboleda (Pollos)',
        'La Esmeralda (Pollos)',
        'La Estrella',
        'La Fiera',
        'La Florida',
        'La Higuera',
        'La Leonera (Pollos)',
        'La Libreta',
        'La Liguana',
        'La Punta',
        'La Soledad 1',
        'La Soledad 10',
        'La Soledad 11',
        'La Soledad 12',
        'La Soledad 2',
        'La Soledad 3',
        'La Soledad 4',
        'La Soledad 5',
        'La Soledad 6',
        'La Soledad 7',
        'La Soledad 8',
        'La Soledad 9',
        'La Soya',
        'La Trompeta',
        'Las Cornizas',
        'Las Cucas',
        'Las Diucas',
        'Las Lomas Norte',
        'Las Lomas Sur',
        'Las Vegas',
        'Loica',
        'Lolenco',
        'Longovilo',
        'Los Chinos II',
        'Los Culenes',
        'Los Gomeros (Pollos)',
        'Los Loros',
        'Los Olivos 1',
        'Los Olivos 2',
        'Los Olivos 3',
        'Los Olivos 4',
        'Los Olivos 5',
        'Los Olivos 6',
        'Los Olivos 7',
        'Los Pavos',
        'Los Pinos (Pollos)',
        'Maitenes 1',
        'Maitenes 2',
        'Maitenes 3',
        'Maitenes 4',
        'Maitenes 5',
        'Maitenes 6',
        'Matanza',
        'Murallas 1',
        'Picarquín',
        'Puangue',
        'Quillayes',
        'Ramirana 1',
        'Ramirana 2',
        'Ramirana 3',
        'Ramirana 4',
        'Ramirana 5',
        'Ramirana 6',
        'Ramirana 7',
        'Ramirana 8',
        'Rubiana 1',
        'Rubiana 2',
        'Rubiana 3',
        'Rubiana 4',
        'Rubiana 5',
        'San Agustin',
        'San Ignacio',
        'San Pedro',
        'San Pedro 1',
        'San Pedro 2',
        'San Pedro 3',
        'San Pedro 4',
        'Santa Elena 1',
        'Santa Elena 2',
        'Santa Elena 3',
        'Santa Elena 4',
        'Santa Rosa',
        'Santa Teresa',
        'Tantehue 1',
        'Tantehue 2',
        'Tantehue 3',
        'Tantehue 4',
        'Tantehue 5',
        'Tantehue 6',
        'Tantehue 7',
        'Tantehue 8',
        'Tantehue 9',
        'Totihue (Pollos)',
        'UR 1',
        'UR 2',
        'UR 3',
        'Valdebenito',
        'ZVC',
      ],
    },
    {
      name: 'Sistemas Tratamiento',
      types: [
        'Biodigestor Corneche',
        'Biodigestor La Estrella',
        'Biodigestor Las Palmas',
        'Biodigestor Peralillo',
        'Biodigestor Pocillas',
        'Biodigestor Ramirana',
        'Biodigestor Santa Rosa',
        'Cancha de Compostaje La Estrella',
        'Cancha de Compostaje La Manga 1-3',
        'Cancha de Compostaje Maitenlahue 1',
        'Cancha de Compostaje Tantehue 1',
        'Cancha de Compostaje Tratamiento Valdebenito',
        'Laguna Alcalde 1,2',
        'Laguna Arboleda',
        'Laguna Biodigestor',
        'Laguna Candelaria 2-4',
        'Laguna Concejales 1,2',
        'Laguna Corneche 1-3',
        'Laguna Divisadero 1,2',
        'Laguna Don Charles',
        'Laguna El Almendro',
        'Laguna El Carmen',
        'Laguna Esmeralda',
        'Laguna Frutícola',
        'Laguna Gomeros',
        'Laguna Grupo 14, 1-3',
        'Laguna Grupo 17 - 1,2',
        'Laguna Grupo 20',
        'Laguna Grupo Maitenlahue',
        'Laguna Grupo Monte',
        'Laguna Grupo Yali',
        'Laguna La Estrella 1-4',
        'Laguna La Higuera 1,2',
        'Laguna La Leonera',
        'Laguna La Mina',
        'Laguna La Noria 1,2',
        'Laguna Las Brisas',
        'Laguna Lechería',
        'Laguna Loica 1,2',
        'Laguna Los Guindos',
        'Laguna Maitenlahue',
        'Laguna Milagro',
        'Laguna Peralillo',
        'Laguna Quisco',
        'Laguna Ramirana 1-4',
        'Laguna Rincón de Abra',
        'Laguna Tantehue 1-4',
        'Laguna Totihue',
        'Laguna Valdebenito 1-4',
        'Lagunas Chancón 1,2',
        'Lombrifiltros Candelaria',
        'Lombrifiltros Coya 1,2',
        'Lombrifiltros Lechería',
        'Lombrifiltros Testaje',
        'Planta de Tratamiento La Estrella',
        'Planta de Tratamiento La Manga',
        'Planta de Tratamiento Maitenlahue',
        'Planta de Tratamiento Peralillo',
        'Planta de Tratamiento Pocillas',
        'Planta de Tratamiento Ramirana',
        'Planta de Tratamiento Tantehue',
        'Planta de Tratamiento Valdebenito',
        'Sistemas de Tratamiento Tradicional Sitio 5 y 6',
        'Sistemas de Tratamiento Tradicional Sitio 7 y 8',
      ],
    },
    {
      name: 'Plantas Alimentos',
      types: ['Lo Miranda', 'Longovilo', 'Casablanca', 'La Calera'],
    },
    {
      name: 'Faenadora',
      types: ['Lo Miranda', 'San Vicente', 'Rosario', 'Sopraval'],
    },
  ];

  return sectors;
};

export { fetchSectors };
