import { vxm } from '@/app/app-state';
import { Component, Vue } from 'vue-property-decorator';
import { deleteUser } from '../shared/services';

@Component
export default class UserListComponent extends Vue {
  private userSelected: any = {
    gi: {
      rut: '',
    },
  };
  private userColumns = [
    {
      title: 'Razón Social',
      dataIndex: 'gi.razon_social',
    },
    {
      title: 'RUT',
      dataIndex: 'gi.rut',
    },
    {
      title: 'Email',
      dataIndex: 'gi.email_primario',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      scopedSlots: { customRender: 'role' },
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];
  private isDeleteUserLoading = false;
  private showDeleteUserModal = false;
  private isUsersTableLoading = false;

  async mounted() {
    await this.getUserList();
  }

  get users() {
    return vxm.users.userList;
  }

  async getUserList() {
    this.isUsersTableLoading = true;
    await vxm.users.getUserList();
    this.isUsersTableLoading = false;
  }

  async deleteUser() {
    this.isDeleteUserLoading = true;
    const response = await deleteUser(this.userSelected._id);
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
    }
    this.isDeleteUserLoading = false;
    this.closeDeleteUserModal();
    await this.getUserList();
  }

  openDeleteUserModal(user: object) {
    this.userSelected = user;
    this.showDeleteUserModal = true;
  }

  closeDeleteUserModal() {
    this.userSelected = {
      gi: {
        rut: '',
      },
    };
    this.showDeleteUserModal = false;
  }

  getRoleEsName(user: any) {
    let esName = '';
    switch (user.role) {
      case 'admin':
        esName = 'Administrador';
        break;
      case 'workers':
        esName = 'Empleado';
        break;
      case 'collaborator':
        esName = 'Colaborador';
        break;
      case 'complainants':
        esName = 'Denunciante';
        break;
    }

    return esName;
  }
}
