const fetchExternalityTypes = () => {
  const externalityTypes = [
    'Externalidad de Agrosuper',
    'No externalidad de Agrosuper',
    'Falta Información',
  ];

  return externalityTypes;
};

export { fetchExternalityTypes };
