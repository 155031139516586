import { Component, Vue, Watch } from 'vue-property-decorator';
import {
  InterestGroupBackendData,
  InterestGroupGeneralInformation,
  InterestGroupLaborInformation,
} from '../../interfaces';

import { vxm } from '@/app/app-state';
import {
  fetchAreaList,
  fetchEducationLevels,
  fetchMaritalStatusList,
  fetchOccupations,
  fetchPJClassifications,
  fetchPJTypes,
  fetchZoneList,
} from '../../services';
import { fetchLocalities } from '@/app/shared/services';

@Component
export default class InterestGroupFormThirdStepComponent extends Vue {
  private occupations: string[];
  private educationLevels: string[];
  private pjTypes: string[];
  private pjClassifications: string[];
  private maritalStatusList: string[];
  private organizationList: InterestGroupBackendData[];
  private responsiblePersonList: InterestGroupBackendData[];
  private organizationSelected: Partial<InterestGroupBackendData> = {};
  private choresSelected: { text: string }[] = [];
  private charge = '';
  private year = 0;
  private areaList: object[];
  private localities: object[];
  private sectors: object[] = [];
  private laborInformation: InterestGroupLaborInformation;
  private generalInformation: InterestGroupGeneralInformation;

  constructor() {
    super();
    this.generalInformation =
      vxm.interestGroup.interestGroupSelected.generalInformation;
    this.laborInformation =
      vxm.interestGroup.interestGroupSelected.laborInformation;
    this.occupations = fetchOccupations();
    this.educationLevels = fetchEducationLevels();
    this.pjTypes = fetchPJTypes();
    this.pjClassifications = fetchPJClassifications();
    this.maritalStatusList = fetchMaritalStatusList();
    this.organizationList = vxm.interestGroup.organizationList;
    this.responsiblePersonList = vxm.interestGroup.responsiblePersonList;
    this.areaList = fetchAreaList();
    this.localities = fetchLocalities();
  }

  get organizationSelectedChoreNames(): object[] {
    this.choresSelected = [];
    return this.organizationSelected.faena
      ? this.organizationSelected.faena
      : [];
  }

  get zones() {
    return fetchZoneList().map(zone => zone.zona);
  }

  @Watch('laborInformation.registeredWorker.zone')
  getSectors() {
    let sectors: object[] = [];

    if (this.laborInformation.registeredWorker.zone) {
      this.laborInformation.registeredWorker.zone.map(zone => {
        sectors = sectors.concat(this.getSectorsByZone(zone));
      });
    }

    this.sectors = sectors;
  }

  addOrganization() {
    this.laborInformation.registeredWorker.organizations?.push({
      id: '',
      businessName: this.organizationSelected.razon_social || '',
      charge: this.charge,
      year: this.year,
      chore: this.choresSelected.map(chore => chore.text),
    });
  }

  removeOrganization(index: number) {
    this.laborInformation.registeredWorker?.organizations?.splice(index, 1);
  }

  getManagementByArea(area: string) {
    const areas = fetchAreaList();
    const areaFound = areas.find(ar => ar.area == area);

    this.laborInformation.registeredWorker.management =
      areaFound?.gerencia || '';
    this.laborInformation.registeredWorker.submanagement =
      areaFound?.subgerencia || '';
  }

  getUbicationByLocality(locality: string) {
    const localities = fetchLocalities();
    const localityFound = localities.find(local => local.localidad == locality);

    this.laborInformation.registeredWorker.province =
      localityFound?.provincia || '';
    this.laborInformation.registeredWorker.commune =
      localityFound?.comuna || '';
    this.laborInformation.registeredWorker.region = localityFound?.region || '';
    this.laborInformation.registeredWorker.sector = [];
    this.laborInformation.registeredWorker.operationalUnit = [];
    this.laborInformation.registeredWorker.operationalInfrastructure = [];
  }

  getSectorsByZone(zone: string) {
    const zones = fetchZoneList();
    const sectorsFound = zones.find(zo => zo.zona == zone);

    return sectorsFound ? sectorsFound.sector : [];
  }

  onOptionCreate(newOption: string) {
    const chore = {
      text: newOption,
    };

    return chore;
  }
}
