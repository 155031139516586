import { httpClient } from '@/app/shared/services';
import { InterestGroupBackendData } from '../interfaces';

const fetchInterestGroup = () => {
  const interestGroupApi = '/gi';

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const fetchInterestGroupByFilter = (column: string, query: string) => {
  const interestGroupApi = `/gi/search/filter/?column=${column}&query=${query}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const fetchPDFInterestGroupByFilter = (column: string, query: string) => {
  const interestGroupApi = `/gi/search/filter/pdf/?column=${column}&query=${query}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const fetchSheetInterestGroupByFilter = (column: string, query: string) => {
  const interestGroupApi = `/gi/search/filter/excel/?column=${column}&query=${query}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const sendSheetToEmail = (column: string, query: string, email: string) => {
  const interestGroupApi = `/gi/search/filter/send-email/?column=${column}&query=${query}`;

  return httpClient.post(interestGroupApi, { email }).then(res => res.data);
};

const newInterestGroup = (interestGroup: InterestGroupBackendData) => {
  const interestGroupApi = '/gi/insert';

  return httpClient
    .post(interestGroupApi, interestGroup)
    .then(res => {
      console.log(res);
      res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

const editInterestGroup = (interestGroup: InterestGroupBackendData) => {
  const interestGroupApi = '/gi/edit';

  return httpClient
    .put(interestGroupApi, interestGroup)
    .then(res => {
      console.log(res);
      res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

const getInterestGroup = (id: string) => {
  const interestGroupApi = `/gi/${id}`;

  return httpClient
    .get(interestGroupApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const getInterestGroupRanking = (year: number, quantity: number) => {
  const interestGroupApi = `/gi/ranking/top/topclaims/?year=${year}&number=${quantity}`;

  return httpClient
    .get(interestGroupApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const deleteInterestGroup = (id: string) => {
  const interestGroupApi = `/gi/${id}`;

  return httpClient
    .delete(interestGroupApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const editInterestGroupPosition = (interestGroupPosition: {
  lat: number;
  lng: number;
  zoom: number;
  id: string;
}) => {
  const interestGroupApi = '/gi/geo';

  return httpClient
    .post(interestGroupApi, interestGroupPosition)
    .then(res => {
      console.log(res);
      res.data;
    })
    .catch(err => {
      console.log(err);
    });
};

export {
  fetchInterestGroup,
  fetchInterestGroupByFilter,
  fetchPDFInterestGroupByFilter,
  fetchSheetInterestGroupByFilter,
  sendSheetToEmail,
  editInterestGroup,
  newInterestGroup,
  editInterestGroupPosition,
  deleteInterestGroup,
  getInterestGroup,
  getInterestGroupRanking,
};
