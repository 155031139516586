import { vxm } from '@/app/app-state';
import { Component, Vue } from 'vue-property-decorator';
import { InterestGroupInfluenceMatrix } from '../../interfaces';

@Component
export default class InterestGroupFormFifthStepComponent extends Vue {
  influenceMatrix: InterestGroupInfluenceMatrix;
  influenceMatrixOptions = ['Alta', 'Media', 'Baja'];

  constructor() {
    super();
    this.influenceMatrix =
      vxm.interestGroup.interestGroupSelected.influenceMatrix;
  }

  getColorByOption(option: string, inverted?: boolean) {
    const hundredValue = inverted ? 'Baja' : 'Alta';
    if (option == hundredValue) return 'danger';
    if (option == 'Media') return 'warn';
    return '';
  }

  roundNumber(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  calculateEachAverage(values: string[], inverted?: boolean) {
    let average = 0;
    const hundredValue = inverted ? 'Baja' : 'Alta';
    values.forEach(value => {
      if (value == 'Media') {
        average = average + 50;
      } else if (value == hundredValue) {
        average = average + 100;
      }
    });

    return (average = this.roundNumber(average / values.length, 1));
  }

  calculateGeneralAverage() {
    const rc = [
      this.influenceMatrix.communityRelations.communication,
      this.influenceMatrix.communityRelations.confidence,
      this.influenceMatrix.communityRelations.organizationKnowledge,
      this.influenceMatrix.communityRelations.complianceWithAgreements,
      this.influenceMatrix.communityRelations.organizationSatisfaction,
    ];
    const ia = [
      this.influenceMatrix.actorInfluence?.territoryOrganizationRelationship,
      this.influenceMatrix.actorInfluence?.relationshipWithOtherLocalActors,
      this.influenceMatrix.actorInfluence?.organizationLeadership,
      this.influenceMatrix.actorInfluence?.mobilization,
    ];
    const aa = [
      this.influenceMatrix.allianceWithActor?.strategicCooperationRelationship,
      this.influenceMatrix.allianceWithActor?.adversariesList,
      this.influenceMatrix.allianceWithActor?.temporaryCooperativeRelationship,
    ];

    this.influenceMatrix.communityRelations.average = this.calculateEachAverage(
      rc,
      true
    );
    this.influenceMatrix.actorInfluence.average = this.calculateEachAverage(ia);
    this.influenceMatrix.allianceWithActor.average = this.calculateEachAverage(
      aa,
      true
    );

    this.influenceMatrix.finalAverage = this.roundNumber(
      (this.influenceMatrix.communityRelations.average +
        this.influenceMatrix.actorInfluence.average +
        this.influenceMatrix.allianceWithActor.average) /
        3,
      1
    );
  }
}
