import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Component, Vue } from 'vue-property-decorator';
import { VerificationItem } from '../shared/interfaces';
import { Claim } from '../shared/models';
import { editClaim, fetchClaim } from '../shared/services';

@Component
export default class ClaimLessonsComponent extends Vue {
  private claimData: Claim;
  private isLoadingClaimItem = false;

  constructor() {
    super();
    this.claimData = new Claim();
  }

  get claimCode() {
    return this.claimData.code ? this.claimData.code : 'REC-AACC-2020-XXXXXX';
  }

  get calculateTotalRepresentativeness() {
    const totalRepresentativeness =
      this.calculateRepresentativeness(
        this.claimData.learnedLessons.verificationList.planification.items
      ) +
      this.calculateRepresentativeness(
        this.claimData.learnedLessons.verificationList.executionAndControl.items
      ) +
      this.calculateRepresentativeness(
        this.claimData.learnedLessons.verificationList.humanFactors.items
      ) +
      this.calculateRepresentativeness(
        this.claimData.learnedLessons.verificationList.general.items
      );

    return totalRepresentativeness / 4;
  }

  get calculateTotalImpactAverage() {
    const totalRepresentativeness =
      this.calculateImpactAverage(
        this.claimData.learnedLessons.verificationList.planification.items
      ) +
      this.calculateImpactAverage(
        this.claimData.learnedLessons.verificationList.executionAndControl.items
      ) +
      this.calculateImpactAverage(
        this.claimData.learnedLessons.verificationList.humanFactors.items
      ) +
      this.calculateImpactAverage(
        this.claimData.learnedLessons.verificationList.general.items
      );

    return Math.round(totalRepresentativeness / 4);
  }

  get calculateTotalImpact() {
    if (
      this.calculateTotalImpactAverage >= 0 &&
      this.calculateTotalImpactAverage <= 2
    ) {
      return 'Bajo';
    } else if (
      this.calculateTotalImpactAverage > 2 &&
      this.calculateTotalImpactAverage <= 4
    ) {
      return 'Medio';
    } else {
      return 'Alto';
    }
  }

  async mounted() {
    await this.getClaim();
  }

  async getClaim() {
    this.isLoadingClaimItem = true;
    const claimId = this.$route.params.id;
    const claimRes = await fetchClaim(claimId);
    this.claimData = new Claim(claimRes.res);
    this.isLoadingClaimItem = false;
  }

  formatDatePPPP(date: string) {
    return format(addDays(new Date(date), 1), 'PPPP', {
      locale: es,
    });
  }

  onCompletedOptionChange(value: string, idx: number, section: string) {
    const list: any = this.claimData.learnedLessons.verificationList;
    if (value == 'N/A') {
      list[section].items[idx].impactLevel = 0;
    }
  }

  calculateRepresentativeness(items: VerificationItem[]) {
    const verificatedItems = items.filter(item => item.completed != 'N/A')
      .length;
    return Math.round((verificatedItems * 100) / items.length);
  }

  calculateImpactAverage(items: VerificationItem[]) {
    let totalImpact = 0;
    const verificatedItems = items.filter(item => {
      totalImpact = totalImpact + item.impactLevel;
      return item.completed != 'N/A';
    }).length;

    return verificatedItems == 0
      ? 0
      : Math.round(totalImpact / verificatedItems);
  }

  calculateImpact(items: VerificationItem[]) {
    const average = this.calculateImpactAverage(items);
    if (average >= 0 && average <= 2) {
      return 'Bajo';
    } else if (average > 2 && average <= 4) {
      return 'Medio';
    } else {
      return 'Alto';
    }
  }

  goToClosure() {
    this.$router.push({
      name: 'reclamo-comunicacion-cierre',
      params: { id: this.claimData.getId() },
    });
  }

  async saveClaim() {
    this.isLoadingClaimItem = true;
    this.claimData.updateClaimStage();
    const response = await editClaim(
      this.claimData.getId(),
      this.claimData.getWithOutId()
    );
    this.isLoadingClaimItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      await this.getClaim();
    }
  }

  async saveAndFinalizeClaim() {
    this.isLoadingClaimItem = true;
    this.claimData.finalized = true;
    this.claimData.updateClaimStage();
    const response = await editClaim(
      this.claimData.getId(),
      this.claimData.getWithOutId()
    );
    this.isLoadingClaimItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'reclamos',
      });
    }
  }
}
