import { Component, Vue } from 'vue-property-decorator';
import { InterestGroupPersonalInformation } from '../../interfaces';
import { fetchSectorTypes } from '../../services';
import { fetchLocalities } from '@/app/shared/services';

import { vxm } from '@/app/app-state';

@Component
export default class InterestGroupFormSecondStepComponent extends Vue {
  private sectors: string[];
  private localities: object[];
  private countries = vxm.interestGroup.countries;
  private personalInformation: InterestGroupPersonalInformation;

  constructor() {
    super();
    this.sectors = fetchSectorTypes();
    this.personalInformation =
      vxm.interestGroup.interestGroupSelected.personalInformation;
    this.localities = fetchLocalities();
    this.personalInformation.country = '152'; // Patch for countries api error
  }

  getUbicationByLocality(locality: string) {
    const localities = fetchLocalities();
    const localityFound = localities.find(local => local.localidad == locality);

    this.personalInformation.province = localityFound?.provincia || '';
    this.personalInformation.commune = localityFound?.comuna || '';
    this.personalInformation.region = localityFound?.region || '';
  }
}
