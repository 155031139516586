const fetchUnits = () => {
  const units = [
    'Un (Unidad)',
    'Lt (Litro)',
    'Mm (Milímetro)',
    'Cm (Centrímetro)',
    'Cm3 (Centímetro Cúbico)',
    'P (Pulgada)',
    'M (Metro)',
    'M2 (Metro Cuadrado)',
    'M3 (Metro Cúbico)',
    'Km (Kilómetro)',
    'Mi (Milla)',
    'Gr (Gramo)',
    'Kg (Kilógramo)',
    'Lb (Libra)',
    'T° (Temperatura)',
    '°C (Grado Celcius)',
    '°F (Grado Farenheit)',
    'S (Segundos)',
    'Min (Minutos)',
    'Hr (Horas)',
    'D (Días)',
    'Ms (Mes)',
    'An (Años)',
    'AH (Amperios)',
    'V (Voltaje)',
    'Ton (Tonelada)',
    'Ha (Hectárea)',
    'Kb (Kilobyte)',
    'Mb (Megabyte)',
    'Gb (Gigabyte)',
    'Tb (Terabyte)',
  ];

  return units;
};

export { fetchUnits };
