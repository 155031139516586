
import { fetchUnits } from '@/app/operational-tasks/shared/services';
import Vue from 'vue';
export default Vue.extend({
  name: 'DateRange',
  props: {
    dateData: {
      type: Object,
      default: () => ({
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        measureUnit: '',
        quantity: 0,
      }),
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    units: [] as string[],
    showEditUnitsModal: false,
    localDateData: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      measureUnit: '',
      quantity: 0,
    } as any,
  }),
  watch: {
    dateData() {
      this.localDateData = JSON.parse(JSON.stringify(this.dateData));
    },
  },
  methods: {
    editUnits() {
      this.showEditUnitsModal = true;
    },
    confirmEditUnits() {
      this.$emit('updateDateData', this.localDateData);
      this.showEditUnitsModal = false;
    },
    cancelEditUnits() {
      this.localDateData = JSON.parse(JSON.stringify(this.dateData));
      this.showEditUnitsModal = false;
    },

    formatUnit(value: string) {
      const unit = value.split(' ');

      return unit[0];
    },
  },
  beforeMount() {
    this.units = fetchUnits();
    this.localDateData = JSON.parse(JSON.stringify(this.dateData));
  },
});
