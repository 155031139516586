
import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';
import Vue from 'vue';
export default Vue.extend({
  name: 'DateRange',
  props: {
    dateData: {
      type: Object,
      default: () => ({
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        measureUnit: '',
        quantity: 0,
      }),
    },
    dashed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showEditDateModal: false,
    showEditTimeModal: false,
    modalDate: '',
    modalTime: '',
    localDateData: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      measureUnit: '',
      quantity: 0,
    } as any,
    dateDataType: 'start',
  }),
  watch: {
    dateData() {
      this.localDateData = JSON.parse(JSON.stringify(this.dateData));
    },
  },
  methods: {
    editDate(value: string) {
      this.showEditDateModal = true;
      this.dateDataType = value;
      this.modalDate = this.localDateData[`${value}Date`];
    },
    confirmEditDate() {
      this.localDateData[`${this.dateDataType}Date`] = this.modalDate;
      this.$emit('updateDateData', this.localDateData);
      this.showEditDateModal = false;
    },
    cancelEditDate() {
      this.modalDate = '';
      this.showEditDateModal = false;
    },
    editTime(value: string) {
      this.showEditTimeModal = true;
      this.dateDataType = value;
      this.modalTime = this.localDateData[`${value}Time`];
    },
    confirmEditTime() {
      this.localDateData[`${this.dateDataType}Time`] = this.modalTime;
      this.$emit('updateDateData', this.localDateData);
      this.showEditTimeModal = false;
    },
    cancelEditTime() {
      this.modalTime = '';
      this.showEditTimeModal = false;
    },

    formatDate(date: string, type: string) {
      if (date == '') return `Fecha ${type}`;

      const newDate = addDays(new Date(date), 1);

      const day = format(newDate, 'dd', {
        locale: es,
      });
      const month = format(newDate, 'MMM', {
        locale: es,
      });

      return `${day} ${month}`;
    },

    formatTime(time: string, type: string) {
      if (time == '') return `Hora ${type}`;

      const newTime = new Date(`2010-10-10 ${time}`);

      const formatTime = format(newTime, 'h:mm');
      const ampm = format(newTime, 'a');

      return `${formatTime} ${ampm}`;
    },
  },
  beforeMount() {
    this.localDateData = JSON.parse(JSON.stringify(this.dateData));
  },
});
