import { Component, Vue } from 'vue-property-decorator';
import {
  InterestGroupActivity,
  InterestGroupGeneralInformation,
} from '../../interfaces';
import {
  fetchBusinessTypes,
  fetchCompanyCategories,
  fetchOrganizationCategories,
  fetchComplainantCategories,
  fetchInterestGroupCategories,
  fetchPrimaryActivitiesWithNames,
} from '../../services';
import { activityByCode, rutMaskTokens } from '../../utils';

import { vxm } from '@/app/app-state';

@Component
export default class InterestGroupFormFirstStepComponent extends Vue {
  private activities: InterestGroupActivity[] = [];
  private categories: string[] = [];
  private businessTypes: string[] = [];
  private businessTypeDisabled = false;
  private companyCategories: string[];
  private organizationCategories: string[];
  private complainantCategories: string[];
  private rutMaskTokens: object;
  private generalInformation: InterestGroupGeneralInformation;

  constructor() {
    super();
    this.generalInformation =
      vxm.interestGroup.interestGroupSelected.generalInformation;
    this.activities = fetchPrimaryActivitiesWithNames();
    this.categories = fetchInterestGroupCategories();
    this.businessTypes = fetchBusinessTypes();
    this.companyCategories = fetchCompanyCategories();
    this.organizationCategories = fetchOrganizationCategories();
    this.complainantCategories = fetchComplainantCategories();
    this.rutMaskTokens = rutMaskTokens;
  }

  get validRUT() {
    const match = this.generalInformation.rut.match(
      /\b(\d{1,3}(?:(\.?)\d{3}){2}(-?)[\dkK])\b/
    );

    if (this.generalInformation.rut == '') return true;

    if (!match) return false;

    if (match[0] == this.generalInformation.rut) {
      return true;
    } else {
      return false;
    }
  }

  mounted() {
    if (this.generalInformation.primaryActivity)
      this.onPrimaryActivityChange(
        parseInt(this.generalInformation.primaryActivity.code)
      );
    if (this.generalInformation.secondaryActivity)
      this.onSecondaryActivityChange(
        parseInt(this.generalInformation.secondaryActivity.code)
      );
  }

  onPrimaryActivityChange(code: number) {
    const activitySelected = activityByCode(code);
    this.generalInformation.primaryActivity = activitySelected;
  }

  onSecondaryActivityChange(code: number) {
    const activitySelected = activityByCode(code);
    this.generalInformation.secondaryActivity = activitySelected;
  }

  onInterestGroupTypeChange(type: string) {
    if (type == 'Sopraval' || type == 'Agrosuper') {
      this.generalInformation.category.businessType = 'Persona Natural';
      this.generalInformation.category.complainantCategory = 'No Aplica';
      this.businessTypeDisabled = true;
    }
    this.businessTypeDisabled = false;
  }

  onInterestBusinessTypeChange(type: string) {
    if (type == 'Persona Natural') {
      this.generalInformation.category.companyCategory = 'No Aplica';
    }
  }
}
