import { httpClient } from '@/app/shared/services';

const login = (user: any) => {
  const loginApi = '/users/authentication';

  return httpClient
    .post(loginApi, {
      rut: user.rut,
      password: user.password,
    })
    .then(res => {
      if (res.data.res.token) {
        localStorage.setItem('user', JSON.stringify(res.data.res.token));
      }

      return res.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

export { login, logout };
