import { vxm } from '@/app/app-state';
import { UserAuthenticated } from '@/app/users/shared/models';
import { format } from 'date-fns';

import {
  AssignmentData,
  AssociatedAnswer,
  ClaimData,
  CommunicationAndClosing,
  ComplainantData,
  LearnedLessons,
  RegistrationData,
} from '../interfaces';
import { Answer } from './answer.model';

export class Claim {
  _id?: string;
  code?: string;
  finalized = false;
  claimStage = 'Registro';
  complainantData: ComplainantData = {
    complainantPerson: {
      interestGroupId: '',
      name: '',
      rut: '',
      primaryContact: '',
      secondaryContact: '',
      address: '',
      email: '',
      organization: '',
      occupation: '',
      charge: '',
    },
  };
  registrationData: RegistrationData = {
    date: format(new Date(), 'yyyy-MM-dd'),
    time: format(new Date(), 'HH:mm'),
    locality: '',
    commune: '',
    province: '',
    region: '',
    zone: '',
    receiver: '',
    receptionType: '',
    title: '',
    description: '',
    smell: false,
    smellLevel: '',
    offenseLevel: '',
    strategicAspects: [],
    estimatedResponse: {
      appetite: 0,
      impact: 0,
      probability: 0,
      classification: 'Baja',
      priority: 'Regular',
      days: 3,
      date: format(new Date(), 'yyyy-MM-dd'),
    },
  };
  assignmentData: AssignmentData = {
    responsiblePerson: {
      interestGroupId: '',
      name: '',
      rut: '',
      charge: '',
      interestGroupName: '',
      email: '',
      zone: '',
      operationalInfrastructure: '',
      operationalUnit: '',
      sector: '',
      area: '',
      claimType: '',
      causerType: '',
    },
    earlyResponse: {
      id: '',
      content: '',
    },
  };
  associatedAnswers: AssociatedAnswer[] = [];
  communicationAndClosing: CommunicationAndClosing = {
    complianceStatus: 'Sin respuesta',
    answerSent: false,
    comments: [],
  };
  learnedLessons: LearnedLessons = {
    verificationList: {
      planification: {
        items: [
          {
            label: 'El Reclamo se respondió en los tiempos establecidos',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Las Tareas Operacionales en la Fecha del Reclamo fueron realizadas adecuadamente',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se identificó correctamente al Responsable de atender el Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label: 'El Reclamo requirió de una asignación de Presupuesto',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se requirió de Medidas Correctivas para entregar una Respuesta al Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
        ],
        comments: '',
      },
      executionAndControl: {
        items: [
          {
            label:
              'Se requirieron cambios en los Procesos Operacionales a raíz de la atención al Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label: 'El Reclamo se respondió en los tiempos establecidos',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se mantuvo actualizada la gestión del Reclamo en todas sus Etapas',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label: 'El reclamo se originó por una acción de un Tercero',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'El Denunciante quedó satisfecho con la Respuesta al Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Los Riesgos identificados a raíz del Reclamo estuvieron controlados y gestionados',
            completed: 'N/A',
            impactLevel: 0,
          },
        ],
        comments: '',
      },
      humanFactors: {
        items: [
          {
            label:
              'El Responsable del Reclamo reportó a un nivel adecuado dentro de la organización',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'El Responsable brindó un Trato Adecuado al Denunciante del Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Existió una buena Comunicación entre los miembros del equipo de área Responsable del reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se cumplieron los Objetivos y Metas definidos por la Gerencia para dar una Eficiente Respuesta al Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Los Recursos Humanos asignados para dar Respuesta al reclamos No estuvieron sobre comprometidos',
            completed: 'N/A',
            impactLevel: 0,
          },
        ],
        comments: '',
      },
      general: {
        items: [
          {
            label:
              'Las estimaciones de Costos y Presupuestos necesarios para atender el Reclamo fueron precisas',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se requirió de Cambios en los Procesos Internos producto de la Atención al Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se generó un Conflicto Social a raíz del levantamiento del Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Las Necesidades y Requerimientos del Denunciante del Reclamo quedaron satisfechas',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Existió intervención de alguna Autoridad en Competencia en la Respuesta del Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
          {
            label:
              'Se requirió de la Entrega de Información a la Autoridad Competente producto del levantamiento del Reclamo',
            completed: 'N/A',
            impactLevel: 0,
          },
        ],
        comments: '',
      },
    },
  };

  constructor(data?: ClaimData) {
    if (data) Object.assign(this, data);
    if (this.registrationData.receiver == '') {
      this.registrationData.receiver = (vxm.authentication
        .authenticatedUser as UserAuthenticated).getId();
    }
  }

  getId() {
    return this._id || '';
  }

  getWithOutId() {
    const data = { ...this };
    delete data._id;

    return data;
  }

  updateAssociatedAnswers(answers: Answer[]) {
    this.associatedAnswers = [];
    answers.map((answer, idx) => {
      this.associatedAnswers.push({
        id: this.getId(),
        number: idx + 1,
        status: answer.status,
        creationDate: answer.creationDate,
        creationTime: answer.creationTime,
        modificationDate: answer.modificationDate,
        modificationTime: answer.modificationTime,
      });
    });
  }

  getApprovedAnswers() {
    const approvedAnswers = this.associatedAnswers.filter(answer => {
      return answer.status == 'Aprobada';
    });

    return approvedAnswers ? approvedAnswers : [];
  }

  addCommunicationAndClosing(data: CommunicationAndClosing) {
    this.communicationAndClosing = data;
  }

  updateClaimStage() {
    if (this.finalized) return (this.claimStage = 'Finalizado');

    if (typeof this.communicationAndClosing != 'undefined') {
      if (this.communicationAndClosing.complianceStatus != 'Sin respuesta')
        return (this.claimStage = 'Lecciones Aprendidas');
    }

    if (typeof this.associatedAnswers != 'undefined') {
      const answers = this.associatedAnswers.filter(
        (answer: any) => answer.status == 'Aprobada'
      );
      if (answers.length > 0)
        return (this.claimStage = 'Comunicación y cierre');
    }

    if (this.assignmentData.responsiblePerson.interestGroupId != '')
      return (this.claimStage = 'Respuesta');
  }
}
