import { vxm } from '@/app/app-state';
import { InterestGroupBackendData } from '@/app/interest-groups/shared/interfaces';
import { UserAuthenticated } from '@/app/users/shared/models';

import {
  DescriptiveInformation,
  OperationalTaskData,
  TaskInformation,
  TaskStatusInformation,
} from '../interfaces';

import { fetchUnits } from '../services';

export class OperationalTask {
  _id?: string;
  interestGroupId = '';
  taskInformation: TaskInformation = {
    area: '',
    submanagement: '',
    management: '',
    taskType: '',
    taskCategory: '',
    task: '',
    activity: '',
  };
  descriptiveInformation: DescriptiveInformation = {
    criticality: 'Sin criticidad',
    externality: 'Sin externalidad',
    strategicAspect: 'Sin aspectos estratégicos',
    description: 'Sin descripción',
  };
  taskStatus: TaskStatusInformation = {
    estimatedData: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      measureUnit: fetchUnits()[0],
      quantity: 0,
    },
    realData: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      measureUnit: fetchUnits()[0],
      quantity: 0,
    },
    progress: 0,
    state: '',
    observations: '',
  };

  constructor(data?: OperationalTaskData) {
    if (data) Object.assign(this, data);
    if (this.interestGroupId == '') {
      this.interestGroupId = (vxm.authentication
        .authenticatedUser as UserAuthenticated).interestGroupId;
    }
  }

  getId() {
    return this._id || '';
  }

  getWithOutId() {
    const data = { ...this };
    delete data._id;

    return data;
  }

  getManagementInformatiom(interestGroupData: InterestGroupBackendData) {
    if (this.taskInformation.area == '') {
      this.taskInformation.management = interestGroupData.gerencia;
      this.taskInformation.submanagement = interestGroupData.subgerencia;
      this.taskInformation.area = interestGroupData.area;
    }
  }
}
