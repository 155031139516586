const fetchOperationalInfras = () => {
  const operationalInfras = [
    {
      name: 'Granja',
      operationalUnits: ['Reproductoras', 'Engorda', 'Pre-Engorda'],
    },
    {
      name: 'Centro Acopio Guano',
      operationalUnits: ['Mariano Alfonso'],
    },
    {
      name: 'Planta Faenadora',
      operationalUnits: ['Planta Faenadora'],
    },
    {
      name: 'Planta Alimentos',
      operationalUnits: ['Sopraval', 'Agrosuper'],
    },
    {
      name: 'Sistema de Tratamiento',
      operationalUnits: ['Sistema de Tratamiento'],
    },
  ];

  return operationalInfras;
};

export { fetchOperationalInfras };
