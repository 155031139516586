import { vxm } from '@/app/app-state';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LoginComponent extends Vue {
  private loginDataForm = {
    rut: '',
    password: '',
  };
  private isLoginLoading = false;

  async login() {
    this.isLoginLoading = true;
    const response = await vxm.authentication.login(this.loginDataForm);
    this.isLoginLoading = false;

    if (response.status != 9000) {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.$router.push({
        name: 'dashboard',
      });
    }
  }
}
