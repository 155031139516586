<template>
  <div class="x-select__container">
    <label class="x-select__label">
      {{ label }}
    </label>
    <v-select
      class="x-select"
      :options="paginated"
      :filterable="false"
      :label="optionLabel"
      :multiple="multiple"
      :noDrop="noDrop"
      :taggable="taggable"
      :clearable="false"
      :createOption="createOption"
      @open="onOpen"
      @close="onClose"
      @search="query => (search = query)"
      :value="value"
      @input="$emit('input', $event)"
    >
      <template #list-footer>
        <li ref="load" class="loader" v-show="hasNextPage">
          Loading more options...
        </li>
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <i class="bx bx-chevron-down"></i>
        </span>
      </template>
      <template #no-options="{}">
        No hay opciones disponibles
      </template>
      <div>
        Label
      </div>
    </v-select>
  </div>
</template>
<script>
export default {
  name: 'InfiniteScroll',
  props: [
    'options',
    'value',
    'optionLabel',
    'label',
    'multiple',
    'noDrop',
    'taggable',
    'createOption',
  ],
  data: () => ({
    observer: null,
    limit: 5,
    search: '',
    attributes: {
      ref: 'openIndicator',
      role: 'presentation',
      class: 'vs__open-indicator',
    },
  }),
  mounted() {
    /**
     * You could do this directly in data(), but since these docs
     * are server side rendered, IntersectionObserver doesn't exist
     * in that environment, so we need to do it in mounted() instead.
     */
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  computed: {
    filtered() {
      if (this.optionLabel) {
        return this.options.filter(opt =>
          opt[this.optionLabel].includes(this.search)
        );
      } else {
        return this.options.filter(opt => opt.includes(this.search));
      }
    },
    paginated() {
      return this.filtered.slice(0, this.limit);
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length;
    },
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    input(e) {
      this.$emit('input', e);
      this.$emit('change', e);
    },
  },
};
</script>
