import { UserAuthenticated } from '@/app/users/shared/models';
import { DecodeJWT } from './decode-jwt.util';

export const getUserFromLocalStorage = (): UserAuthenticated | null => {
  const localStorageUserData = localStorage.getItem('user');

  if (localStorageUserData) {
    const decodedJWT = DecodeJWT(localStorageUserData);

    if (decodedJWT) {
      return new UserAuthenticated(decodedJWT);
    }

    return decodedJWT;
  }

  return null;
};
