import UsersComponent from './users.component.vue';
import UserItemComponent from './user-item/user-item.component.vue';
import UserListComponent from './user-list/user-list.component.vue';

export const userRoutes = [
  {
    path: '/usuarios',
    component: UsersComponent,
    children: [
      {
        path: 'crear',
        name: 'crear-usuario',
        component: UserItemComponent,
      },
      {
        path: 'editar/:id',
        name: 'editar-usuario',
        component: UserItemComponent,
      },
      {
        path: '',
        name: 'usuarios',
        component: UserListComponent,
      },
    ],
  },
];
