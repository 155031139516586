const fetchReceptionTypes = () => {
  const types = [
    'Presencial',
    'Llamado Telefónico',
    'Carta',
    'Correo Electronico',
    'RS_Whatsapp',
    'RS_Facebook',
    'RS_Twitter',
    'RS_Instagram',
    'Web denuncia',
  ];

  return types;
};

export { fetchReceptionTypes };
