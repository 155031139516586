/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator';
import {
  InterestGroupBackendData,
  InterestGroupData,
  PositionGI,
} from '../shared/interfaces';
import { InterestGroup } from '../shared/models';
import {
  deleteInterestGroup,
  editInterestGroupPosition,
  // fetchCountries,
  fetchPDFInterestGroupByFilter,
  fetchSheetInterestGroupByFilter,
  sendSheetToEmail,
} from '../shared/services';
import { generateCode, emptyInterestGroupBackendJson } from '../shared/utils';

import InterestGroupFormComponent from '../shared/components/interest-group-form-component/interest-group-form-component.vue';

import { vxm } from '@/app/app-state';
import { UserAuthenticated } from '@/app/users/shared/models';

@Component({
  components: {
    InterestGroupForm: InterestGroupFormComponent,
  },
})
export default class InterestGroupListComponent extends Vue {
  private interestGroupSelected: InterestGroupData;
  private interestGroupBackendSelected: InterestGroupBackendData;
  private page = 1;
  private max = 25;
  private editDialogState = false;
  private mapDialogState = false;
  private claimsDialogState = false;
  private mapCenterPosition: PositionGI = {
    lat: 0.0,
    lng: 0.0,
    zoom: 17,
  };
  private interestGroupPosition: PositionGI = {
    lat: 0.0,
    lng: 0.0,
    zoom: 17,
  };
  private isInterestGroupTableLoading = false;
  private placeSelected: any = {};
  private dialogMode: string;
  private searchOption = '';
  private searchText = '';
  private searchFilters = {
    query: '',
    column: 'codigo',
  };
  $vs!: {
    loading: any;
  };

  private showDeleteInterestGroupModal = false;
  private isDeleteInterestGroupLoading = false;
  private isEmailSending = false;
  private isExportModalOpen = false;
  private emailToSend = '';

  private interestGroupColumns = [
    {
      title: 'Código',
      dataIndex: 'codigo',
    },
    {
      title: 'Matriz de influencia',
      dataIndex: 'promedio_final',
      scopedSlots: { customRender: 'promedio_final' },
    },
    {
      title: 'Razón Social',
      dataIndex: 'razon_social',
    },
    {
      title: 'RUT',
      dataIndex: 'rut',
      width: 90,
    },
    {
      title: 'Dirección',
      dataIndex: 'direccion',
    },
    {
      title: 'Comuna',
      dataIndex: 'comuna_personal',
    },
    {
      title: 'Email Primario',
      dataIndex: 'email_primario',
    },
    {
      title: 'Contacto Primario',
      dataIndex: 'contacto_primario',
    },
    {
      title: 'Tipo de GI',
      dataIndex: 'grupointeres',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  private claimColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Fecha de reclamo',
      dataIndex: 'registrationData.date',
    },
    {
      title: 'Hora de reclamo',
      dataIndex: 'registrationData.time',
    },
    {
      title: 'Título',
      dataIndex: 'registrationData.title',
    },
    {
      title: 'Tipo Reclamo',
      dataIndex: 'assignmentData.responsiblePerson.claimType',
    },
    {
      title: 'Tipo Causante',
      dataIndex: 'assignmentData.responsiblePerson.causerType',
    },
    {
      title: 'Clasificación',
      dataIndex: 'registrationData.estimatedResponse.classification',
    },
    {
      title: 'Prioridad',
      dataIndex: 'registrationData.estimatedResponse.priority',
    },
    {
      title: 'Comuna',
      dataIndex: 'registrationData.commune',
    },
    {
      title: 'Etapa',
      dataIndex: 'step',
    },
  ];

  constructor() {
    super();
    this.interestGroupSelected = InterestGroup.transformBackendData(
      emptyInterestGroupBackendJson
    );
    this.interestGroupBackendSelected = emptyInterestGroupBackendJson;
    this.dialogMode = vxm.interestGroup.interestDialogMode;
  }

  private actionsMenuItems = [
    ...(this.user.can('edit', 'interestGroups')
      ? [
          {
            action: this.editInterestGroup,
            text: 'Editar',
            icon: 'edit',
          },
        ]
      : []),
    ...(this.user.can('delete', 'interestGroups')
      ? [
          {
            action: this.openDeleteInterestGroupModal,
            text: 'Eliminar',
            icon: 'delete',
          },
        ]
      : []),
    {
      divider: true,
    },
    ...(this.user.can('read', 'interestGroups')
      ? [
          {
            action: this.openMapDialog,
            text: 'Ver Mapa',
            icon: 'environment',
          },
        ]
      : []),
    ...(this.user.can('read', 'interestGroups')
      ? [
          {
            action: this.openClaimsListDialog,
            text: 'Reclamos',
            icon: 'file-search',
          },
        ]
      : []),
  ];

  get user() {
    const authenticatedUser = vxm.authentication
      .authenticatedUser as UserAuthenticated;
    return authenticatedUser;
  }

  get userCanCreate() {
    return this.user.can('create', 'claims');
  }

  get interestGroups() {
    return vxm.interestGroup.interestGroupListFiltered;
  }

  get interestGroupSelectedClaim() {
    return vxm.interestGroup.interestGroupSelected;
  }

  get claimsByInterestGroup() {
    return (
      vxm.claims.claimList.filter(
        (claim: any) =>
          claim.complainantData.complainantPerson.interestGroupId ==
          this.interestGroupSelectedClaim.id
      ) || []
    );
  }

  get filterOptions() {
    return this.interestGroupColumns.filter(
      column => column.dataIndex != 'actions'
    );
  }

  async setSearchFilterValues() {
    this.isInterestGroupTableLoading = true;
    await vxm.interestGroup.fetchAllByFilter({
      column: this.searchOption,
      query: this.searchText,
    });
    this.isInterestGroupTableLoading = false;
  }

  async downloadPDFByFilters() {
    this.isInterestGroupTableLoading = true;
    const res = await fetchPDFInterestGroupByFilter(
      this.searchOption,
      this.searchText
    );
    window.open(res.uri, '_blank');
    this.isInterestGroupTableLoading = false;
  }

  async downloadSheetByFilters() {
    this.isInterestGroupTableLoading = true;
    const res = await fetchSheetInterestGroupByFilter(
      this.searchOption,
      this.searchText
    );
    window.open(res.uri, '_blank');
    this.isInterestGroupTableLoading = false;
  }

  async sendSheetToEmail() {
    this.isEmailSending = true;
    await sendSheetToEmail(
      this.searchOption,
      this.searchText,
      this.emailToSend
    );
    this.isEmailSending = false;
  }

  filterList(list: object[], option: string, value: string) {
    let filteredList = list;

    if (option == '') {
      return filteredList;
    } else {
      filteredList = list.filter((item: any) => {
        if (item[option] || item[option] == 0) {
          return String(item[option])
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      });

      return filteredList;
    }
  }

  async mounted() {
    this.isInterestGroupTableLoading = true;
    await vxm.interestGroup.fetchAll();
    await vxm.claims.getClaimList();
    // vxm.interestGroup.countries = await fetchCountries();
    this.isInterestGroupTableLoading = false;
  }

  editInterestGroup(interestGroup: InterestGroupBackendData) {
    vxm.interestGroup.interestDialogMode = 'Editar';
    vxm.interestGroup.interestGroupSelected = InterestGroup.transformBackendData(
      interestGroup
    );
    this.editDialogState = true;
  }

  openMapDialog(interestGroup: InterestGroupBackendData) {
    vxm.interestGroup.interestGroupSelected = InterestGroup.transformBackendData(
      interestGroup
    );
    this.interestGroupPosition =
      vxm.interestGroup.interestGroupSelected.laborInformation.registeredWorker.position;
    this.updateMapCenter();
    this.mapDialogState = true;
  }

  createInterestGroup() {
    vxm.interestGroup.interestDialogMode = 'Nuevo';
    vxm.interestGroup.interestGroupSelected = InterestGroup.transformBackendData(
      emptyInterestGroupBackendJson
    );
    vxm.interestGroup.interestGroupSelected.code = generateCode(
      vxm.interestGroup.interestGroupListFiltered.length + 1
    );
    this.editDialogState = true;
  }

  closeDialog() {
    this.editDialogState = false;
  }

  onPlaceChange(place: object) {
    this.placeSelected = place;
  }

  onMarkerDrag(position: { latLng: { lat(): number; lng(): number } }) {
    this.interestGroupPosition.lat = position.latLng.lat();
    this.interestGroupPosition.lng = position.latLng.lng();
  }

  submitPlace() {
    if (Object.keys(this.placeSelected).length !== 0) {
      this.interestGroupPosition.lat = this.placeSelected.geometry
        ? this.placeSelected.geometry.location.lat()
        : 0;
      this.interestGroupPosition.lng =
        this.placeSelected.geometry.location.lng() || 0;
      this.updateMapCenter();
    }
  }

  updateMapCenter() {
    this.mapCenterPosition = JSON.parse(
      JSON.stringify(this.interestGroupPosition)
    );
  }

  async saveInterestGroupPositionToStore() {
    const positionGi = {
      ...this.interestGroupPosition,
      id: vxm.interestGroup.interestGroupSelected.id,
    };
    const loading = this.$vs.loading();

    await editInterestGroupPosition(positionGi);
    await vxm.interestGroup.fetchAll();

    loading.close();
    this.mapDialogState = false;
  }

  openDeleteInterestGroupModal(interestGroup: InterestGroupBackendData) {
    this.interestGroupBackendSelected = interestGroup;
    this.showDeleteInterestGroupModal = true;
  }

  async deleteInterestGroup() {
    this.isDeleteInterestGroupLoading = true;
    if (this.interestGroupBackendSelected._id) {
      const response = await deleteInterestGroup(
        this.interestGroupBackendSelected._id
      );
      if (response.status == 'Failed') {
        this.$message.error(response.msg);
      } else {
        this.$message.success(response.msg);
      }
      this.isDeleteInterestGroupLoading = false;
      this.closeDeleteInterestGroupModal();
      await vxm.interestGroup.fetchAll();
    }
  }

  closeDeleteInterestGroupModal() {
    this.interestGroupBackendSelected = emptyInterestGroupBackendJson;
    this.showDeleteInterestGroupModal = false;
  }

  openClaimsListDialog(interestGroup: InterestGroupBackendData) {
    this.claimsDialogState = true;
    vxm.interestGroup.interestGroupSelected = InterestGroup.transformBackendData(
      interestGroup
    );
  }

  colorByAverage(average: string) {
    const averageFloat = parseFloat(average);
    if (averageFloat >= 0 && averageFloat < 50) return '';
    if (averageFloat >= 50 && averageFloat < 80) return 'warn';
    return 'danger';
  }
}
