import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { dashboardRoutes } from './dashboard';
import { interestGroupRoutes } from './interest-groups';
import { claimRoutes } from './claims';
import { userRoutes } from './users';
import { operationalTaskRoutes } from './operational-tasks';
import { unauthorizedRoutes } from './unauthorized';

import LoginComponent from './authentication/login/login.component.vue';
import { vxm } from './app-state';
import { UserAuthenticated } from './users/shared/models';

Vue.use(VueRouter);

const appRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'login',
    component: LoginComponent,
  },
];

const routes = [
  ...appRoutes,
  ...dashboardRoutes,
  ...interestGroupRoutes,
  ...claimRoutes,
  ...userRoutes,
  ...operationalTaskRoutes,
  ...unauthorizedRoutes,
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loginPath = '/';
  const dashboardPath = '/dashboard';
  const unauthorizedPath = '/no-autorizado';
  const publicPages = ['/'];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !vxm.authentication.status.authenticated) {
    next(loginPath);
  } else {
    const user = vxm.authentication.authenticatedUser as UserAuthenticated;

    if (to.path == loginPath && vxm.authentication.status.authenticated)
      next(dashboardPath);

    // List
    if (to.name == 'grupos-interes' && !user.can('list', 'claims'))
      next(unauthorizedPath);
    if (to.name == 'reclamos' && !user.can('list', 'claims'))
      next(unauthorizedPath);
    if (
      to.name == 'tareas-operacionales' &&
      !user.can('list', 'operationalTasks')
    )
      next(unauthorizedPath);
    if (to.name == 'usuarios' && !user.can('list', 'users'))
      next(unauthorizedPath);

    // Edit
    if (to.name == 'editar-reclamo' && !user.can('edit', 'claims'))
      next(unauthorizedPath);
    if (to.name == 'reclamo-respuestas' && !user.can('edit', 'claims'))
      next(unauthorizedPath);
    if (to.name == 'reclamo-crear-respuesta' && !user.can('edit', 'claims'))
      next(unauthorizedPath);
    if (to.name == 'reclamo-respuesta' && !user.can('edit', 'claims'))
      next(unauthorizedPath);
    if (to.name == 'reclamo-comunicacion-cierre' && !user.can('edit', 'claims'))
      next(unauthorizedPath);
    if (
      to.name == 'reclamo-lecciones-aprendidas' &&
      !user.can('edit', 'claims')
    )
      next(unauthorizedPath);
    if (
      to.name == 'editar-tarea-operacional' &&
      !user.can('edit', 'operationalTasks')
    )
      next(unauthorizedPath);
    if (to.name == 'editar-usuario' && !user.can('edit', 'users'))
      next(unauthorizedPath);

    // Create
    if (to.name == 'crear-reclamo' && !user.can('create', 'claims'))
      next(unauthorizedPath);
    if (
      to.name == 'crear-tarea-operacional' &&
      !user.can('create', 'operationalTasks')
    )
      next(unauthorizedPath);
    if (to.name == 'crear-usuario' && !user.can('create', 'users'))
      next(unauthorizedPath);

    next();
  }
});

export default router;
