import axios from 'axios';

const config = {
  baseURL: 'https://sopraval-escucha-backend-dev.onrender.com/api/v2',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const httpClient = axios.create(config);

export { httpClient };
