import { Component, Vue } from 'vue-property-decorator';

import { vxm } from '@/app/app-state';
import { OperationalTaskData } from '../shared/interfaces';
import { OperationalTask } from '../shared/models';
import { deleteOperationalTask } from '../shared/services';
import { UserAuthenticated } from '@/app/users/shared/models';

@Component
export default class OperationalTaskListComponent extends Vue {
  private isOperationalTasksTableLoading = false;
  private operationalTaskColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
    },
    {
      title: 'Subgerencia',
      dataIndex: 'taskInformation.submanagement',
    },
    {
      title: 'Area',
      dataIndex: 'taskInformation.area',
    },
    {
      title: 'Tipo Tarea',
      dataIndex: 'taskInformation.taskType',
    },
    {
      title: 'Categoría Tarea',
      dataIndex: 'taskInformation.taskCategory',
    },
    {
      title: 'Tarea',
      dataIndex: 'taskInformation.task',
    },
    {
      title: 'Actividad',
      dataIndex: 'taskInformation.activity',
    },
    {
      title: 'Acciones',
      dataIndex: 'actions',
      scopedSlots: { customRender: 'actions' },
    },
  ];

  private showDeleteOperationalTaskModal = false;
  private operationalTaskSelected: OperationalTask = new OperationalTask();
  private isDeleteOperationalTaskLoading = false;

  private actionsMenuItems = [
    ...(this.user.can('edit', 'operationalTasks')
      ? [
          {
            action: this.editOperationalTask,
            text: 'Editar',
            icon: 'edit',
          },
        ]
      : []),
    ...(this.user.can('delete', 'operationalTasks')
      ? [
          {
            action: this.openDeleteOperationalTaskModal,
            text: 'Eliminar',
            icon: 'delete',
          },
        ]
      : []),
  ];

  get user() {
    const authenticatedUser = vxm.authentication
      .authenticatedUser as UserAuthenticated;
    return authenticatedUser;
  }

  get userCanCreate() {
    return this.user.can('create', 'operationalTasks');
  }

  get operationalTasks() {
    return vxm.operationalTasks.operationalTaskList;
  }

  async mounted() {
    await this.getOperationalTaskList();
  }

  async getOperationalTaskList() {
    this.isOperationalTasksTableLoading = true;
    await vxm.operationalTasks.getOperationalTaskList();
    this.isOperationalTasksTableLoading = false;
  }

  async deleteOperationalTask() {
    this.isDeleteOperationalTaskLoading = true;
    const response = await deleteOperationalTask(
      this.operationalTaskSelected.getId()
    );
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
    }
    this.isDeleteOperationalTaskLoading = false;
    this.closeDeleteOperationalTaskModal();
    await this.getOperationalTaskList();
  }

  openDeleteOperationalTaskModal(operationalTask: OperationalTaskData) {
    this.operationalTaskSelected = new OperationalTask(operationalTask);
    this.showDeleteOperationalTaskModal = true;
  }

  closeDeleteOperationalTaskModal() {
    this.operationalTaskSelected = new OperationalTask();
    this.showDeleteOperationalTaskModal = false;
  }

  editOperationalTask(operationalTask: OperationalTaskData) {
    const operationalTaskSelected = new OperationalTask(operationalTask);

    this.$router.push({
      name: 'editar-tarea-operacional',
      params: {
        id: operationalTaskSelected.getId(),
      },
    });
  }
}
