import { Component, Vue } from 'vue-property-decorator';
import {
  LineChart,
  PieChart,
  BarChart,
  BubbleChart,
} from '@/app/shared/components/charts';
import { vxm } from '../app-state';
import { Claim } from '../claims/shared/models';
import { getInterestGroupRanking } from '../interest-groups/shared/services';
import { getYear } from 'date-fns';

@Component({
  components: {
    LineChart,
    PieChart,
    BarChart,
    BubbleChart,
  },
})
export default class Dashboard extends Vue {
  private monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  private claimsByLocalityModal = false;
  private claimsByLocalityModalTitle = '';

  private claimsDataFilteredByChart: object[] = [];
  private claimColumns = [
    {
      title: 'Código',
      dataIndex: 'code',
      scopedSlots: { customRender: 'code' },
    },
    {
      title: 'Fecha de reclamo',
      dataIndex: 'registrationData.date',
      className: 'centered',
    },
    // {
    //   title: 'Hora de reclamo',
    //   dataIndex: 'registrationData.time',
    //   className: 'centered',
    // },
    {
      title: 'Título',
      dataIndex: 'registrationData.title',
    },
    {
      title: 'Denunciante',
      dataIndex: 'complainantData.complainantPerson.name',
    },
    {
      title: 'Tipo Reclamo',
      dataIndex: 'assignmentData.responsiblePerson.claimType',
      className: 'centered',
    },
    {
      title: 'Tipo Causante',
      dataIndex: 'assignmentData.responsiblePerson.causerType',
      className: 'centered',
    },
    {
      title: 'Clasificación',
      dataIndex: 'registrationData.estimatedResponse.classification',
      className: 'centered',
    },
    {
      title: 'Localidad',
      dataIndex: 'registrationData.locality',
      className: 'centered',
    },
    // {
    //   title: 'Comuna',
    //   dataIndex: 'registrationData.commune',
    //   className: 'centered',
    // },
    {
      title: 'Etapa',
      dataIndex: 'claimStage',
      className: 'centered',
    },
  ];

  private selectedYear = 'Todos';
  private selectedCompany = 'Todos';
  private isListsDataLoading = false;
  private isChartsDataLoading = false;
  private months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Setiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  private defaultColors = [
    '#3366CC',
    '#DC3912',
    '#FF9900',
    '#109618',
    '#990099',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#B82E2E',
    '#316395',
    '#994499',
    '#22AA99',
    '#AAAA11',
    '#6633CC',
    '#E67300',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
  ];
  private claimsByLocalityChartData = {};
  private claimsByLocalityChartOptions = {};
  private claimsByZoneChartData = {};
  private claimsByZoneChartOptions = {};
  private allClaimsByZoneChartData = {};
  private allClaimsByZoneChartOptions = {};
  private claimsByMonthChartData = {};
  private claimsByMonthChartOptions = {};
  private claimsByLocalityBarChartData = {};
  private claimsByZoneBarChartData = {};
  private allClaimsByZoneBarChartData = {};
  private allClaimsByZoneBarChartDataOptions = {};
  private claimsByMonthBarChartData = {};
  private claimsByTypeChartData = {};
  private claimsByTypeChartDataOptions = {};
  private claimsByCauserTypeChartData = {};
  private claimsByCauserTypeChartDataOptions = {};
  private claimsByOperInfraChartData = {};
  private claimsByOperInfraChartDataOptions = {};
  private claimsByOperUnitChartData = {};
  private claimsByOperUnitChartDataOptions = {};
  private claimsByStatusChartData = {};
  private claimsByStatusChartOptions = {};
  private claimsByLocality: object[] = [];
  private interestGroupsByClaims: object[] = [];
  private lineChartTabs = [
    {
      key: 'locality',
      tab: 'Localidad',
    },
    {
      key: 'month',
      tab: 'Mes',
    },
    {
      key: 'zones',
      tab: 'Zona',
    },
    {
      key: 'yearly',
      scopedSlots: { tab: 'anual' },
    },
    {
      key: 'business',
      scopedSlots: { tab: 'empresa' },
    },
  ];
  private lineChartSelectedTab = 'locality';
  private barChartTabs = [
    {
      key: 'type',
      tab: 'Tipo',
    },
    {
      key: 'causerType',
      tab: 'Tipo Causante',
    },
    {
      key: 'operInfra',
      tab: 'Infra Operacional',
    },
    {
      key: 'operUnit',
      tab: 'Unidad Operacional',
    },
  ];
  private barChartSelectedTab = 'type';
  private rankingSelectedTab = 'interestGroup';
  private rankingTabs = [
    {
      key: 'locality',
      tab: 'Localidad',
    },
    {
      key: 'interestGroup',
      tab: 'Grupo Interés',
    },
  ];
  private lineChartClaimsOptions = {
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };
  private chartBarMode = true;

  get companies() {
    return ['Todos', 'Sopraval', 'Agrosuper'];
  }

  get validYears() {
    const startYear = 2020;
    const currentYear = getYear(new Date());

    const validYears = ['Todos'];

    for (let i = startYear; i <= currentYear; i++) {
      validYears.push(i.toString());
    }

    return validYears;
  }

  get claimsQuantity() {
    const claimsByYear = vxm.claims.claimList.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    return claimsByYear.length;
  }

  get answerQuantity() {
    const claimsByYear = vxm.claims.claimList.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const answersByYear = claimsByYear.filter((claim: any) =>
      claim.associatedAnswers.some((answer: any) => answer.status == 'Aprobada')
    );

    return answersByYear.length;
  }

  get interestGroupQuantity() {
    return vxm.interestGroup.interestGroupList.length;
  }

  async mounted() {
    this.isListsDataLoading = true;
    this.claimsByLocalityChartOptions = {
      onClick: this.onClaimsByLocalityChartClick,
    };
    this.claimsByMonthChartOptions = {
      onClick: this.onClaimsByMonthChartClick,
    };
    this.claimsByZoneChartOptions = {
      onClick: this.onClaimsByZoneChartClick,
    };
    this.claimsByTypeChartDataOptions = {
      onClick: this.onClaimsByTypeChartClick,
    };
    this.claimsByCauserTypeChartDataOptions = {
      onClick: this.onClaimsByCauserTypeChartClick,
    };
    this.claimsByOperInfraChartDataOptions = {
      onClick: this.onClaimsByOperInfraTypeChartClick,
    };
    this.claimsByOperUnitChartDataOptions = {
      onClick: this.onClaimsByOperUnitTypeChartClick,
    };
    this.claimsByStatusChartOptions = {
      onClick: this.onClaimsByStatusChartClick,
    };
    this.allClaimsByZoneBarChartDataOptions = {
      onClick: this.onAllClaimsByZoneBarChartData,
    };
    await vxm.claims.getClaimList();
    await vxm.interestGroup.fetchAll();
    this.isListsDataLoading = false;
    await this.updateCharts();
  }

  async updateCharts() {
    this.isChartsDataLoading = true;
    this.claimsByLocality = [];
    this.claimsByLocalityChartData = this.getClaimsByLocalityChartData();
    this.claimsByZoneChartData = this.getClaimsByZoneChartData();
    this.allClaimsByZoneChartData = this.getAllClaimsByZoneChartData();
    this.claimsByStatusChartData = this.getClaimsByStatusChartData();
    this.claimsByMonthChartData = this.getClaimsByMonthChartData();
    this.claimsByTypeChartData = this.getClaimsByTypeChartData();
    this.claimsByCauserTypeChartData = this.getClaimsByCauserTypeChartData();
    this.claimsByOperInfraChartData = this.getClaimsByOperInfraChartData();
    this.claimsByOperUnitChartData = this.getClaimsByOperUnitChartData();
    const interestGroupRanking = await getInterestGroupRanking(
      parseInt(this.selectedYear),
      8
    );
    this.interestGroupsByClaims = interestGroupRanking.res;
    this.isChartsDataLoading = false;
  }

  onClaimsByLocalityChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        this.monthNames[
          parseInt(claim.registrationData.date.slice(5, 7)) - 1
        ] == model.label &&
        claim.registrationData.locality == model.datasetLabel
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByMonthChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle = model.label + ' ' + this.selectedYear;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        this.monthNames[
          parseInt(claim.registrationData.date.slice(5, 7)) - 1
        ] == model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByZoneChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - Zona ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        this.monthNames[
          parseInt(claim.registrationData.date.slice(5, 7)) - 1
        ] == model.label && claim.registrationData.zone == model.datasetLabel
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByTypeChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        claim.assignmentData.responsiblePerson.claimType == model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByCauserTypeChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        claim.assignmentData.responsiblePerson.causerType == model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByOperInfraTypeChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        claim.assignmentData.responsiblePerson.operationalInfrastructure ==
        model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByOperUnitTypeChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      model.label + ' ' + this.selectedYear + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) =>
        claim.assignmentData.responsiblePerson.operationalUnit == model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onClaimsByStatusChartClick(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle =
      'Etapa: ' + model.label + ' (' + this.selectedYear + ')';

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == this.selectedYear
    );

    const claimsByLocalityAndMonth = claimsByYear.filter(
      (claim: any) => claim.claimStage == model.label
    );

    this.claimsDataFilteredByChart = claimsByLocalityAndMonth;

    this.claimsByLocalityModal = true;
  }

  onAllClaimsByZoneBarChartData(event: any, el: any) {
    if (el.length == 0) return;
    const chart = el[0]._chart;
    const datasetIndex = chart.getElementAtEvent(event)[0]._datasetIndex;
    const chartSelected = chart
      .getElementsAtEvent(event)
      .find((el: any) => el._datasetIndex == datasetIndex);
    const model = chartSelected._model;

    this.claimsByLocalityModalTitle = model.label + ' - ' + model.datasetLabel;

    const claims = vxm.claims.claimList;

    const claimsByYear: object[] = claims.filter(
      (claim: any) =>
        claim.registrationData.date.slice(0, 4) == model.datasetLabel
    );

    const claimsByMonth = claimsByYear.filter(
      (claim: any) =>
        this.monthNames[
          parseInt(claim.registrationData.date.slice(5, 7)) - 1
        ] == model.label
    );

    this.claimsDataFilteredByChart = claimsByMonth;

    this.claimsByLocalityModal = true;
  }

  getClaimsByLocalityChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    let localities: string[] = [];
    let tempLocality = '';
    const datasets: object[] = [];
    const datasetsBar: object[] = [];

    claimsByYear.map((claim: any) => {
      if (tempLocality != claim.registrationData.locality) {
        if (claim.registrationData.locality != '')
          localities.push(claim.registrationData.locality);
      }
      tempLocality = claim.registrationData.locality;
    });

    localities = localities.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    localities.map((locality: string, idx: number) => {
      const yearData: number[] = [];

      const claimsByLocality = claimsByYear.filter(
        (claim: any) => claim.registrationData.locality == locality
      );

      this.claimsByLocality.push({
        locality,
        quantity: claimsByLocality.length,
      });

      for (let i = 1; i < 13; i++) {
        yearData.push(this.getNumberOfClaimsByMonth(claimsByLocality, i));
      }

      datasets.push({
        label: locality,
        borderColor: this.defaultColors[idx],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
      datasetsBar.push({
        label: locality,
        borderColor: this.defaultColors[idx],
        backgroundColor: this.defaultColors[idx],
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
    });

    this.claimsByLocality.sort((a: any, b: any) => {
      return b.quantity - a.quantity;
    });

    const chartData = {
      labels: this.months,
      datasets,
    };

    this.claimsByLocalityBarChartData = {
      labels: this.months,
      datasets: datasetsBar,
    };

    return chartData;
  }

  getClaimsByZoneChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    const zones = ['Centro', 'Norte', 'Sur', 'Sin Zona'];
    const datasets: object[] = [];
    const datasetsBar: object[] = [];

    zones.map((zone: string, idx: number) => {
      const yearData: number[] = [];

      const claimsByLocality = claimsByYear.filter(
        (claim: any) => claim.registrationData.zone == zone
      );

      for (let i = 1; i < 13; i++) {
        yearData.push(this.getNumberOfClaimsByMonth(claimsByLocality, i));
      }

      datasets.push({
        label: zone,
        borderColor: this.defaultColors[idx],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
      datasetsBar.push({
        label: zone,
        borderColor: this.defaultColors[idx],
        backgroundColor: this.defaultColors[idx],
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
    });

    const chartData = {
      labels: this.months,
      datasets,
    };

    this.claimsByZoneBarChartData = {
      labels: this.months,
      datasets: datasetsBar,
    };

    return chartData;
  }

  getAllClaimsByZoneChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const datasets: object[] = [];
    const datasetsBar: object[] = [];

    this.validYears.map((year: string, idx: number) => {
      const yearData: number[] = [];

      const claimsByYear = claimsByCompany.filter(
        (claim: any) => claim.registrationData.date.slice(0, 4) == year
      );

      for (let i = 1; i < 13; i++) {
        yearData.push(this.getNumberOfClaimsByMonth(claimsByYear, i));
      }

      datasets.push({
        label: year,
        borderColor: this.defaultColors[idx],
        backgroundColor: 'rgba(0, 0, 0, 0)',
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
      datasetsBar.push({
        label: year,
        borderColor: this.defaultColors[idx],
        backgroundColor: this.defaultColors[idx],
        lineTension: 0,
        maintainAspectRatio: false,
        responsive: false,
        data: yearData,
      });
    });

    const chartData = {
      labels: this.months,
      datasets,
    };

    this.allClaimsByZoneBarChartData = {
      labels: this.months,
      datasets: datasetsBar,
    };

    return chartData;
  }

  getClaimsByStatusChartData() {
    const claims = vxm.claims.flattenClaimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim['assignmentData.responsiblePerson.interestGroupName'] ==
              this.selectedCompany
          );

    const claimsByYear: any[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter((claim: any) => {
            return (
              claim['registrationData.date'].slice(0, 4) ==
              this.selectedYear.toString()
            );
          });

    claimsByYear.map((claim: any) => {
      const claimData = new Claim(claim);

      if (claimData.finalized) return (claim.status = 'Finalizado');

      if (typeof claim.communicationAndClosing != 'undefined') {
        if (claim.communicationAndClosing.complianceStatus != 'Sin respuesta')
          return (claim.status = 'Lecciones Aprendidas');
      }

      if (typeof claim.associatedAnswers != 'undefined') {
        const answers = claim.associatedAnswers.filter(
          (answer: any) => answer.status == 'Aprobada'
        );
        if (answers.length > 0) return (claim.status = 'Comunicación y cierre');
      }

      if (claim['assignmentData.responsiblePerson.interestGroupId'] != '')
        return (claim.status = 'Respuesta');

      return (claim.status = 'Registro');
    });

    const data = [
      claimsByYear.filter(claim => claim.status == 'Registro').length,
      claimsByYear.filter(claim => claim.status == 'Respuesta').length,
      claimsByYear.filter(claim => claim.status == 'Comunicación y cierre')
        .length,
      claimsByYear.filter(claim => claim.status == 'Lecciones Aprendidas')
        .length,
      claimsByYear.filter(claim => claim.status == 'Finalizado').length,
    ];

    const chartData = {
      labels: [
        'Registro',
        'Respuesta',
        'Comunicación y cierre',
        'Lecciones Aprendidas',
        'Finalizado',
      ],
      datasets: [
        {
          backgroundColor: [
            '#DD4477',
            '#66AA00',
            '#B82E2E',
            '#316395',
            '#994499',
          ],
          data,
        },
      ],
    };

    return chartData;
  }

  getClaimsByTypeChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    let types: string[] = [];
    let tempType = '';

    claimsByYear.map((claim: any) => {
      if (tempType != claim.assignmentData.responsiblePerson.claimType) {
        if (
          claim.assignmentData.responsiblePerson.claimType != '' &&
          typeof claim.assignmentData.responsiblePerson.claimType != 'undefined'
        )
          types.push(claim.assignmentData.responsiblePerson.claimType);
      }
      tempType = claim.assignmentData.responsiblePerson.claimType;
    });

    types = types.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    const yearData: number[] = [];
    types.map((locality: string) => {
      const claimsByLocality = claimsByYear.filter(
        (claim: any) =>
          claim.assignmentData.responsiblePerson.claimType == locality
      );

      yearData.push(claimsByLocality.length);
    });

    const chartData = {
      labels: types,
      datasets: [
        {
          label: 'Tipo Causante',
          backgroundColor: this.defaultColors,
          data: yearData,
        },
      ],
    };

    return chartData;
  }

  getClaimsByCauserTypeChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    let causerTypes: string[] = [];
    let tempCauser = '';

    claimsByYear.map((claim: any) => {
      if (tempCauser != claim.assignmentData.responsiblePerson.causerType) {
        if (
          claim.assignmentData.responsiblePerson.causerType != '' &&
          typeof claim.assignmentData.responsiblePerson.causerType !=
            'undefined'
        )
          causerTypes.push(claim.assignmentData.responsiblePerson.causerType);
      }
      tempCauser = claim.assignmentData.responsiblePerson.causerType;
    });

    causerTypes = causerTypes.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    const yearData: number[] = [];
    causerTypes.map((locality: string) => {
      const claimsByLocality = claimsByYear.filter(
        (claim: any) =>
          claim.assignmentData.responsiblePerson.causerType == locality
      );

      yearData.push(claimsByLocality.length);
    });

    const chartData = {
      labels: causerTypes,
      datasets: [
        {
          label: 'Tipo Causante',
          backgroundColor: this.defaultColors,
          data: yearData,
        },
      ],
    };

    return chartData;
  }

  getClaimsByOperInfraChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    let operInfras: string[] = [];
    let tempInfra = '';

    claimsByYear.map((claim: any) => {
      if (
        tempInfra !=
        claim.assignmentData.responsiblePerson.operationalInfrastructure
      ) {
        if (
          claim.assignmentData.responsiblePerson.operationalInfrastructure != ''
        )
          operInfras.push(
            claim.assignmentData.responsiblePerson.operationalInfrastructure
          );
      }
      tempInfra =
        claim.assignmentData.responsiblePerson.operationalInfrastructure;
    });

    operInfras = operInfras.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    const yearData: number[] = [];
    operInfras.map((locality: string) => {
      const claimsByLocality = claimsByYear.filter(
        (claim: any) =>
          claim.assignmentData.responsiblePerson.operationalInfrastructure ==
          locality
      );

      yearData.push(claimsByLocality.length);
    });

    const chartData = {
      labels: operInfras,
      datasets: [
        {
          label: 'Infra Operacional',
          backgroundColor: this.defaultColors,
          data: yearData,
        },
      ],
    };

    return chartData;
  }

  getClaimsByOperUnitChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    let operUnit: string[] = [];
    let tempUnit = '';

    claimsByYear.map((claim: any) => {
      if (tempUnit != claim.assignmentData.responsiblePerson.operationalUnit) {
        if (claim.assignmentData.responsiblePerson.operationalUnit != '')
          operUnit.push(claim.assignmentData.responsiblePerson.operationalUnit);
      }
      tempUnit = claim.assignmentData.responsiblePerson.operationalUnit;
    });

    operUnit = operUnit.filter(
      (value, index, array) => array.indexOf(value) == index
    );

    const yearData: number[] = [];
    operUnit.map((locality: string) => {
      const claimsByLocality = claimsByYear.filter(
        (claim: any) =>
          claim.assignmentData.responsiblePerson.operationalUnit == locality
      );

      yearData.push(claimsByLocality.length);
    });

    const chartData = {
      labels: operUnit,
      datasets: [
        {
          label: 'Infra Operacional',
          backgroundColor: this.defaultColors,
          data: yearData,
        },
      ],
    };

    return chartData;
  }

  getClaimsByMonthChartData() {
    const claims = vxm.claims.claimList;

    const claimsByCompany =
      this.selectedCompany == 'Todos'
        ? claims
        : claims.filter(
            (claim: any) =>
              claim.assignmentData.responsiblePerson.interestGroupName ==
              this.selectedCompany
          );

    const claimsByYear: object[] =
      this.selectedYear == 'Todos'
        ? claimsByCompany
        : claimsByCompany.filter(
            (claim: any) =>
              claim.registrationData.date.slice(0, 4) == this.selectedYear
          );

    const yearData: number[] = [];

    for (let i = 1; i < 13; i++) {
      yearData.push(this.getNumberOfClaimsByMonth(claimsByYear, i));
    }

    const chartData = {
      labels: this.months,
      datasets: [
        {
          label: 'Meses',
          borderColor: this.defaultColors[6],
          backgroundColor: 'rgba(0, 0, 0, 0)',
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: yearData,
        },
      ],
    };

    this.claimsByMonthBarChartData = {
      labels: this.months,
      datasets: [
        {
          label: 'Meses',
          borderColor: this.defaultColors[6],
          backgroundColor: this.defaultColors[6],
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: yearData,
        },
      ],
    };

    return chartData;
  }

  getNumberOfClaimsByMonth(claims: object[], month: number) {
    return claims.filter(
      (claim: any) => parseInt(claim.registrationData.date.slice(5, 7)) == month
    ).length;
  }

  getAnnualClaimsByBusiness() {
    const chartData = {
      labels: ['Sopraval', 'Agrosuper', 'Total'],
      datasets: [
        {
          label: '2020',
          borderColor: this.defaultColors[0],
          backgroundColor: 'rgba(0, 0, 0, 0)',
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [100, 200, 300],
        },
        {
          label: '2021',
          borderColor: this.defaultColors[1],
          backgroundColor: 'rgba(0, 0, 0, 0)',
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [140, 250, 320],
        },
        {
          label: '2022',
          borderColor: this.defaultColors[2],
          backgroundColor: 'rgba(0, 0, 0, 0)',
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [120, 220, 300],
        },
      ],
    };

    return chartData;
  }

  getAnnualClaimsByBusinessBar() {
    const chartData = {
      labels: ['Sopraval', 'Agrosuper', 'Total'],
      datasets: [
        {
          label: '2020',
          borderColor: this.defaultColors[0],
          backgroundColor: this.defaultColors[0],
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [100, 200, 300],
        },
        {
          label: '2021',
          borderColor: this.defaultColors[1],
          backgroundColor: this.defaultColors[1],
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [140, 250, 320],
        },
        {
          label: '2022',
          borderColor: this.defaultColors[2],
          backgroundColor: this.defaultColors[2],
          lineTension: 0,
          maintainAspectRatio: false,
          responsive: false,
          data: [120, 220, 300],
        },
      ],
    };

    return chartData;
  }

  businessOptions() {
    return {
      responsive: true,
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  onYearChange() {
    this.updateCharts();
  }

  onCompanyChange() {
    this.updateCharts();
  }

  onRankingTabChange(key: any) {
    this.rankingSelectedTab = key;
  }

  onTabChange(key: any) {
    this.lineChartSelectedTab = key;
  }

  onBarChartTabChange(key: any) {
    if (key == 'type')
      this.claimsByTypeChartData = this.getClaimsByTypeChartData();
    if (key == 'causerType')
      this.claimsByCauserTypeChartData = this.getClaimsByCauserTypeChartData();
    if (key == 'operInfra')
      this.claimsByOperInfraChartData = this.getClaimsByOperInfraChartData();
    if (key == 'operUnit')
      this.claimsByOperUnitChartData = this.getClaimsByOperUnitChartData();
    this.barChartSelectedTab = key;
  }

  downloadDashboardPdf() {
    window.open('/Reportes_Reclamos_Sopraval.pdf', '_blank');
  }

  dynamicColors() {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
}
