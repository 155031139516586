import OperationalTasksComponent from './operational-tasks.component.vue';
import OperationalTaskItemComponent from './operational-task-item/operational-task-item.component.vue';
import OperationalTaskListComponent from './operational-task-list/operational-task-list.component.vue';

export const operationalTaskRoutes = [
  {
    path: '/tareas-operacionales',
    component: OperationalTasksComponent,
    children: [
      {
        path: 'crear',
        name: 'crear-tarea-operacional',
        component: OperationalTaskItemComponent,
      },
      {
        path: 'editar/:id',
        name: 'editar-tarea-operacional',
        component: OperationalTaskItemComponent,
      },
      {
        path: '',
        name: 'tareas-operacionales',
        component: OperationalTaskListComponent,
      },
    ],
  },
];
