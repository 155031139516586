import { httpClient } from '@/app/shared/services';
import { ClaimData } from '../interfaces';

const fetchClaims = () => {
  const claimsApi = '/reclamos';

  return httpClient
    .get(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const fetchClaimsByFilter = (payload: Record<string, string>) => {
  const queryParams = new URLSearchParams(payload).toString();

  const interestGroupApi = `/reclamos/search/filter/?${queryParams}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const fetchPDFClaimsByFilter = (payload: Record<string, string>) => {
  const queryParams = new URLSearchParams(payload).toString();

  const interestGroupApi = `/reclamos/search/filter/pdf/?${queryParams}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const fetchSheetClaimsByFilter = (payload: Record<string, string>) => {
  const queryParams = new URLSearchParams(payload).toString();

  const interestGroupApi = `/reclamos/search/filter/excel/?${queryParams}`;

  return httpClient.get(interestGroupApi).then(res => res.data);
};

const sendSheetToEmail = (payload: Record<string, string>, email: string) => {
  const queryParams = new URLSearchParams(payload).toString();

  const interestGroupApi = `/reclamos/search/filter/send-email/?${queryParams}`;

  return httpClient.post(interestGroupApi, { email }).then(res => res.data);
};

const fetchClaim = (id: string) => {
  const claimsApi = `/reclamos/${id}`;

  return httpClient
    .get(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const newClaim = (claim: ClaimData) => {
  const claimsApi = '/reclamos';

  return httpClient
    .post(claimsApi, claim)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const editClaim = (id: string, claim: ClaimData) => {
  const claimsApi = `/reclamos/${id}`;

  return httpClient
    .put(claimsApi, claim)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const deleteClaim = (id: string) => {
  const claimsApi = `/reclamos/${id}`;

  return httpClient
    .delete(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

export {
  fetchClaims,
  fetchClaimsByFilter,
  fetchPDFClaimsByFilter,
  fetchSheetClaimsByFilter,
  sendSheetToEmail,
  fetchClaim,
  newClaim,
  editClaim,
  deleteClaim,
};
