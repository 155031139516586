import { createModule, action, mutation } from 'vuex-class-component';
import { UserAuthenticated } from '../users/shared/models';
import { AuthenticationStatusData } from './shared/interfaces';
import { login, logout } from './shared/services';
import { getUserFromLocalStorage } from './shared/utils';

const VuexModule = createModule({
  namespaced: 'authentication',
  strict: false,
  target: 'nuxt',
});

export class AuthenticationStore extends VuexModule {
  status: AuthenticationStatusData = { authenticated: false };
  authenticatedUser: UserAuthenticated | unknown = null;

  constructor() {
    super();

    this.authenticatedUser = getUserFromLocalStorage();
    if (this.authenticatedUser) this.status = { authenticated: true };
  }

  @mutation loginSuccess() {
    this.authenticatedUser = getUserFromLocalStorage();
    if (this.authenticatedUser) this.status.authenticated = true;
  }

  @mutation loginFailure() {
    this.status.authenticated = false;
    this.authenticatedUser = null;
  }

  @mutation logoutMutation() {
    this.status.authenticated = false;
    this.authenticatedUser = null;
  }

  @action async login(payload: any) {
    return login(payload).then(
      data => {
        if (data.status === 9000) {
          this.loginSuccess();
          return Promise.resolve(data);
        } else {
          this.loginFailure();
          return Promise.resolve(data);
        }
      },
      error => {
        this.loginFailure();
        return Promise.reject(error);
      }
    );
  }

  @action async logout() {
    logout();
    this.logoutMutation();
  }
}
