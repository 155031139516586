const fetchInterestGroupCategories = () => {
  const activities = [
    'Accionistas',
    'Clientes',
    'EECC',
    'Proveedores',
    'Instituciones',
    'ONG',
    'Sector Político',
    'Sopraval',
    'Agrosuper',
    'Comunidades',
    'Gobierno',
    'Medios',
    'Mundo',
    'Académico',
    'Orgenizaciones Gremiales',
  ];

  return activities;
};

const fetchBusinessTypes = () => {
  const types = ['Empresa', 'Organización', 'Persona Natural'];

  return types;
};

const fetchCompanyCategories = () => {
  const categories = [
    'No Aplica',
    'Microempresa',
    'Pequeña Empresa',
    'Mediana Empresa',
    'Gran Empresa',
  ];

  return categories;
};

const fetchOrganizationCategories = () => {
  const organizations = [
    'No Aplica',
    'Comunitaria/Vecinal',
    'Corporacion',
    'Corporacion/Fundacion',
    'Fundacion',
    'Ley Especial De Culto',
    'Ley Especial De Deporte',
    'Leyes Especiales',
    'Organización Funcional',
    'Organización Territorial',
    'Otra',
  ];

  return organizations;
};

const fetchComplainantCategories = () => {
  const categories = ['No Aplica', 'Frecuente', 'Habitual', 'Ocasional'];

  return categories;
};

const fetchSectorTypes = () => {
  const types = ['Urbano', 'Rural'];

  return types;
};

const fetchOccupations = () => {
  const occupations = [
    'Abogado/a',
    'Académico/a',
    'Adjunto/a',
    'Administrador/a',
    'Administrativo/a',
    'Agrónomo/a',
    'Alergista',
    'Alergólogo/a',
    'Almacenero/a',
    'Almacenista',
    'Anatomista',
    'Anestesiólogo/a',
    'Anestesista',
    'Antologista',
    'Antropólogo/a',
    'Arabista',
    'Archivero/a',
    'Arqueólogo/a',
    'Arquitecto/a',
    'Asesor/a',
    'Asistente/a',
    'Astrofísico/a',
    'Astrólogo/a',
    'Astrónomo/a',
    'Atleta',
    'ATS',
    'Autor/a',
    'Auxiliar',
    'Avicultor/a',
    'Bacteriólogo/a',
    'Bedel/a',
    'Bibliógrafo/a',
    'Bibliotecario/a',
    'Biofísico/a',
    'Biógrafo/a',
    'Biólogo/a',
    'Bioquímico/a',
    'Botánico/a',
    'Cancerólogo/a',
    'Cardiólogo/a',
    'Cartógrafo/a',
    'Castrador/a',
    'Catedrático/a',
    'Cirujano/a',
    'Citólogo/a',
    'Climatólogo/a',
    'Codirector/a',
    'Comadrón/a',
    'Consejero/a',
    'Conserje',
    'Conservador/a',
    'Coordinador/a',
    'Cosmógrafo/a',
    'Cosmólogo/a',
    'Criminalista',
    'Cronólogo/a',
    'Decano/a',
    'Decorador/a',
    'Defensor/a',
    'Delegado/a',
    'Delineante',
    'Demógrafo/a',
    'Dentista',
    'Dermatólogo/a',
    'Dibujante',
    'Directivo/a',
    'Director/a',
    'Dirigente',
    'Doctor/a',
    'Documentalista',
    'Ecólogo/a',
    'Economista',
    'Educador/a',
    'Egiptólogo/a',
    'Endocrinólogo/a',
    'Enfermero/a',
    'Enólogo/a',
    'Entomólogo/a',
    'Epidemiólogo/a',
    'Especialista',
    'Espeleólogo/a',
    'Estadista',
    'Estadístico/a',
    'Etimólogo/a',
    'Etimologista',
    'Etnógrafo/a',
    'Etnólogo/a',
    'Etólogo/a',
    'Examinador/a',
    'Facultativo/a',
    'Farmacéutico/a',
    'Farmacólogo/a',
    'Filólogo/a',
    'Filósofo/a',
    'Fiscal',
    'Físico/a',
    'Fisiólogo/a',
    'Fisioterapeuta',
    'Fonetista',
    'Foníatra',
    'Fonólogo/a',
    'Forense',
    'Fotógrafo/a',
    'Funcionario/a',
    'Gemólogo/a',
    'Genetista',
    'Geobotánica',
    'Geodesta',
    'Geofísico/a',
    'Geógrafo/a',
    'Geólogo/a',
    'Geomántico/a',
    'Geómetra',
    'Geoquímico/a',
    'Gerente/a',
    'Geriatra',
    'Gerontólogo/a',
    'Gestor/a',
    'Grabador/a',
    'Graduado/a social',
    'Grafólogo/a',
    'Gramático/a',
    'Hematólogo/a',
    'Hepatólogo/a',
    'Hidrogeólogo/a',
    'Hidrógrafo/a',
    'Hidrólogo/a',
    'Higienista',
    'Hispanista',
    'Historiador/a',
    'Homeópata',
    'Informático/a',
    'Ingeniero/a',
    'Ingeniero/a técnico',
    'Inmunólogo/a',
    'Inspector/a',
    'Interino/a',
    'Interventor/a',
    'Investigador/a ',
    'Jardinero/a',
    'Jefe/a',
    'Juez/a',
    'Latinista',
    'Lector/a',
    'Letrado/a',
    'Lexicógrafo/a',
    'Lexicólogo/a',
    'Licenciado/a',
    'Lingüista',
    'Logopeda',
    'Maestro/a',
    'Matemático/a',
    'Matrón/a',
    'Mecánico/a',
    'Médico/a',
    'Meteorólogo/a',
    'Micólogo/a',
    'Microbiológico/a',
    'Microcirujano/a',
    'Mimógrafo/a',
    'Mineralogista',
    'Monitor/a',
    'Musicólogo/a',
    'Naturópata',
    'Nefrólogo/a',
    'Neumólogo/a',
    'Neuroanatomista',
    'Neurobiólogo/a',
    'Neurocirujano',
    'Neuroembriólogo/a',
    'Neurofisiólogo/a',
    'Neurólogo/a',
    'Nutrólogo/a',
    'Oceanógrafo/a',
    'Odontólogo/a',
    'Oficial/a',
    'Oficinista',
    'Oftalmólogo/a',
    'Oncólogo/a',
    'Operador/a',
    'Óptico/a',
    'Optometrista',
    'Ordenanza',
    'Orientador/a',
    'Ornitólogo/a',
    'Ortopédico/a',
    'Ortopedista',
    'Osteólogo/a',
    'Osteópata',
    'Otorrinolaringólogo/a',
    'Paleobiólogo/a',
    'Paleobotánico/a',
    'Paleógrafo/a',
    'Paleólogo/a',
    'Paleontólogo/a',
    'Patólogo/a',
    'Pedagogo/a',
    'Pediatra',
    'Pedicuro/a',
    'Periodista',
    'Perito/a',
    'Piscicultor/a',
    'Podólogo/a',
    'Portero/a',
    'Prehistoriador/a',
    'Presidente/a',
    'Proctólogo/a',
    'Profesor/a',
    'Programador/a',
    'Protésico/a',
    'Proveedor/a',
    'Psicoanalista',
    'Psicólogo/a',
    'Psicofísico/a',
    'Psicopedagogo/a',
    'Psicoterapeuta',
    'Psiquiatra',
    'Publicista',
    'Publicitario/a',
    'Puericultor/a',
    'Químico/a',
    'Quiropráctico/a',
    'Radioastrónomo/a',
    'Radiofonista',
    'Radiólogo/a',
    'Radiotécnico/a',
    'Radiotelefonista',
    'Radiotelegrafista',
    'Radioterapeuta',
    'Rector/a',
    'Sanitario/a',
    'Secretario/a',
    'Sexólogo/a',
    'Sismólogo/a',
    'Sociólogo/a',
    'Subdelegado/a',
    'Subdirector/a',
    'Subsecretario/a',
    'Técnico/a',
    'Telefonista',
    'Teólogo/a',
    'Terapeuta',
    'Tocoginecólogo/a',
    'Tocólogo/a',
    'Toxicólogo/a',
    'Traductor/a',
    'Transcriptor/a',
    'Traumatólogo/a',
    'Tutor/a',
    'Urólogo/a',
    'Veterinario/a',
    'Vicedecano/a',
    'Vicedirector/a',
    'Vicegerente',
    'Vicepresidente/a',
    'Vicerrector/a',
    'Vicesecretario/a',
    'Virólogo/a',
    'Viticultor/a',
    'Vulcanólogo/a',
    'Xilógrafo/a',
    'Zoólogo/a',
    'Zootécnico/a',
  ];

  return occupations;
};

const fetchEducationLevels = () => {
  const levels = [
    'Sin educación formal',
    'Básica o primaria',
    'Media científico humanista',
    'Media técnico profesional',
    'Centro formación técnica',
    'Instituto profesional',
    'Universitaria',
    'Postgrado universitario',
    'Sin informacion',
    'No Aplica',
  ];

  return levels;
};

const fetchPJTypes = () => {
  const types = [
    'Comunitaria/Vecinal',
    'Corporacion',
    'Corporacion/Fundacion',
    'Fundacion',
    'Ley Especial De Culto',
    'Ley Especial De Deporte',
    'Leyes Especiales',
    'Organización Funcional',
    'Organización Territorial',
    'Otra ',
  ];

  return types;
};

const fetchPJClassifications = () => {
  const classifications = [
    'Agrupación Animalista',
    'Agrupación De Cultura',
    'Agrupación De Discapacidad',
    'Agrupación De Mujeres',
    'Agrupación De Salud',
    'Agrupación De Seguridad',
    'Agrupación De Vivienda',
    'Agrupación Ecológica',
    'Agrupación Etnica',
    'Agrupación Folclorica',
    'Agrupación Juvenil',
    'Agrupación Medio Ambiental',
    'Agrupación Social',
    'Ancianos',
    'Asistencia Social',
    'Centro Adulto Mayor',
    'Centro De Madres',
    'Centro De Padres',
    'Centros De Padres',
    'Club De Damas',
    'Club Deportivo',
    'Clubes De Leones',
    'Comité',
    'Cuerpo De Bomberos',
    'Culturales',
    'Deportivas',
    'Educación',
    'Junta De Vecinos O Condominios',
    'Ong',
    'Otras',
    'Religiosas',
    'Rotary Club',
    'Salud',
    'Unión Comunal',
    'Otra',
  ];

  return classifications;
};

const fetchYears = () => {
  const years = [
    '1950',
    '1951',
    '1952',
    '1953',
    '1954',
    '1955',
    '1956',
    '1957',
    '1958',
    '1959',
    '1960',
    '1961',
    '1962',
    '1963',
    '1964',
    '1965',
    '1966',
    '1967',
    '1968',
    '1969',
    '1970',
    '1971',
    '1972',
    '1973',
    '1974',
    '1975',
    '1976',
    '1977',
    '1978',
    '1979',
    '1980',
    '1981',
    '1982',
    '1983',
    '1984',
    '1985',
    '1986',
    '1987',
    '1988',
    '1989',
    '1990',
    '1991',
    '1992',
    '1993',
    '1994',
    '1995',
    '1996',
    '1997',
    '1998',
    '1999',
    '2000',
    '2001',
    '2002',
    '2003',
    '2004',
    '2005',
    '2006',
    '2007',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
    '2031',
    '2032',
    '2033',
    '2034',
    '2035',
    '2036',
    '2037',
    '2038',
    '2039',
    '2040',
    '2041',
    '2042',
    '2043',
    '2044',
    '2045',
    '2046',
    '2047',
    '2048',
    '2049',
    '2050',
  ];

  return years;
};

const fetchMaritalStatusList = () => {
  const status = [
    'Soltero/a',
    'Comprometido/a',
    'En Relación (más de 1 año noviazgo)',
    'Casado/a',
    'Unión libre o unión de hecho',
    'Separado/a',
    'Divorciado/a',
    'Viudo/a',
    'Noviazgo (período inferior a 1 año)',
  ];

  return status;
};

const fetchAreaList = () => {
  const areas = [
    {
      area: 'División Industrial',
      subgerencia: 'División Industrial',
      gerencia: 'División Industrial',
    },
    {
      area: 'Aseguramiento Calidad',
      subgerencia: 'División Industrial',
      gerencia: 'División Industrial',
    },
    {
      area: 'Procesos',
      subgerencia: 'División Industrial',
      gerencia: 'División Industrial',
    },
    {
      area: 'Despacho',
      subgerencia: 'División Industrial',
      gerencia: 'División Industrial',
    },
    {
      area: 'Mantención',
      subgerencia: 'Mantención',
      gerencia: 'División Industrial',
    },
    {
      area: 'Planta Riles',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Servicios',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Sistemas de Información',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Logistica Materiales',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Compras',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Operaciones',
      subgerencia: 'Administración',
      gerencia: 'División Industrial',
    },
    {
      area: 'Personas',
      subgerencia: 'Personas',
      gerencia: 'División Industrial',
    },
    {
      area: 'Planta Subproductos',
      subgerencia: 'Negocios Secundarios',
      gerencia: 'Comercial',
    },
    {
      area: 'Gestión Despacho',
      subgerencia: 'Negocios Secundarios',
      gerencia: 'Comercial',
    },
    {
      area: 'Mantención',
      subgerencia: 'Negocios Secundarios',
      gerencia: 'Comercial',
    },
    {
      area: 'Servicios',
      subgerencia: 'Negocios Secundarios',
      gerencia: 'Comercial',
    },
    {
      area: 'Digestor',
      subgerencia: 'Negocios Secundarios',
      gerencia: 'Comercial',
    },
    {
      area: 'Preengorda',
      subgerencia: 'Crianza Pavos - Engorda',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Engorda',
      subgerencia: 'Crianza Pavos - Engorda',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Tratamiento GAC',
      subgerencia: 'Crianza Pavos - Engorda',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Terreno Crianza Pavos - Engorda',
      subgerencia: 'Crianza Pavos - Engorda',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Terreno Reproductora',
      subgerencia: 'Reproductora',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Planta Incubación',
      subgerencia: 'Reproductora',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Despicador',
      subgerencia: 'Reproductora',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Veterinaria',
      subgerencia: 'Sanidad y Nutrición',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Investigación y Desarrollo',
      subgerencia: 'Sanidad y Nutrición',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Laboratorio',
      subgerencia: 'Sanidad y Nutrición',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Control, Gestión y Planificación',
      subgerencia: 'Producción Pavos',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Mantención ',
      subgerencia: 'Producción Pavos',
      gerencia: 'Producción Pavos',
    },
    {
      area: 'Relacionamiento Comunitario',
      subgerencia: 'Relaciones Comunitarias',
      gerencia: 'Asuntos Corporativos y Sustentabilidad',
    },
    {
      area: 'Comunicaciones Externas',
      subgerencia: 'Relaciones Comunitarias',
      gerencia: 'Asuntos Corporativos y Sustentabilidad',
    },
    {
      area: 'Gestión Ambiental',
      subgerencia: 'Sustentabilidad',
      gerencia: 'Sustentabilidad',
    },
    {
      area: 'Gestión Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      gerencia: 'Sustentabilidad',
    },
    {
      area: 'Gestión Calidad',
      subgerencia: 'Sustentabilidad',
      gerencia: 'Sustentabilidad',
    },
    {
      area: 'Proyectos y Riesgos',
      subgerencia: 'Sustentabilidad',
      gerencia: 'Sustentabilidad',
    },
    {
      area: 'Control Transportes',
      subgerencia: 'Transporte',
      gerencia: 'Transporte',
    },
    {
      area: 'Transportes Cerdos',
      subgerencia: 'Transporte',
      gerencia: 'Transporte',
    },
    {
      area: 'Transportes Aves',
      subgerencia: 'Transporte',
      gerencia: 'Transporte',
    },
    {
      area: 'Transportes Alimentos',
      subgerencia: 'Transporte',
      gerencia: 'Transporte',
    },
    {
      area: 'Planta Alimentos',
      subgerencia: 'Planta Alimentos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Producción',
      subgerencia: 'Planta Alimentos Pavos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Producción Alimentos',
      subgerencia: 'Planta Alimentos Pavos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Mantención ',
      subgerencia: 'Planta Alimentos Pavos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Servicios',
      subgerencia: 'Planta Alimentos Pavos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Logistica e Insumos',
      subgerencia: 'Planta Alimentos Pavos',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Proyectos y Desarrollo',
      subgerencia: 'Proyectos y Desarrollo',
      gerencia: 'Planta Alimentos',
    },
    {
      area: 'Mantenimiento',
      subgerencia: 'Mantenimiento',
      gerencia: 'Planta Alimentos',
    },
  ];

  return areas;
};

const fetchZoneList = () => {
  const zones = [
    {
      zona: 'Centro',
      sector: [
        {
          nombre: 'Los Tilos',
          pos: { lng: -71.05177862416889, lat: -32.80395639071546 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Romeral',
          pos: { lng: -71.05545446390074, lat: -32.80215381900472 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Division Industrial',
          pos: { lng: -71.26383903204606, lat: -32.338763408323 },
          icon: 'farm_icon_2.png',
          infraOper: 'Division Industrial',
          unidadOper: 'Division Industrial',
        },
        {
          nombre: 'Planta Alimentos',
          pos: { lng: -71.26383903204606, lat: -32.338763408323 },
          icon: 'farm_icon_2.png',
          infraOper: 'Planta Alimentos',
          unidadOper: 'Planta Alimentos',
        },
        {
          nombre: 'Of. Asuntos Corporativos',
          pos: { lng: -71.26383903204606, lat: -32.338763408323 },
          icon: 'farm_icon_2.png',
          infraOper: 'Of. Asuntos Corporativos',
          unidadOper: 'Of. Asuntos Corporativos',
        },
        {
          nombre: 'Piscina Riles',
          pos: { lng: -71.18918422647555, lat: -32.7655553044428 },
          icon: 'farm_icon_2.png',
          infraOper: 'Piscina Riles',
          unidadOper: 'Piscina Riles',
        },
        {
          nombre: 'Subproductos',
          pos: { lng: -71.19052916227432, lat: -32.76547940656728 },
          icon: 'farm_icon_2.png',
          infraOper: 'Subproductos',
          unidadOper: 'Subproductos',
        },
        {
          nombre: 'Ecualizador',
          pos: { lng: -71.19066877761504, lat: -32.76595013966579 },
          icon: 'farm_icon_2.png',
          infraOper: 'Ecualizador',
          unidadOper: 'Ecualizador',
        },
        {
          nombre: 'Planta EISA',
          pos: { lng: -71.18834394376445, lat: -32.7654170032778 },
          icon: 'farm_icon_2.png',
          infraOper: 'Planta EISA',
          unidadOper: 'Planta EISA',
        },
        {
          nombre: 'Canelo',
          pos: { lng: -71.17566851358592, lat: -32.6761911024992 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Chamizal',
          pos: { lng: -71.17198403808793, lat: -32.68090714803594 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'EL Peumo',
          pos: { lng: -71.21588776974296, lat: -32.72368795871185 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Incubación Pucalán',
          pos: { lng: -71.24577187804289, lat: -32.73617512315055 },
          icon: 'incubadora.png',
          infraOper: 'Incubadora',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'La Gloria',
          pos: { lng: -71.15836622018742, lat: -32.75655392165745 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'La Nueva',
          pos: { lng: -71.17239157816219, lat: -32.75588686924608 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Las Encinas',
          pos: { lng: -71.20326524344793, lat: -32.65976882342148 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Los Boldos',
          pos: { lng: -71.17750385760756, lat: -32.69619355719297 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Los Molles',
          pos: { lng: -71.18550201223627, lat: -32.69406756850432 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Los Molles 1',
          pos: { lng: -71.48870317120952, lat: -32.20448451356646 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Los Molles 2',
          pos: { lng: -71.47670043275814, lat: -32.21185966600122 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Melon 1',
          pos: { lng: -71.18873885542239, lat: -32.71611762101274 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Melon 2',
          pos: { lng: -71.19397734668678, lat: -32.71380173682069 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Navio',
          pos: { lng: -71.2036950071411, lat: -32.65277814496848 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Preengorda',
        },
        {
          nombre: 'Pucalán 1',
          pos: { lng: -71.24946617774074, lat: -32.74616599500911 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Pucalán 2',
          pos: { lng: -71.2525810782821, lat: -32.74397342291286 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Pucalán 3',
          pos: { lng: -71.25407558291708, lat: -32.74140209265913 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Pucalán 4',
          pos: { lng: -71.24468735472549, lat: -32.74239691242465 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Pucalán 5',
          pos: { lng: -71.23782302522123, lat: -32.7426864209585 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Pucalán 6',
          pos: { lng: -71.24372697015762, lat: -32.75050825766376 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Rosario',
          pos: { lng: -71.24088667592099, lat: -32.72127547162259 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Santa Ana',
          pos: { lng: -71.18080677489556, lat: -32.74360937960351 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 1',
          pos: { lng: -71.28881140264342, lat: -32.33774020214494 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 2',
          pos: { lng: -71.28445799698443, lat: -32.34601482744 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 3',
          pos: { lng: -71.27853931089626, lat: -32.35214699250628 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 4',
          pos: { lng: -71.27218221865229, lat: -32.35623563696531 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 5',
          pos: { lng: -71.27529465414139, lat: -32.33723995512744 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 6',
          pos: { lng: -71.27148887524022, lat: -32.34496387647286 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 7',
          pos: { lng: -71.26719637821962, lat: -32.35212864297747 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Santa Ana 8',
          pos: { lng: -71.26383903204606, lat: -32.338763408323 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
      ],
    },
    {
      zona: 'Norte',
      sector: [
        {
          nombre: 'CAG Talanquén',
          pos: { lng: -71.25365242564746, lat: -32.52212045239551 },
          icon: 'farm_icon_2.png',
          infraOper: 'Centro Acopio',
          unidadOper: 'Centro Acopio Guano',
        },
        {
          nombre: 'Huaquén 1.1',
          pos: { lng: -71.39442358262401, lat: -32.31146714945394 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Huaquén 1.2',
          pos: { lng: -71.39155055586318, lat: -32.31128599206436 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Huaquén 2.1',
          pos: { lng: -71.39039751121692, lat: -32.31530465781842 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Huaquén 2.2',
          pos: { lng: -71.38660896612768, lat: -32.32020907165181 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Huaquén 3',
          pos: { lng: -71.38273958095894, lat: -32.32152950271669 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Huaquén 4',
          pos: { lng: -71.36879885304357, lat: -32.33231574553955 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Talanquén',
          pos: { lng: -71.29004869441134, lat: -32.32038365937422 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Of Quebradillas',
          pos: { lng: -71.25738343687408, lat: -32.49151635213153 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
      ],
    },
    {
      zona: 'Sur',
      sector: [
        {
          nombre: 'Casablanca',
          pos: { lng: -71.3423189117248, lat: -33.35034022977372 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Torombolo',
          pos: { lng: -71.37595991883413, lat: -33.34330406262917 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Los Leones',
          pos: { lng: -71.24374043605296, lat: -33.04155555531824 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Palmas 1-1',
          pos: { lng: -71.40046372758115, lat: -33.1422682714645 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 1-2',
          pos: { lng: -71.40289234229692, lat: -33.14514873470509 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 2-1',
          pos: { lng: -71.39441086629134, lat: -33.14441483909417 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 2-2',
          pos: { lng: -71.39021480813739, lat: -33.14707025699822 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 2-3',
          pos: { lng: -71.39265410060206, lat: -33.14555828335475 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 3',
          pos: { lng: -71.39410055138792, lat: -33.13877219532208 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Palmas 4',
          pos: { lng: -71.38855403900476, lat: -33.14398584535828 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Crianza Pavos - Engorda',
        },
        {
          nombre: 'Cardonal 1.1',
          pos: { lng: -71.32445526131677, lat: -33.07021642036106 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Cardonal 1.2',
          pos: { lng: -71.32420777899551, lat: -33.06724547966721 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Cardonal 2',
          pos: { lng: -71.3255528910992, lat: -33.06436388002469 },
          icon: 'farm_icon_2.png',
          infraOper: 'Granja',
          unidadOper: 'Reproductoras',
        },
        {
          nombre: 'Incubacion Cardonal',
          pos: { lng: -71.32951886365896, lat: -33.06392418457512 },
          icon: 'incubadora.png',
          infraOper: 'Incubadora',
          unidadOper: 'Reproductoras',
        },
      ],
    },
    {
      zona: 'Agrosuper',
      sector: [
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Cerdo',
          infraOper: 'AC Rincón de Abra',
          unidadOper: 'AC Rincón de Abra',
        },
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Pavo',
          infraOper: 'CAG  Navío',
          unidadOper: 'CAG  Navío',
        },
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Pollo',
          infraOper: 'Alhue',
          unidadOper: 'Alhue',
        },
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Sistemas Tratamiento',
          infraOper: 'Biodigestor Corneche',
          unidadOper: 'Biodigestor Corneche',
        },
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Plantas Alimentos',
          infraOper: 'Lo Miranda',
          unidadOper: 'Lo Miranda',
        },
        {
          pos: {
            lng: 0,
            lat: 0,
          },
          icon: 'farm_icon_2.png',
          nombre: 'Faenadora',
          infraOper: 'Lo Miranda',
          unidadOper: 'Lo Miranda',
        },
      ],
    },
  ];

  return zones;
};

export {
  fetchInterestGroupCategories,
  fetchBusinessTypes,
  fetchCompanyCategories,
  fetchOrganizationCategories,
  fetchComplainantCategories,
  fetchSectorTypes,
  fetchOccupations,
  fetchEducationLevels,
  fetchPJTypes,
  fetchPJClassifications,
  fetchMaritalStatusList,
  fetchAreaList,
  fetchZoneList,
  fetchYears,
};
