import { OperationalTaskData } from '@/app/operational-tasks/shared/interfaces';
import { OperationalTask } from '@/app/operational-tasks/shared/models';
import { format } from 'date-fns';

import {
  AnswerData,
  CausesDetermination,
  answerStatus,
  OperationalTasks,
  CorrectiveMeasures,
} from '../interfaces';

export class Answer {
  _id?: string;
  status: answerStatus = 'Pendiente';
  statusComment = '';
  modificationDate = format(new Date(), 'yyyy-MM-dd');
  modificationTime = format(new Date(), 'HH:mm');
  creationDate = format(new Date(), 'yyyy-MM-dd');
  creationTime = format(new Date(), 'HH:mm');
  claimId = '';
  causesDetermination: CausesDetermination = {
    envirosuit: '',
    smellUnits: '',
    weatherDate: format(new Date(), 'yyyy-MM-dd'),
    weatherTime: format(new Date(), 'HH:mm'),
    weatherData: {
      state: '',
      temperature: '',
      windSpeed: '',
      coordinates: '',
      windDirection: '',
    },
    geographicData: {
      affectation: false,
      externalityType: '',
      externalityDistance: '',
    },
    productionData: {
      territorialUnit: '',
      infraOperational: '',
      operationalUnit: '',
      sectorType: '',
      sector: '',
      zone: '',
      breedingType: '',
      animalSpecie: '',
      animalAge: '',
      criticalStage: '',
    },
  };
  operationalTasks: OperationalTasks = {
    selectedTaskIds: [],
  };
  correctiveMeasures: CorrectiveMeasures = {
    measures: [],
  };
  answer = '';
  comments = '';

  constructor(data?: AnswerData) {
    if (data) Object.assign(this, data);
  }

  setClaimId(claimId: string) {
    this.claimId = claimId;
  }

  getId() {
    return this._id || '';
  }

  getWithOutId() {
    const data = { ...this };
    delete data._id;

    return data;
  }

  updateAnswer(status: answerStatus) {
    this.modificationDate = format(new Date(), 'yyyy-MM-dd');
    this.modificationTime = format(new Date(), 'HH:mm');
    this.status = status;
  }

  setSelectedTaskIds(selectedTasks: OperationalTaskData[]) {
    const selectedTaskIds = selectedTasks.map(selectedTask => {
      const task = new OperationalTask(selectedTask);
      return task.getId();
    });

    this.operationalTasks.selectedTaskIds = selectedTaskIds;
  }
}
