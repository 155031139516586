import { createModule, action } from 'vuex-class-component';
import { fetchUsers } from './shared/services';

const VuexModule = createModule({
  namespaced: 'users',
  strict: false,
  target: 'nuxt',
});

export class UsersStore extends VuexModule {
  userList: object[] = [];

  @action async getUserList() {
    const usersResponse = await fetchUsers();
    this.userList = usersResponse.res;
  }
}
