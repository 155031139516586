
import Vue from 'vue';
export default Vue.extend({
  name: 'TextEditable',
  props: {
    value: {
      type: String,
    },
  },
  data: () => ({
    isEditing: false,
    localValue: '',
  }),
  beforeMount() {
    this.localValue = this.value;
  },
});
