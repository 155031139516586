import InterestGroupComponent from './interest-group.component.vue';
import InterestGroupItemComponent from './interest-group-item/interest-group-item.component.vue';
import InterestGroupListComponent from './interest-group-list/interest-group-list.component.vue';

export const interestGroupRoutes = [
  {
    path: '/grupos-interes',
    component: InterestGroupComponent,
    children: [
      {
        path: 'crear',
        name: 'crear-grupo-interes',
        component: InterestGroupItemComponent,
      },
      {
        path: '',
        name: 'grupos-interes',
        component: InterestGroupListComponent,
      },
    ],
  },
];
