const fetchTerritorialUnits = () => {
  const territorialUnits = [
    'Candelaria',
    'Cardonal',
    'Casablanca (Pavos)',
    'Casablanca (Pollos)',
    'Chancón - Huilmay',
    'Codegua',
    'Convento',
    'Corneche',
    'Coya',
    'Curacaví',
    'Huaquén',
    'La Estrella',
    'La Higuera',
    'La Manga',
    'La Ramirana',
    'Las Palmas',
    'Lo Miranda',
    'Loica',
    'Longovilo',
    'Los Chinos - Las Mercedes',
    'Los Leones',
    'Maitenlahue',
    'Melón',
    'Mostazal',
    'Navío',
    'Nogales - Calera',
    'Peralillo',
    'Pucalán',
    'Punta de Cortés',
    'Quebradilla - Talanquén',
    'Requínoa',
    'Romeral',
    'Rosario',
    'Santa Elena',
    'Santa Rosa',
    'Tantehue',
    'Trapiche - Longotoma',
    'Valdebenito',
  ];

  return territorialUnits;
};

export { fetchTerritorialUnits };
