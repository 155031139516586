import { httpClient } from '@/app/shared/services';
import { AnswerData } from '../interfaces';

const fetchAnswers = () => {
  const claimsApi = '/respuestas';

  return httpClient
    .get(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const fetchAnswer = (id: string) => {
  const claimsApi = `/respuestas/${id}`;

  return httpClient
    .get(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const newAnswer = (claim: AnswerData) => {
  const claimsApi = '/respuestas';

  return httpClient
    .post(claimsApi, claim)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const editAnswer = (id: string, claim: AnswerData) => {
  const claimsApi = `/respuestas/${id}`;

  return httpClient
    .put(claimsApi, claim)
    .then(res => res.data)
    .catch(err => err.response.data);
};

const deleteAnswer = (id: string) => {
  const claimsApi = `/respuestas/${id}`;

  return httpClient
    .delete(claimsApi)
    .then(res => res.data)
    .catch(err => err.response.data);
};

export { fetchAnswers, fetchAnswer, newAnswer, editAnswer, deleteAnswer };
