const fetchExternalityDistances = () => {
  const externalityDistances = [
    'No Aplica',
    'Menos de 1 Km',
    'Entre 1 - 5 Km',
    'Más de 5 Km',
  ];

  return externalityDistances;
};

export { fetchExternalityDistances };
