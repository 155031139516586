import { Component, Vue } from 'vue-property-decorator';
import { CommunicationAndClosing } from '../shared/interfaces';
import { Claim } from '../shared/models';
import { editClaim, fetchClaim } from '../shared/services';

import { format, formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import { vxm } from '@/app/app-state';
import { UserAuthenticated } from '@/app/users/shared/models';
import { UserAuthenticatedData } from '@/app/users/shared/interfaces';

@Component
export default class ClaimClosureComponent extends Vue {
  private claimData: Claim;
  private communicationAndClosingForm: CommunicationAndClosing;
  private closureStatus = false;
  private commentValue = '';
  private isLoadingClaimItem = false;
  private communicationAndClosingRules = {
    answerSelected: [
      {
        required: true,
        message: 'Por favor selecciona una respuesta',
        trigger: 'change',
      },
    ],
  };
  $refs!: {
    communicationAndClosingForm: any;
  };

  constructor() {
    super();
    this.claimData = new Claim();
    this.communicationAndClosingForm = this.claimData.communicationAndClosing;
  }

  get hasApprovedAnswers() {
    return this.claimData.getApprovedAnswers().length > 0;
  }

  get approvedAnswers() {
    return this.claimData.getApprovedAnswers();
  }

  get userComments() {
    return this.communicationAndClosingForm.comments.filter(
      comment => comment.isUser == true
    );
  }

  get userAvatarLetters() {
    return vxm.authentication.authenticatedUser
      ? this.getInitials(
          (vxm.authentication.authenticatedUser as UserAuthenticated)
            .interestGroupInfo.bussinesName
        )
      : 'AA';
  }

  get claimCode() {
    return this.claimData.code ? this.claimData.code : 'REC-AACC-2020-XXXXXX';
  }

  getInitials(name: any) {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  async mounted() {
    await this.getClaim();
  }

  async getClaim() {
    this.isLoadingClaimItem = true;
    const claimId = this.$route.params.id;
    const claimRes = await fetchClaim(claimId);
    this.claimData = new Claim(claimRes.res);
    this.communicationAndClosingForm = this.claimData.communicationAndClosing;
    this.closureStatus =
      this.communicationAndClosingForm.complianceStatus == 'Conforme';
    this.isLoadingClaimItem = false;
  }

  formatDateForComments(date: string) {
    return formatDistanceToNow(new Date(date), {
      includeSeconds: true,
      locale: es,
    });
  }

  async addComment() {
    if (!this.commentValue) {
      return;
    }
    this.communicationAndClosingForm.comments.push({
      author:
        (vxm.authentication.authenticatedUser as UserAuthenticatedData)
          .interestGroupInfo.bussinesName || 'Usuario logueado',
      isUser: true,
      avatarLetters: this.userAvatarLetters,
      avatarImage: '',
      content: this.commentValue,
      creationDate: format(new Date(), 'yyyy-MM-dd'),
      creationTime: format(new Date(), 'HH:mm'),
    });
    this.commentValue = '';
    this.isLoadingClaimItem = true;
    this.claimData.updateClaimStage();
    const response = await editClaim(
      this.claimData.getId(),
      this.claimData.getWithOutId()
    );
    this.isLoadingClaimItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.getClaim();
    }
  }

  async removeComment(index: number) {
    this.communicationAndClosingForm.comments.splice(index, 1);
    this.isLoadingClaimItem = true;
    this.claimData.updateClaimStage();
    const response = await editClaim(
      this.claimData.getId(),
      this.claimData.getWithOutId()
    );
    this.isLoadingClaimItem = false;
    if (response.status == 'Failed') {
      this.$message.error(response.msg);
    } else {
      this.$message.success(response.msg);
      this.getClaim();
    }
  }

  goToAnswers() {
    this.$router.push({
      name: 'reclamo-respuestas',
      params: { id: this.claimData.getId() },
    });
  }

  async saveClaimClosure(answerStatus: any) {
    this.$refs.communicationAndClosingForm.validate(async (valid: boolean) => {
      if (valid) {
        if (answerStatus) {
          this.communicationAndClosingForm.complianceStatus = answerStatus;
        }
        this.communicationAndClosingForm.answerSent =
          answerStatus == 'Sin respuesta' ? false : true;
        if (answerStatus != 'Sin respuesta') {
          const answer = this.approvedAnswers.find(
            ans => ans.id == this.communicationAndClosingForm.answerSelected
          );
          const content =
            answerStatus == ''
              ? `La respuesta #${answer?.number} ha sido enviada`
              : `El denunciante esta ${answerStatus.toLowerCase()} con la respuesta #${
                  answer?.number
                }`;
          this.communicationAndClosingForm.comments.push({
            author: 'System',
            isUser: false,
            avatarLetters: '',
            avatarImage: '',
            content,
            creationDate: format(new Date(), 'yyyy-MM-dd'),
            creationTime: format(new Date(), 'HH:mm'),
          });
        }
        this.isLoadingClaimItem = true;
        this.claimData.updateClaimStage();
        const response = await editClaim(
          this.claimData.getId(),
          this.claimData.getWithOutId()
        );
        this.isLoadingClaimItem = false;
        if (response.status == 'Failed') {
          this.$message.error(response.msg);
        } else {
          this.$message.success(response.msg);
          this.getClaim();
        }
      }
    });
  }
}
