const fetchAnimalSpecies = () => {
  const animalSpecies = [
    {
      name: 'Cerdo',
      ages: [
        '21 - 36 días',
        '37 - 44 días',
        '45 - 60 días',
        '61 - 70 días',
        '71 - 100 días',
        '101 - 130 días',
        '131 - 160 días',
        '161 - 180 días',
      ],
    },
    {
      name: 'Pavo',
      ages: ['1 - 42 días', '43 - 133 días'],
    },
    {
      name: 'Pollo',
      ages: [
        '0 - 7 días',
        '8 - 14 días',
        '15 - 21 días',
        '22 - 28 días',
        '29 - 35 días',
        '36 - 42 días',
        'No Aplica',
      ],
    },
  ];

  return animalSpecies;
};

export { fetchAnimalSpecies };
