import { createModule, action } from 'vuex-class-component';
import { fetchOperationalTasks } from './shared/services';

const VuexModule = createModule({
  namespaced: 'operational-task',
  strict: false,
  target: 'nuxt',
});

export class OperationalTasksStore extends VuexModule {
  operationalTaskList: object[] = [];

  @action async getOperationalTaskList() {
    const operationalTasksResponse = await fetchOperationalTasks();
    this.operationalTaskList = operationalTasksResponse.res;
  }
}
