import { createModule, action } from 'vuex-class-component';
import {
  InterestGroupData,
  InterestGroupBackendData,
} from './shared/interfaces';
import {
  fetchInterestGroup,
  fetchInterestGroupByFilter,
} from './shared/services';

const VuexModule = createModule({
  namespaced: 'interest-group',
  strict: false,
  target: 'nuxt',
});

export class InterestGroupStore extends VuexModule {
  interestGroupList: InterestGroupBackendData[] = [];
  interestGroupListFiltered: InterestGroupBackendData[] = [];
  interestGroupSelected: InterestGroupData = {
    id: '',
    code: '',
    generalInformation: {
      rut: '',
      category: {
        businessType: '',
        companyCategory: '',
        organizationCategory: '',
        complainantCategory: '',
        name: '',
      },
    },
    personalInformation: {},
    laborInformation: {
      registeredWorker: {
        position: {
          lat: 0.0,
          lng: 0.0,
          zoom: 17,
        },
      },
    },
    historicalInformation: {},
    influenceMatrix: {
      actorInfluence: {
        territoryOrganizationRelationship: '',
        relationshipWithOtherLocalActors: '',
        organizationLeadership: '',
        mobilization: '',
        average: 0,
      },
      allianceWithActor: {
        strategicCooperationRelationship: '',
        adversariesList: '',
        temporaryCooperativeRelationship: '',
        average: 0,
      },
      communityRelations: {
        communication: '',
        confidence: '',
        organizationKnowledge: '',
        complianceWithAgreements: '',
        organizationSatisfaction: '',
        average: 0,
      },
    },
  };
  countries = [];
  interestDialogMode: 'Nuevo' | 'Editar' = 'Nuevo';

  @action async fetchAll() {
    const interestGroupResponse = await fetchInterestGroup();
    this.interestGroupList = interestGroupResponse.res;
    this.interestGroupListFiltered = interestGroupResponse.res;
  }

  @action async fetchAllByFilter(payload: { column: string; query: string }) {
    const interestGroupResponse = await fetchInterestGroupByFilter(
      payload.column,
      payload.query
    );
    this.interestGroupListFiltered = interestGroupResponse.res;
  }

  get organizationList() {
    return this.interestGroupList.filter(
      gi =>
        gi.tipo_denunciante === 'Empresa/Organización' ||
        gi.tipo_denunciante === 'Organización'
    );
  }

  get responsiblePersonList() {
    return this.interestGroupList.filter(
      gi => gi.grupointeres === 'Sopraval' || gi.grupointeres === 'Agrosuper'
    );
  }
}
