import { OperationalTaskActivity } from '../interfaces';

const fetchOperationalTaskActivities = () => {
  const activities: OperationalTaskActivity[] = [
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Control de Vectores',
      actividad: 'Control de Roedores',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Control de Vectores',
      actividad: 'Control de Alphitobius',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Control de Vectores',
      actividad: 'Control de Aves Silvestres',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Medicion de Olores',
      actividad: 'Estudios Medicion de Olores',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Medicion de Olores',
      actividad: 'Carga de Datos a Sistemas',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Medicion de Olores',
      actividad: 'Analisis de Informacion',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Control de Malezas',
      actividad: 'Controles Mecànicos',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Control de Malezas',
      actividad: 'Aplicaciones Quimicas',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Residuos Peligrosos',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Residuos No Peligrosos',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Organicos (incubadoras)',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Area Domestica',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Limpieza Fosas Septicas',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Sustentabilidad',
      subgerencia: 'Sustentabilidad',
      area: 'Medio Ambiente',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Operativa',
      tarea: 'Retiros de Residuos',
      actividad: 'Disposicion Final de Residuos',
      respOperativo: 'Coordinador Ambiental',
      respAreasubgcia: 'Jefe Gestión Ambiental',
      respGerencia: 'Subgerente Sustentabilidad',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Lavado Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Desinfección Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Acumulación de Humedad',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Rebarrido (humedades)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Descarga Y Reparto de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Esparcido de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Desinfección de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Reposición de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Carguío de Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Distribución Macho',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Distribución Hembra',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Agua',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Alimento',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Aire (ventilación Mecanizada)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Extracción de Mortalidad (diario)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Vacuna Aplicación Según Programa',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Faena',
      actividad: 'Levante Líneas de Alimento - Ayuno',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Faena',
      actividad: 'Levante Líneas de Agua - Ayuno',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Faena',
      actividad: 'Pillado Y Carguío',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Granja',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Faena',
      actividad: 'Traslado Aves Planta Faenadora',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Ingreso',
      actividad: 'Ingreso',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Apilamiento',
      actividad: 'Apilamiento',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Volteo',
      actividad: 'Volteo',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Correr Pilas',
      actividad: 'Correr Pilas',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Terminacion Pilas',
      actividad: 'Terminacion Pilas',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Emboladora',
      actividad: 'Emboladora',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Carga Camiones',
      actividad: 'Carga Camiones Guano - M. Alfonso',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Carga Camiones',
      actividad: 'Carga Camiones Guano - Venta Directa',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Tratamiento Guano Gac',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Carga Camiones',
      actividad: 'Carga Camiones Guano - Venta Desde M. Alfonso',
      respOperativo: 'Jefe Tratamiento GAC',
      respAreasubgcia: 'Subgerente Crianza Pavos',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Primario',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Primario',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Primario',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Secundario',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Secundario',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Secundario',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Lodos',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Lodos',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Riles',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Tratamiento Lodos',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Viscera',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Viscera',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Viscera',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Pluma',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Pluma',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Harina de Pluma',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Aceite',
      actividad: 'Operación',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Aceite',
      actividad: 'Detención',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Division Industrial',
      subgerencia: 'Administracion',
      area: 'Planta Subproductos',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Linea Productiva de Aceite',
      actividad: 'Mantenimiento',
      respOperativo: 'Supervisor Servicios',
      respAreasubgcia: 'Gerente Administración',
      respGerencia: 'Gerente División Industrial',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Huevos',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Pavos 1 Dia',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte 5 Semanas',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Pavos Faena',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Alimento',
      respOperativo: 'Jefe Transporte Alimento',
      respAreasubgcia: 'Jefe Transporte Alimento',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Materia Prima',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Viruta',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Transporte',
      subgerencia: 'Transporte',
      area: 'Transporte',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Transporte',
      actividad: 'Transporte Personal',
      respOperativo: '',
      respAreasubgcia: '',
      respGerencia: 'Gerente Transporte',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Incubadora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Definir',
      actividad: 'Definir',
      respOperativo: 'Jefe Planta Incubación',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Lavado Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Desinfección Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Acumulación de Humedad',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Aseo',
      actividad: 'Rebarrido (humedades)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Descarga Y Reparto de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Esparcido de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Desinfección de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejo de Viruta',
      actividad: 'Reposición de Viruta',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Carguío de Pabellones',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Distribución Macho',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Recepcion de Aves',
      actividad: 'Distribución Hembra',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Agua',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Alimento',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Aire (ventilación Mecanizada)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Extracción de Mortalidad (diario)',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Específica',
      categoriaTarea: 'Operativa',
      tarea: 'Manejos Generales',
      actividad: 'Vacuna Aplicación Según Programa',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
    {
      gerencia: 'Produccion Pavos',
      subgerencia: 'Crianza Pavos',
      area: 'Reproductora',
      tipoTarea: 'Genérica',
      categoriaTarea: 'Administrativa',
      tarea: 'Manejos Generales',
      actividad: 'Elaboración Guías',
      respOperativo: 'Jefe Terreno Crianza Pavos',
      respAreasubgcia: 'Subgetente Reproductoras',
      respGerencia: 'Gerente Producción Pavos',
    },
  ];

  return activities;
};

export { fetchOperationalTaskActivities };
