import { InterestGroupInfo, UserAuthenticatedData } from '../interfaces';
import { SopravalSystemModule, SopravalUserActions } from '../types';
import { SopravalUserRole } from '../types/role.type';

export class UserAuthenticated {
  private id = '';
  interestGroupId = '';
  interestGroupInfo: InterestGroupInfo = {
    bussinesName: '',
    charge: '',
    primaryContact: '',
    primaryEmail: '',
    commune: '',
    locality: '',
    province: '',
    region: '',
  };
  private role: SopravalUserRole = 'complainants';
  readonly iat = 0;

  constructor(data?: UserAuthenticatedData) {
    if (data) Object.assign(this, data);
  }

  getId() {
    return this.id;
  }

  getRole(): SopravalUserRole {
    return this.role;
  }

  getDate() {
    return new Date(this.iat * 1000);
  }

  can(action: SopravalUserActions, module: SopravalSystemModule): boolean {
    const role = this.getRole();

    switch (role) {
      case 'admin':
        return true;
      case 'workers':
        if (module == 'users') {
          return false;
        }
        if (module == 'interestGroups') {
          if (action == 'delete') return false;
        }
        if (module == 'claims') {
          if (action == 'delete') return false;
        }
        if (module == 'operationalTasks') {
          if (action == 'delete') return false;
        }
        return true;
      case 'collaborator':
        if (module == 'users') {
          return false;
        }
        if (module == 'interestGroups') {
          if (action == 'delete') return false;
        }
        if (module == 'claims') {
          if (
            action == 'create' ||
            action == 'edit' ||
            action == 'read' ||
            action == 'delete'
          )
            return false;
        }
        if (module == 'operationalTasks') {
          if (action == 'edit' || action == 'delete') return false;
        }
        return true;
      case 'complainants':
        if (module == 'users') {
          return false;
        }
        if (module == 'interestGroups') {
          if (action == 'list' || action == 'edit') return false;
        }
        if (module == 'claims') {
          if (
            action == 'create' ||
            action == 'edit' ||
            action == 'read' ||
            action == 'delete'
          )
            return false;
        }
        if (module == 'operationalTasks') {
          if (
            action == 'list' ||
            action == 'create' ||
            action == 'edit' ||
            action == 'read' ||
            action == 'delete'
          )
            return false;
        }
        return true;
      default:
        return false;
    }
  }
}
