import Vue from 'vue';
import App from './app/app.component.vue';
import router from './app/app-routes';
import { store } from './app/app-state';

import Vuesax from 'vuesax';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueTheMask from 'vue-the-mask';
import Antd from 'ant-design-vue';

import vSelect from 'vue-select';
import XSelectComponent from './app/shared/components/x-select/x-select.component.vue';
import XInputComponent from './app/shared/components/x-input/x-input.component';

import 'vuesax/dist/vuesax.css';
import 'ant-design-vue/dist/antd.css';
import './css/box-icons.css';
import './css/overwritten-styles/vuesax.css';
import './styles/main.scss';

Vue.config.productionTip = false;

Vue.component('v-select', vSelect);
Vue.component('x-select', XSelectComponent);
Vue.component('x-input', XInputComponent);

Vue.use(VueTheMask);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyA4xq0d39F_6rRSYv-0iQUACNagp6zHzvo',
    libraries: 'places', // necessary for places input
  },
});
Vue.use(Vuesax);
Vue.use(Antd);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
