const fetchCriticalStages = () => {
  const criticalStages = [
    'Lavado',
    'Salida a Faena',
    'Sacado de Guano',
    'Limpieza Laguna',
    'Volteo Pilas',
    'Riego',
    'No Aplica',
  ];

  return criticalStages;
};

export { fetchCriticalStages };
