import { Component, Vue } from 'vue-property-decorator';
import { vxm } from './app-state';
import { UserAuthenticated } from './users/shared/models';

@Component
export default class Home extends Vue {
  private activeSidebar = true;

  get user() {
    const authenticatedUser = vxm.authentication
      .authenticatedUser as UserAuthenticated;
    return authenticatedUser;
  }

  get menuItems() {
    return [
      ...(this.user.can('list', 'reports')
        ? [
            {
              name: 'Dashboard',
              route: 'dashboard',
              camelCase: 'Dashboard',
              icon: {
                name: 'pie-chart',
                theme: 'filled',
              },
            },
          ]
        : []),
      ...(this.user.can('list', 'interestGroups')
        ? [
            {
              name: 'Grupos de interés',
              route: 'grupos-interes',
              camelCase: 'InterestGroup',
              icon: {
                name: 'idcard',
                theme: 'outlined',
              },
            },
          ]
        : []),
      ...(this.user.can('list', 'claims')
        ? [
            {
              name: 'Reclamos',
              route: 'reclamos',
              camelCase: 'Claim',
              icon: {
                name: 'container',
                theme: 'outlined',
              },
            },
          ]
        : []),
      ...(this.user.can('list', 'operationalTasks')
        ? [
            {
              name: 'Tareas Operacionales',
              route: 'tareas-operacionales',
              camelCase: 'OperatioanlTask',
              icon: {
                name: 'carry-out',
                theme: 'outlined',
              },
            },
          ]
        : []),
      ...(this.user.can('list', 'users')
        ? [
            {
              name: 'Usuarios',
              route: 'usuarios',
              camelCase: 'User',
              icon: {
                name: 'team',
                theme: 'outlined',
              },
            },
          ]
        : []),
    ];
  }

  get userAvatarLetters() {
    return vxm.authentication.authenticatedUser
      ? this.getInitials(
          (vxm.authentication.authenticatedUser as UserAuthenticated)
            .interestGroupInfo.bussinesName
        )
      : 'AA';
  }

  getInitials(name: any) {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  goToRoute(route: string) {
    if (route != this.$route.name) this.$router.push({ name: route });
  }

  getPopupContainer(trigger: any) {
    return trigger ? trigger.parentNode : document.body;
  }

  logout() {
    vxm.authentication.logout();
    this.$router.push({ name: 'login' });
  }
}
