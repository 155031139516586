import { createModule, action } from 'vuex-class-component';
import { Answer } from './shared/models';
import { fetchAnswers } from './shared/services';
import {
  fetchClaims,
  fetchClaimsByFilter,
} from './shared/services/claims.service';
import { flatten } from './shared/utils';

const VuexModule = createModule({
  namespaced: 'claims',
  strict: false,
  target: 'nuxt',
});

export class ClaimsStore extends VuexModule {
  claimList: object[] = [];
  answerList: Answer[] = [];

  get flattenClaimList() {
    const flattenList = this.claimList.map((item: any) => {
      const clone = JSON.parse(JSON.stringify(item));

      const answers = clone.associatedAnswers;
      const communication = clone.communicationAndClosing;
      const lessons = clone.learnedLessons;

      delete clone.associatedAnswers;
      delete clone.communicationAndClosing;
      delete clone.learnedLessons;

      const flatted: any = flatten(clone);

      flatted.associatedAnswers = answers;
      flatted.communicationAndClosing = communication;
      flatted.learnedLessons = lessons;

      return flatted;
    });
    return flattenList;
  }

  @action async getClaimList() {
    const claimsResponse = await fetchClaims();
    this.claimList = claimsResponse.res;
  }

  @action async getClaimListByFilter(payload: Record<string, string>) {
    const claimsResponse = await fetchClaimsByFilter(payload);
    this.claimList = claimsResponse.res;
  }

  @action async getAnswerList() {
    const answersResponse = await fetchAnswers();
    this.answerList = answersResponse.res;
  }
}
