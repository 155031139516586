import { Component, Vue } from 'vue-property-decorator';
import { Claim } from '../shared/models/claim.model';
import { fetchClaim } from '../shared/services';

import { addDays, format } from 'date-fns';
import { es } from 'date-fns/locale';

import { vxm } from '@/app/app-state';

@Component
export default class AnswersComponent extends Vue {
  private claimData: Claim;
  private isLoadingClaimItem = false;

  constructor() {
    super();
    this.claimData = new Claim();
  }

  get answers() {
    return vxm.claims.answerList
      .filter(answer => answer.claimId == this.claimData.getId())
      .reverse();
  }

  get claimCode() {
    return this.claimData.code ? this.claimData.code : 'REC-AACC-2020-XXXXXX';
  }

  get inAnswerPending() {
    if (this.answers.length == 0) {
      return false;
    } else {
      return this.answers[this.answers.length - 1].status == 'Pendiente';
    }
  }

  get hasApprovedAnswers() {
    return this.claimData.getApprovedAnswers().length > 0;
  }

  async mounted() {
    this.isLoadingClaimItem = true;
    const claimId = this.$route.params.id;
    const claimRes = await fetchClaim(claimId);
    await vxm.claims.getAnswerList();
    this.claimData = new Claim(claimRes.res);
    this.isLoadingClaimItem = false;
  }

  formatDatePPPP(date: string) {
    return format(addDays(new Date(date), 1), 'PPPP', {
      locale: es,
    });
  }

  formatDatePPP(date: string) {
    return format(addDays(new Date(date), 1), 'PPP', {
      locale: es,
    });
  }

  iconByStatus(status: string) {
    if (status == 'Pendiente') return '';
    if (status == 'Aprobada') return 'check';
    return 'close';
  }

  colorByStatus(status: string) {
    if (status == 'Pendiente') return '#1890ff';
    if (status == 'Aprobada') return '#46c93a';
    return '#ff4757';
  }

  goToClosure() {
    this.$router.push({
      name: 'reclamo-comunicacion-cierre',
      params: { id: this.claimData.getId() },
    });
  }

  goToClaimList() {
    this.$router.push({
      name: 'reclamos',
    });
  }
}
